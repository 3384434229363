import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

class Developments extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Developments</h2>
            </div>
            <div
              className={'col-12 col-md-10'}
              style={{ marginTop: 40, marginBottom: 25 }}>
              <h5>Developers</h5>
            </div>
            <div className={'col-12 col-md-10'}>
              <p>
                Our experienced New Homes team specialises in the marketing and
                sale of new residential developments across the UK. Drawing on
                our vast industry experience and extensive global office network
                we understand how to achieve the best possible results for both
                large and small scale developers by ensuring their developments
                are exposed to the correct markets across the world. Enquire
                here for further information on how we can assist in the sale of
                your development{' '}
              </p>
            </div>
            <div
              className={'col-12 col-md-10'}
              style={{ marginTop: 40, marginBottom: 25 }}>
              <h5>Buyers</h5>
            </div>
            <div className={'col-12 col-md-10'}>
              <p>
                For the buyer the experience is un-paralleled with our team of
                associates guiding you through the purchase process from the
                very first viewing until handover of the keys on completion.
                There are many benefits to buying directly from a developer,
                including having no chain which usually allows for a far easier
                and smoother transaction in addition to in many cases capital
                growth between reservation and final completion of the purchase.
                Click here for further information on the developments we are
                currently offering for sale.
              </p>
            </div>
            <div
              className={'col-12 col-md-10'}
              style={{ marginTop: 40, marginBottom: 25 }}>
              <h5>Investors</h5>
            </div>
            <div className={'col-12 col-md-10'}>
              <p>
                Many of our clients decide to invest in off plan new-build
                properties because of the strong long-term gains of the UK
                property market, in both rental and capital growth which often
                provide investors with more attractive returns than many other
                asset classes. Our specialist investment consultants cater for
                all types of investors from single unit buy to let landlords,
                right through to experience and professional investors with more
                specialist requirements. Our Investment consultants also provide
                development consultancy, acquisition advice, and act in an
                agency capacity to dispose of both multi-unit residential
                portfolios and commercial assets.
              </p>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 col-md-6 col-lg-2 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    );
  }
}

export default Developments;
