import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';

import { push } from 'connected-react-router';
import { saveSearchValues, saveSearchResults, UPDATE_SEARCH_RESULTS } from "../../modules/search";

import Http from '../../constants/Http';
import PropertySearch from '../../components/Fields/PropertySearch';
import SelectDropdown from '../Fields/SelectDropdown';

import './index.scss';

class SearchForm extends React.Component {
  state = {
    suggestions: null,
    cityArray: null,
  };
  
  componentWillMount() {
    Http.get(`/auth/count${this.props.type === 'sales' ? '-sale' : this.props.type === 'rent' ? '-rent' : '-development'}`)
      .then(res => {
        const cityArray = [];
        res.data.forEach((city, i) => {
          cityArray.push(city.city)
        });
        this.setState({
          suggestions: res.data,
          cityArray
        })
      })
      .catch(err => {
        console.error(err);
      });
  };
  
  onSubmit = values => {
    this.props.dispatch({
      type: UPDATE_SEARCH_RESULTS,
      payload: null
    });
    Http.post('/auth/search', values)
      .then(res => {
        this.props.saveSearchValues(values).then(() => {
          this.props.saveSearchResults(res.data).then(() => {
            this.props.push(`/dashboard/search${this.props.type === 'sales' ? '?type=sale' : this.props.type === 'rent' ? '?type=rent' : '?type=development'}`);
          })
        });
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { props, state } = this;
    const { handleSubmit } = props;
    const { suggestions } = state;
    return (
      <Form className={"property-search-form"} onSubmit={handleSubmit(this.onSubmit)}>
        <div className={'row mt-3'}>
          <FormGroup className={'col-12 col-md-8 pr-md-0 mb-0'}>
            <Field
              name={'location'}
              component={PropertySearch}
              suggestions={suggestions}
            />
          </FormGroup>
          <FormGroup className={'col-12 col-md-4 pl-md-0'}>
            <Button
              className={'btn btn-block cbls-dark-grey-button '}
              style={{
                fontFamily: 'proxima-nova, sans-serif',
                fontSize: 12,
                fontWeight: 300,
                backgroundColor: '#EBA94F',
                border: 'none',
                width: '100%',
                height: 60,
                color: '#000000',
              }}>
              <i className={'fa fa-search'} />&nbsp;&nbsp; Find your
              opportunities
            </Button>
          </FormGroup>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            {this.props.type === 'sales' || this.props.type === "development" ? (
              <div className={'row'}>
                <FormGroup
                  className={'col-12 col-sm-6 col-md-6 col-xl-3 searchOption'}>
                  <Field
                    name={'min_price'}
                    placeholder={'Min Price (£)'}
                    component={SelectDropdown}
                    searchable={false}
                    options={[
                      {
                        id: 1500000,
                        label: '£1,500,000'
                      },
                      {
                        id: 1750000,
                        label: '£1,750,000'
                      },
                      {
                        id: 2000000,
                        label: '£2,000,000'
                      },
                      {
                        id: 2500000,
                        label: '£2,500,000'
                      },
                      {
                        id: 3000000,
                        label: '£3,000,000'
                      },
                      {
                        id: 3500000,
                        label: '£3,500,000'
                      },
                      {
                        id: 4000000,
                        label: '£4,000,000'
                      },
                      {
                        id: 4500000,
                        label: '£4,500,000'
                      },
                      {
                        id: 5000000,
                        label: '£5,000,000'
                      },
                      {
                        id: 7500000,
                        label: '£7,500,000'
                      },
                      {
                        id: 15000000,
                        label: '£15,000,000'
                      },
                      {
                        id: 20000000,
                        label: '£20,000,000'
                      },
                      {
                        id: 25000000,
                        label: '£25,000,000 +'
                      }
                    ]}
                  />
                  <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/price-tag.svg"} />
                </FormGroup>
                <FormGroup
                  className={'col-12 col-sm-6 col-md-6  col-xl-3 searchOption'}>
                  <Field
                    name={'max_price'}
                    searchable={false}
                    placeholder={'Max Price (£)'}
                    component={SelectDropdown}
                    options={[
                      {
                        id: 1500000,
                        label: '£1,500,000'
                      },
                      {
                        id: 1750000,
                        label: '£1,750,000'
                      },
                      {
                        id: 2000000,
                        label: '£2,000,000'
                      },
                      {
                        id: 2500000,
                        label: '£2,500,000'
                      },
                      {
                        id: 3000000,
                        label: '£3,000,000'
                      },
                      {
                        id: 3500000,
                        label: '£3,500,000'
                      },
                      {
                        id: 4000000,
                        label: '£4,000,000'
                      },
                      {
                        id: 4500000,
                        label: '£4,500,000'
                      },
                      {
                        id: 5000000,
                        label: '£5,000,000'
                      },
                      {
                        id: 7500000,
                        label: '£7,500,000'
                      },
                      {
                        id: 15000000,
                        label: '£15,000,000'
                      },
                      {
                        id: 20000000,
                        label: '£20,000,000'
                      },
                      {
                        id: 25000000,
                        label: '£25,000,000 +'
                      }
                    ]}
                  />
                  <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/price-tag.svg"} />
                </FormGroup>
                <FormGroup
                  className={'col-12 col-sm-6 col-md-6  col-xl-3 searchOption'}>
                  <Field
                    placeholder={'Property Type'}
                    searchable={false}
                    name={'property_type'}
                    component={SelectDropdown}
                    labelKey={"name"}
                    valueKey={"id"}
                    multi
                    options={props.lookups.property_types}
                  />
                  <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/structural.svg"} />
                </FormGroup>
                <FormGroup
                  className={'col-12 col-sm-6 col-md-6  col-xl-3 searchOption'}>
                  <Field
                    placeholder={'Bedrooms'}
                    searchable={false}
                    name={'bedrooms'}
                    component={SelectDropdown}
                    options={[
                      {
                        id: 1,
                        label: "1"
                      },
                      {
                        id: 2,
                        label: "2"
                      },
                      {
                        id: 3,
                        label: "3"
                      },
                      {
                        id: 4,
                        label: "4"
                      },
                      {
                        id: 5,
                        label: "5"
                      },
                      {
                        id: 6,
                        label: "6"
                      },
                      {
                        id: 7,
                        label: "7"
                      },
                      {
                        id: 8,
                        label: "8"
                      },
                      {
                        id: 9,
                        label: "9"
                      },
                      {
                        id: 10,
                        label: "10"
                      },
                      {
                        id: 11,
                        label: "11+"
                      },
                    ]}
                  />
                  <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/bedroom.svg"} />
                </FormGroup>
              </div>
            ) : (
              <div className={'row'}>
                <FormGroup className={'col-12 col-sm-6 col-md-6 col-xl-3 searchOption'}>
                  <Field
                    name={'min_price'}
                    searchable={false}
                    placeholder={'Min Price (£)'}
                    component={SelectDropdown}
                    options={[
                      {
                        id: 800,
                        label: '£800 pcm'
                      },
                      {
                        id: 1000,
                        label: '£1,000 pcm'
                      },
                      {
                        id: 1200,
                        label: '£1,200 pcm'
                      },
                      {
                        id: 1400,
                        label: '£1,400 pcm'
                      },
                      {
                        id: 1600,
                        label: '£1,600 pcm'
                      },
                      {
                        id: 1800,
                        label: '£1,800 pcm'
                      },
                      {
                        id: 2000,
                        label: '£2,000 pcm'
                      },
                      {
                        id: 2200,
                        label: '£2,200 pcm'
                      },
                      {
                        id: 2400,
                        label: '£2,400 pcm'
                      },
                      {
                        id: 2600,
                        label: '£2,600 pcm'
                      },
                      {
                        id: 2800,
                        label: '£2,800 pcm'
                      },
                      {
                        id: 3000,
                        label: '£3,000 pcm'
                      }
                    ]}
                  />
                  <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/price-tag.svg"} />
                </FormGroup>
               <FormGroup className={'col-12 col-sm-6 col-md-6 col-xl-3 searchOption'}>
                  <Field
                    placeholder={'Max Price (£)'}
                    searchable={false}
                    name={'max_price'}
                    component={SelectDropdown}
                    options={[
                      {
                        id: 800,
                        label: '£800 pcm'
                      },
                      {
                        id: 1000,
                        label: '£1,000 pcm'
                      },
                      {
                        id: 1200,
                        label: '£1,200 pcm'
                      },
                      {
                        id: 1400,
                        label: '£1,400 pcm'
                      },
                      {
                        id: 1600,
                        label: '£1,600 pcm'
                      },
                      {
                        id: 1800,
                        label: '£1,800 pcm'
                      },
                      {
                        id: 2000,
                        label: '£2,000 pcm'
                      },
                      {
                        id: 2200,
                        label: '£2,200 pcm'
                      },
                      {
                        id: 2400,
                        label: '£2,400 pcm'
                      },
                      {
                        id: 2600,
                        label: '£2,600 pcm'
                      },
                      {
                        id: 2800,
                        label: '£2,800 pcm'
                      },
                      {
                        id: 3000,
                        label: '£3,000 pcm'
                      }
                    ]}
                  />
                 <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/price-tag.svg"} />
                </FormGroup>
               <FormGroup className={'col-12 col-sm-6 col-md-6 col-xl-3 searchOption'}>
                  <Field
                    name={'property_type'}
                    placeholder={'Property Type'}
                    searchable={false}
                    component={SelectDropdown}
                    multi
                    labelKey={"name"}
                    valueKey={"id"}
                    options={props.lookups.property_types}
                  />
                 <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/structural.svg"} />
                </FormGroup>
               <FormGroup className={'col-12 col-sm-6 col-md-6 col-xl-3 searchOption'}>
                  <Field
                    placeholder={'Bedrooms'}
                    searchable={false}
                    name={'bedrooms'}
                    component={SelectDropdown}
                    options={[
                      {
                        id: 1,
                        label: "1"
                      },
                      {
                        id: 2,
                        label: "2"
                      },
                      {
                        id: 3,
                        label: "3"
                      },
                      {
                        id: 4,
                        label: "4"
                      },
                      {
                        id: 5,
                        label: "5"
                      },
                      {
                        id: 6,
                        label: "6"
                      },
                      {
                        id: 7,
                        label: "7"
                      },
                      {
                        id: 8,
                        label: "8"
                      },
                      {
                        id: 9,
                        label: "9"
                      },
                      {
                        id: 10,
                        label: "10"
                      },
                      {
                        id: 11,
                        label: "11+"
                      },
                    ]}
                  />
                 <img className={"for-select-dropdown"} src={"/images/new/Home/Search_icons/bedroom.svg"} />
                </FormGroup>
              </div>
            )}
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  values: state.search.values,
  lookups: state.search.lookups,
});

export default connect(mapStateToProps, {
  saveSearchValues,
  saveSearchResults,
  push,
})(
  reduxForm({
    form: 'searchForm',
    destroyOnUnmount: false,
  })(SearchForm)
);
