import React, { Component } from 'react'

import './index.scss'
import Loading from '../../constants/Loading'

let scroll;

class InfiniteScrollPagination extends Component {
  constructor(props) {
    super(props);
    this.checkScroll = this.checkScroll.bind(this) //bind function once
  }
  
  state = {
    loading: false,
  };
  
  componentDidMount() {
    window.setTimeout(() => {
        scroll = window.addEventListener('scroll', this.checkScroll, false)
    }, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll, false)
  }
  
  checkScroll = () => {
    let body = document.body,
      html = document.documentElement;
  
    let height = Math.max( body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight );
    
    let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

    if (!this.props.loading && (height / 100) * 60 <= scrollTop) {
      this.props.loadMore()
    } else {}
  };
  
  
  render() {
    const { props, state } = this
    const { children, refresh, loadMore, loading, endOfStream, endOfStreamText = props.endOfStreamComponent, loadingMore, loadingMoreComponent } = props
    return (
      <div className={"infinite-scroll-pagination"}>
        {children}
        {endOfStream &&
          <>{endOfStreamText}</>
        }
        {loading &&
          <>
            {loadingMoreComponent}
            <Loading />
          </>
        }
      </div>
    )
  }
}

export default InfiniteScrollPagination
