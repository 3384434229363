import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './index.scss';

class TopNavBar extends Component {
  state = {
    isOpen: false
  };
  
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };
  
  render() {
    const { props } = this;
    const { match } = props;
    return (
      <div className={"topNavBar"}>
        <Navbar expand="lg" fixed={'top'}>
          <NavbarBrand href="/dashboard"><img src={"/images/new/Landing/logo-new.svg"} width={"45px"} height={"45px"} className={"img-fluid"} /></NavbarBrand>
          <NavbarToggler onClick={this.toggle} >
            <i className="fas fa-bars"/>
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="" navbar>
              <NavItem>
                <NavLink activeClassName="active" exact className={"nav-link"} to={`${match}`} >Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink activeClassName="active" className={"nav-link"} to={`${match}/my-cobbletons`} >My Cobbletons</NavLink>
              </NavItem>
              <NavItem>
                <NavLink activeClassName="active" className={"nav-link"} to={`${match}/sales-search`} >Manage My Buying Search</NavLink>
              </NavItem>
              {/*<NavItem>*/}
                {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/lettings-search`} >My Rental Search</NavLink>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink activeClassName="active" className={"nav-link"} to={`${match}/sale-opportunities`} >Manage My Sales Properties</NavLink>
              </NavItem>
              {/*<NavItem>*/}
                {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/letting-opportunities`} >My Lettings Properties</NavLink>*/}
              {/*</NavItem>*/}
              {/*<NavItem>*/}
                {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/how-to-use`} >How To Use</NavLink>*/}
              {/*</NavItem>*/}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Help
                </DropdownToggle>
                <DropdownMenu right>
                  {/*<div className="dropdown-item">*/}
                    {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/cobbletons-buying-solution`} >Buying Solution</NavLink>*/}
                  {/*</div>*/}
                  <div className="dropdown-item">
                    <NavLink activeClassName="active" className={"nav-link"} to={`${match}/how-to-use`} >How To Use</NavLink>
                  </div>
                  {/*<div className="dropdown-item">*/}
                    {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/agents-partnership`} >Agents Partnership</NavLink>*/}
                  {/*</div>*/}
                  {/*<div className="dropdown-item">*/}
                    {/*<NavLink activeClassName="active" className={"nav-link"} to={`${match}/sales`} >Sales</NavLink>*/}
                  {/*</div>*/}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <a href={"#"} className={"nav-link"}
                  onClick={() => {
                    window.localStorage.removeItem('token');
                    window.location.href = '/';
                  }}>Logout</a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default TopNavBar;
