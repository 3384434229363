export const UPDATE_URL = 'routes/UPDATE_URL';

const initialState = {
  url: window.location.pathname.split('/')[2] !== (null || undefined || "") ? window.location.pathname.split('/')[2] : window.location.pathname.split('/')[1]
};

export default (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_URL: {
      return {
        ...state,
        url: action.payload
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export const update_url = url => dispatch => {
  dispatch({
    type: UPDATE_URL,
    payload: url
  })
};