import React, { Component } from 'react';
import moment from 'moment';

class SmallSaleOpportunitiesViewingTable extends Component {
  render() {
    const { offers } = this.props;
    return (
      <div className={'small-table'}>
        <div className="d-flex">
          <div className="col">
            <b>Ref</b>
          </div>
          <div className="col">
            <b>Status</b>
          </div>
          <div className="col">
            <b>Feedback</b>
          </div>
          <div className="col">
            <b>Date</b>
          </div>
        </div>
        {offers !== null &&
          offers !== undefined &&
          offers.map((offer, index) => (
            <div className="d-flex border-top" key={index}>
              <div className="col">
                <span>#{offer.id}</span>
              </div>
              <div className="col">
                <span className={`${offer.status.name}`}>{offer.status.name}</span>
              </div>
              <div className="col">
                {offer.feedback !== null ? (
                  <button
                    className={
                      'cbls-dark-grey-button'
                    }
                    style={{
                      height: 15,
                      borderRadius: 5,
                      fontSize: 12,
                      cursor: 'pointer',
                      border: 'none'
                    }}
                    type={'button'}
                    onClick={() =>
                      this.props.toggleFeedback(
                        offer.id,
                        offer.property_id
                      )
                    }>
                    View Feedback
                  </button>
                ) : (
                  'Feeback Not Yet Left'
                )}
              </div>
              <div className="col">
                <span>{moment(offer.created_at).format('DD/MM/YYYY')}</span>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default SmallSaleOpportunitiesViewingTable;
