import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux';

import Textfit from 'react-textfit';

import Cover from 'react-video-cover';

import './index.scss';

const videoOptions = {
  src: '/videos/bannervid.mp4',
  autoPlay: true,
  loop: true,
  muted: true,
  poster: "/images/dashboard/london-bg-day.jpg",
};

const Video = props => (
  <div className="videoWrapper">
    <div className={"login-button"}>
      <button onClick={() => {
        props.dispatch(push('/login'));
      }}>{/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MMStream && window.matchMedia("(orientation: landscape)").matches ? "LOG IN" : "ALREADY HAVE AN ACCOUNT? LOG IN"}</button>
    </div>
    <div className="videoFilter" />
    <div className="videoText text-center" >
      <div className="row h-100 w-custom justify-content-center align-items-center">
        <div className="col-12 text-center p-0">
          <img src={"/images/new/Landing/logo-new.svg"} className={"logo-new"} />
          <h3>FIND YOUR OPPORTUNITY</h3>
          <h1 className={"text-gradient"}>COBBLETONS</h1>
          <p dangerouslySetInnerHTML={{ __html: props.Banner_Subheading_Type_Wysiwyg }} ></p>
        </div>
      </div>
      <div className="col-12 text-center videoSeeMore" onClick={() => {
        const el = document.getElementById('contentTop');
        window.scrollTo(0, el.offsetTop);
      } }>
          <a><p>FIND OUT MORE</p>
          <i className="fas fa-angle-down"></i></a>
        </div>
    </div>
    <div className="video">
      {/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MMStream ?

        <img src="/images/dashboard/london-bg-day.jpg" alt="" style={{ width: "100%", height: "100%", overflow: "hidden", position: "absolute", objectFit: "cover" }} />

        :

        <Cover
          videoOptions={videoOptions}
          forceFallback={true}
          remeasureOnWindowResize={true}
        />

      }
      
    </div>
  </div>
)

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Video);
