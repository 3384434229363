import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { reduxForm, Field, change } from 'redux-form';
import { Form, Label, FormGroup, Modal, Button, ModalHeader } from 'reactstrap';
import LightBox from 'react-image-lightbox';
import swal from 'sweetalert';

import Http from '../../constants/Http';
import MakeAnOfferModal from '../Modals/MakeAnOfferModal';
import BookAViewingModal from '../Modals/BookAViewingModal';
import VirtualTourModal from '../Modals/VirtualTourModal';

import defaultImage from './property_image_placeholder.png';

import 'react-image-lightbox/style.css';

import './index.scss';

import TextInput from '../Fields/TextInput';

class HomepagePropertyCard extends React.Component {
  state = {
    showMoreDescription: false,
    content: "desc",
    lightBoxOpen: null,
    photoIndex: 0,
    submitting: false,
    contactAgentModal: false,
    makeOfferModal: false,
    virtualTourModal: null,
    recent: null,
    saving: false,
    saved: true
  };
  
  toggleContent = val => {
    this.setState({
      content: val
    })
  };
  
  openLightBox = index => {
    this.setState({
      lightBoxOpen: !this.state.lightBoxOpen,
      photoIndex: 0
    });
  };
  
  toggleShowMoreDescription = () => {
    this.setState({
      showMoreDescription: !this.state.showMoreDescription
    })
  };
  
  render() {
    const { state, props } = this;
    const { recent, saved } = state;
    const { PageContent, cardNum } = props;
    return (
      <React.Fragment>
        <div className={"property-card-new"} >
          {this.state.lightBoxOpen && (
            <LightBox
              enableZoom={false}
              mainSrc={
                PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)
                  ? PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex].url
                  : defaultImage
              }
              nextSrc={
                PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)
                  ? PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][
                  (this.state.photoIndex + 1) % PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length
                    ].url
                  : defaultImage
              }
              prevSrc={
                PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)
                  ? PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][
                  (this.state.photoIndex + PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length - 1) %
                  PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length
                    ].url
                  : defaultImage
              }
              onCloseRequest={() => this.openLightBox()}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex] !==
                    (null || undefined)
                      ? (this.state.photoIndex + PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length - 1) %
                      PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length
                      : 0
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][this.state.photoIndex] !==
                    (null || undefined)
                      ? (this.state.photoIndex + 1) % PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length
                      : 0
                })
              }
            />
          )}
          {/*{this.state.content === 'floorplan' &&*/}
          {/*<LightBox*/}
          {/*  enableZoom={false}*/}
          {/*  mainSrc={*/}
          {/*    PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)*/}
          {/*      ? PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex].url*/}
          {/*      : defaultImage*/}
          {/*  }*/}
          {/*  nextSrc={*/}
          {/*    PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)*/}
          {/*      ? PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][*/}
          {/*      (this.state.photoIndex + 1) % PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length*/}
          {/*        ].url*/}
          {/*      : defaultImage*/}
          {/*  }*/}
          {/*  prevSrc={*/}
          {/*    PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex] !== (null || undefined)*/}
          {/*      ? PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][*/}
          {/*      (this.state.photoIndex + PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length - 1) %*/}
          {/*      PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length*/}
          {/*        ].url*/}
          {/*      : defaultImage*/}
          {/*  }*/}
          {/*  onCloseRequest={() => this.setState({ content: "desc" })}*/}
          {/*  onMovePrevRequest={() =>*/}
          {/*    this.setState({*/}
          {/*      photoIndex:*/}
          {/*        PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex] !==*/}
          {/*        (null || undefined)*/}
          {/*          ? (this.state.photoIndex + PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length - 1) %*/}
          {/*          PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length*/}
          {/*          : 0*/}
          {/*    })*/}
          {/*  }*/}
          {/*  onMoveNextRequest={() =>*/}
          {/*    this.setState({*/}
          {/*      photoIndex:*/}
          {/*        PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'][this.state.photoIndex] !==*/}
          {/*        (null || undefined)*/}
          {/*          ? (this.state.photoIndex + 1) % PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length*/}
          {/*          : 0*/}
          {/*    })*/}
          {/*  }*/}
          {/*/>*/}
          {/*}*/}
          <div className="row h-100">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="property-image" style={{ maxHeight: 353 }} >
                <div className={"img"} style={{
                  background: `${
                    PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length > 0
                      ? `url(${PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'][0].url})`
                      : `url(${defaultImage})`
                    } no-repeat center center`,
                  cursor: 'pointer',
                }} onClick={() => this.openLightBox()} />
                <div className="col-12 img-icons">
                  <div className="row d-flex justify-content-around">
                    {/*{PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'] !== null && PageContent.content['Property_Card_' + cardNum + '_Property_Floorplan_Type_FileUpload'].length > 0 &&*/}
                    {/*<div className="">*/}
                    {/*  <img src="/images/icons/floorplan.svg" alt="" onClick={() => this.toggleContent('floorplan')}/>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {/*{recent.epc !== null && recent.epc !== undefined &&*/}
                    {/*<div className="">*/}
                    {/*  <img src="/images/icons/EPC.svg" alt="" onClick={() => this.toggleContent('epc')}/>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {/*{recent.virtual_tour_url !== null && recent.virtual_tour_url !== undefined &&*/}
                    {/*<div className="">*/}
                    {/*  <img src="/images/icons/virtual_tour.svg" alt=""*/}
                    {/*       onClick={() => this.toggleContent('virtual-tour')}/>*/}
                    {/*</div>*/}
                    {/*}*/}
                  </div>
                </div>
                <div className={"offer-status"}>
                  <p>{PageContent.content['Property_Card_' + cardNum + '_Status_Type_TextInput']}</p>
                </div>
                {PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length > 1 &&
                <div
                  className={'propertyGalleryText'}>
                  <i className={'fas fa-camera'}/>
                  &nbsp;{PageContent.content['Property_Card_' + cardNum + '_Property_Images_Type_FileUpload'].length}
                </div>
                }
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-8">
              <div className="property-info">
                <Modal isOpen={this.state.content !== "desc" && this.state.content !== 'floorplan'} toggle={() => this.setState({ content: "desc" })} className={this.state.content}>
                  <ModalHeader
                    toggle={() => this.setState({ content: "desc" })}
                  />
                  {/*{this.state.content === 'virtual-tour' &&*/}
                  {/*<div className="property-info virtual-tour">*/}
                  {/*  <iframe src={recent.virtual_tour_url} allowFullScreen={false} width="100%" height="100%" frameBorder="0" style={{borderWidth:0, minHeight:350 }} />*/}
                  {/*</div>*/}
                  }
                  {/*{this.state.content === "epc" &&*/}
                  {/*recent.epc !== null && recent.epc !== undefined &&*/}
                  {/*<div className="property-info epc">*/}
                  {/*  <h4 className={"address"}>{recent.property_name} - EPC</h4>*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-12">*/}
                  {/*      <img className={"img-fluid"} src={`https://epc.rightmove.co.uk/generateEpc?graphtype=EPC&currentenergy=${recent.epc.efficiency_actual}&potentialenergy=${recent.epc.efficiency_potential}&currentenvironment=${recent.epc.impact_actual}&potentialenvironment=${recent.epc.impact_potential}&address=&reference=&graphquality=web`} alt=""/>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*}*/}
                </Modal>
                {/*<span>{28 - PageContent.content['Property_Card_' + cardNum + '_Property_Day_Counter_Type_TextInput']} Days Remaining</span>*/}
                <h4 className={"address"}>{PageContent.content['Property_Card_' + cardNum + '_Property_Name_Type_TextInput']} </h4>
                
                {/*<span className={"asking-price-text"}>ASKING PRICE</span>*/}
                {/*<p className="asking-price">&pound;&nbsp;{PageContent.content['Property_Card_' + cardNum + '_Property_Price_Type_TextInput'].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>*/}
                
                <p className={"property-stats"}>{PageContent.content['Property_Card_' + cardNum + '_Property_Subtitle_Type_TextInput']}</p>
                
                {!this.state.showMoreDescription ?
                  <p onClick={this.toggleShowMoreDescription} className={"property-description"} dangerouslySetInnerHTML={{
                    __html: `${PageContent.content['Property_Card_' + cardNum + '_Property_Description_Type_Wysiwyg'].slice(0, 250)}... <span>Show more</span>`
                  }}/>
                  :
                  <p onClick={this.toggleShowMoreDescription} className={"property-description"}
                     dangerouslySetInnerHTML={{ __html: `${PageContent.content['Property_Card_' + cardNum + '_Property_Description_Type_Wysiwyg']} <span>Show less</span>` }}/>
                }
                  <div className="row button-row">
                    <div className="col-12">
                      <button
                        type={'button'}
                        className={
                          'btn btn-block new-property-button mt-3'
                        }
                        onClick={this.props.toggle}
                      >
                        Join Cobbletons
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(
  reduxForm({ form: 'contactAgentForm' })(HomepagePropertyCard)
);
