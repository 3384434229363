import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import { reduxForm, Field, change } from "redux-form";
import { Form, Label, FormGroup, Modal, Button, ModalHeader } from "reactstrap";
import LightBox from "react-image-lightbox";
import swal from "sweetalert";

import Http from "../../constants/Http";
import MakeAnOfferModal from "../Modals/MakeAnOfferModal";
import BookAViewingModal from "../Modals/BookAViewingModal";
import VirtualTourModal from "../Modals/VirtualTourModal";

import defaultImage from "./property_image_placeholder.png";

import "react-image-lightbox/style.css";

import "./index.scss";

import TextInput from "../Fields/TextInput";

class PropertyComponent extends React.Component {
  state = {
    showMoreDescription: false,
    content: "desc",
    lightBoxOpen: null,
    photoIndex: 0,
    submitting: false,
    contactAgentModal: false,
    makeOfferModal: false,
    virtualTourModal: null,
    recent: null,
    saving: false,
    saved: this.props.sale
      ? this.props.data.is_saved_sale
      : this.props.data.is_saved_rent
  };
  
  componentDidMount() {
    this.setState({
      recent: this.props.data
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({
      recent: nextProps.data
    });
  }
  
  toggleContent = val => {
    this.setState({
      content: val
    });
  };
  
  openLightBox = index => {
    this.setState({
      lightBoxOpen: !this.state.lightBoxOpen,
      photoIndex: 0
    });
  };
  
  makeOfferModal = () => {
    this.setState({
      makeOfferModal: !this.state.makeOfferModal
    });
  };
  
  toggleShowMoreDescription = () => {
    this.setState({
      showMoreDescription: !this.state.showMoreDescription
    });
  };
  
  contactAgentModal = (name, propertyID) => {
    this.props.dispatch(
      change("contactAgentForm", "subject", `Regarding ${name}`)
    );
    this.props.dispatch(change("contactAgentForm", "description", ""));
    this.props.dispatch(change("contactAgentForm", "property_id", propertyID));
    this.setState({
      contactAgentModal: !this.state.contactAgentModal
    });
  };
  
  toggleVirtualTourModal = num => {
    this.setState({
      virtualTourModal: num
    });
  };
  
  saveProperty = (id, type, saved) => {
    this.setState({
      saving: id.toString()
    });
    
    if (saved) {
      Http({
        method: "delete",
        url: `/auth/search/save${type ? "-sale" : "-rent"}/delete`,
        data: { id }
      })
        .then(res => {
          this.setState({
            saving: false,
            saved: !this.state.saved
          });
          this.props.afterUnsave();
        })
        .catch(err => {
          console.error(err);
          this.setState({
            saving: false
          });
        });
    } else {
      Http.post(`/auth/save-property${type ? "-sales" : "-lettings"}`, {
          property_id: id
        })
        .then(res => {
          this.setState({
            saving: false,
            saved: !this.state.saved
          });
          this.props.afterUnsave();
        })
        .catch(err => {
          console.error(err);
          this.setState({
            saving: false
          });
        });
    }
  };
  
  onSubmit = values => {
    this.setState({
      submitting: true
    });
    Http.post("/auth/contact/send", values)
      .then(res => {
        this.setState({
          submitting: false
        });
        this.setState({
          contactAgentModal: !this.state.contactAgentModal
        });
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK"
        });
      })
      .catch(err => {
        this.setState({
          submitting: false
        });
        this.setState({
          contactAgentModal: !this.state.contactAgentModal
        });
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK"
        });
        console.error(err);
      });
  };
  
  render() {
    const { state } = this;
    const { recent, saved } = state;
    
    return recent !== null ? (
      <React.Fragment>
        <div className={"property-card-new"}>
          {this.state.lightBoxOpen && (
            <LightBox
              enableZoom={false}
              mainSrc={
                recent.all_images[this.state.photoIndex] !== (null || undefined)
                  ? recent.all_images[this.state.photoIndex].image.url
                  : defaultImage
              }
              nextSrc={
                recent.all_images[this.state.photoIndex] !== (null || undefined)
                  ? recent.all_images[
                  (this.state.photoIndex + 1) % recent.all_images.length
                    ].image.url
                  : defaultImage
              }
              prevSrc={
                recent.all_images[this.state.photoIndex] !== (null || undefined)
                  ? recent.all_images[
                  (this.state.photoIndex + recent.all_images.length - 1) %
                  recent.all_images.length
                    ].image.url
                  : defaultImage
              }
              onCloseRequest={() => this.openLightBox()}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    recent.all_images[this.state.photoIndex] !==
                    (null || undefined)
                      ? (this.state.photoIndex + recent.all_images.length - 1) %
                      recent.all_images.length
                      : 0
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    recent.all_images[this.state.photoIndex] !==
                    (null || undefined)
                      ? (this.state.photoIndex + 1) % recent.all_images.length
                      : 0
                })
              }
            />
          )}
          {this.state.content === "floorplan" &&
          <LightBox
            enableZoom={false}
            mainSrc={
              recent.floor_plans[this.state.photoIndex] !== (null || undefined)
                ? recent.floor_plans[this.state.photoIndex].url
                : defaultImage
            }
            nextSrc={
              recent.floor_plans[this.state.photoIndex] !== (null || undefined)
                ? recent.floor_plans[
                (this.state.photoIndex + 1) % recent.floor_plans.length
                  ].url
                : defaultImage
            }
            prevSrc={
              recent.floor_plans[this.state.photoIndex] !== (null || undefined)
                ? recent.floor_plans[
                (this.state.photoIndex + recent.floor_plans.length - 1) %
                recent.floor_plans.length
                  ].url
                : defaultImage
            }
            onCloseRequest={() => this.setState({ content: "desc" })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  recent.floor_plans[this.state.photoIndex] !==
                  (null || undefined)
                    ? (this.state.photoIndex + recent.floor_plans.length - 1) %
                    recent.floor_plans.length
                    : 0
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  recent.floor_plans[this.state.photoIndex] !==
                  (null || undefined)
                    ? (this.state.photoIndex + 1) % recent.floor_plans.length
                    : 0
              })
            }
          />
          }
          <div className="property-info-hold">
            <div className="col-12 col-md-6 col-lg-4 prop-image">
              <div className="property-image"
                   style={{ maxHeight: recent.parent_id === null ? recent.children_count === 0 ? 432 : 337 : 475 }}>
                <div className="image-wrap">
                  <div className={"img"} style={{
                    background: `${
                      recent.all_images.length > 0
                        ? `url(${recent.all_images[0].image.url})`
                        : `url(${defaultImage})`
                    } no-repeat center center`,
                    cursor: "pointer"
                  }} onClick={() => this.openLightBox()}/>
                </div>
                
                <div className="col-12 img-icons">
                  <div className="row d-flex justify-content-around">
                    {/*{recent.floor_plans !== null && recent.floor_plans.length > 0 &&*/}
                    {/*<div className="floorplan">*/}
                    {/*  <img src="/images/icons/floorplan.svg" alt="" onClick={() => this.toggleContent('floorplan')}/>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {recent.epc !== null && recent.epc !== undefined &&
                    <div className="">
                      <img src="/images/icons/EPC.svg" alt="" onClick={() => this.toggleContent("epc")}/>
                    </div>
                    }
                    {recent.virtual_tour_url !== null && recent.virtual_tour_url !== undefined &&
                    <div className="">
                      <img src="/images/icons/virtual_tour.svg" alt=""
                           onClick={() => this.toggleContent("virtual-tour")}/>
                    </div>
                    }
                  </div>
                </div>
                <div className={"offer-status"}>
                  <p>{this.props.sale ? recent.property_status_sale : recent.property_status_rent}</p>
                </div>
                {recent.all_images.length > 1 &&
                <div
                  className={"propertyGalleryText"}>
                  <i className={"fas fa-camera"}/>
                  &nbsp;{recent.all_images.length}
                </div>
                }
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-8 prop-info">
              <div className="property-info">
                <Modal isOpen={this.state.content !== "desc" && this.state.content !== "floorplan"}
                       toggle={() => this.setState({ content: "desc" })} className={this.state.content}>
                  <ModalHeader
                    toggle={() => this.setState({ content: "desc" })}
                  />
                  {this.state.content === "virtual-tour" &&
                  <div className="property-info virtual-tour">
                    <iframe src={recent.virtual_tour_url} allowFullScreen={false} width="100%" height="100%"
                            frameBorder="0" style={{ borderWidth: 0, minHeight: 350 }}/>
                  </div>
                  }
                  {this.state.content === "epc" &&
                  recent.epc !== null && recent.epc !== undefined &&
                  <div className="property-info epc">
                    <h4 className={"address"}>{recent.property_name} - EPC</h4>
                    <div className="row">
                      <div className="col-12">
                        <img className={"img-fluid"}
                             src={`https://epc.rightmove.co.uk/generateEpc?graphtype=EPC&currentenergy=${recent.epc.efficiency_actual}&potentialenergy=${recent.epc.efficiency_potential}&currentenvironment=${recent.epc.impact_actual}&potentialenvironment=${recent.epc.impact_potential}&address=&reference=&graphquality=web`}
                             alt=""/>
                      </div>
                    </div>
                  </div>
                  }
                </Modal>
                <h4 className={"address"}>{recent.property_name}</h4>
                <div className="below-title">
                  <span className="days-remaining">{28 - recent.day_counter} Days Remaining</span>
                  {recent.floor_plans.length > 0 &&
                  <div className="doc-download">
                    <a href={recent.floor_plans[0].url} target="_blank" className="btn-download">
                      View + Download pdf
                    </a>
                  </div>
                  }
                </div>
                
                {recent.development_sale_type_id === 2 ? null :
                  this.props.sale ? recent.property_for_sale !== null && recent.property_for_sale.estimated_price !== null &&
                    <span
                      className={"asking-price-text"}>{recent.property_for_sale.price_qualifier ? recent.property_for_sale.price_qualifier.name.toUpperCase() : "ASKING PRICE"}</span>
                    : recent.property_for_rent !== null && recent.property_for_rent.rent_price !== null &&
                    <span className={"asking-price-text"}>ASKING PRICE</span>
                }
                
                {
                
                }
                
                {recent.development_sale_type_id === 2 ? null :
                  this.props.sale ? recent.property_for_sale !== null && recent.property_for_sale.estimated_price !== null &&
                    recent.property_for_sale.estimated_price === 0 ?
                    <p className="asking-price">POA</p>
                    :
                    <p className="asking-price">
                      &pound;{recent.property_for_sale.estimated_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </p>
                    : recent.property_for_rent !== null && recent.property_for_rent.rent_price !== null &&
                    <p className="asking-price">
                      &pound;{recent.property_for_rent.rent_price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      <span className={"time-frame"}>
                        {recent.property_for_rent.rent_type.name.replace("Rent", "")}
                      </span>
                    </p>
                }
                
                {/*{this.props.sale*/}
                {/*? recent.property_for_sale !== null && ( recent.property_for_sale.estimated_price <*/}
                {/*1000000*/}
                {/*? recent.property_for_sale.estimated_price*/}
                {/*.toString()*/}
                {/*.replace(/\B(?=(\d{3})+(?!\d))/g, ',')*/}
                {/*: recent.property_for_sale.estimated_price <*/}
                {/*1000000000*/}
                {/*? `${(*/}
                {/*recent.property_for_sale.estimated_price /*/}
                {/*1000000*/}
                {/*)*/}
                {/*.toFixed(3)*/}
                {/*.replace(/0+$/g, '')*/}
                {/*.replace(/\B(?=(\d{3})+(?!\d))/g, '.')*/}
                {/*.replace(/\.$/g, '')}m`*/}
                {/*: `${(*/}
                {/*recent.property_for_sale.estimated_price /*/}
                {/*1000000000*/}
                {/*)*/}
                {/*.toFixed(3)*/}
                {/*.replace(/0+$/g, '')*/}
                {/*.replace(/\\B(?=(\\d{3})+(?!\\d))/g, '.')*/}
                {/*.replace(/\.$/g, '')}b`*/}
                {/*)*/}
                {/*: recent.property_for_rent !== null && ( <React.Fragment>{recent.property_for_rent.rent_price*/}
                {/*.toString()*/}
                {/*.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                {/*<span className={"time-frame"}>{recent.property_for_rent.rent_type.name.replace('Rent', '')}</span>*/}
                {/*</React.Fragment>*/}
                {/*)}*/}
                {recent.development_sale_type_id !== null ? null :
                  <p className={"property-stats"}>{recent.bedrooms !== null ? recent.bedrooms : "n/a"} Bedrooms
                    | {recent.bathrooms !== null ? recent.bathrooms : "n/a"} Bathrooms
                    | {recent.reception_rooms !== null ? recent.reception_rooms : "n/a"} Receptions</p>
                }
                {recent.brief_description.length > 150 ?
                  !this.state.showMoreDescription ?
                    <p onClick={this.toggleShowMoreDescription} className={"property-description"}
                       dangerouslySetInnerHTML={{
                         __html: `${recent.brief_description.slice(0, 150)}... <span>Show more</span>`
                       }}/>
                    :
                    <p onClick={this.toggleShowMoreDescription} className={"property-description"}
                       dangerouslySetInnerHTML={{ __html: `${recent.brief_description} <span>Show less</span>` }}/>
                  
                  :
                  <p className={"property-description"}
                     dangerouslySetInnerHTML={{ __html: `${recent.brief_description}` }}/>
                }
                {recent.development_sale_type_id === 1 || recent.development_sale_type_id === null ?
                  <div className="row button-row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <BookAViewingModal
                        sale={this.props.sale}
                        propertyID={recent.id}
                        property_name={recent.property_name}
                        owner={recent.property_owner}
                        address={recent.address}
                        dates={recent.availability}
                        salePrice={
                          recent.property_for_sale !== null
                            ? recent.property_for_sale.estimated_price
                            : ""
                        }
                        rentPrice={
                          recent.property_for_rent !== null
                            ? `${recent.property_for_rent.rent_price} ${
                              recent.property_for_rent.rent_type.name
                            }`
                            : ""
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      {this.state.saving === recent.id.toString() ? (
                        <button
                          type={"button"}
                          disabled={recent.property_owner}
                          className={
                            "btn btn-block new-property-button mt-3"
                          }>
                          <img
                            src={"/images/ui/Rolling.svg"}
                            alt={"loading deals"}
                            style={{ width: 15, height: 15 }}
                          />
                        </button>
                      ) : this.state.saving ? (
                        this.props.sale ? (
                          <button
                            type={"button"}
                            disabled
                            onClick={() =>
                              this.saveProperty(
                                recent.id,
                                this.props.sale,
                                saved
                              )
                            }
                            className={
                              "btn btn-block new-property-button mt-3"
                            }>
                            {saved
                              ? "Unsave Property"
                              : "Save Property"}
                          </button>
                        ) : (
                          <button
                            type={"button"}
                            disabled
                            onClick={() =>
                              this.saveProperty(
                                recent.id,
                                this.props.sale,
                                saved
                              )
                            }
                            className={
                              "btn btn-block new-property-button mt-3"
                            }>
                            {saved
                              ? "Unsave Property"
                              : "Save Property"}
                          </button>
                        )
                      ) : this.props.sale ? (
                        <button
                          type={"button"}
                          disabled={recent.property_owner}
                          onClick={() =>
                            this.saveProperty(
                              recent.id,
                              this.props.sale,
                              saved
                            )
                          }
                          className={
                            "btn btn-block new-property-button mt-3"
                          }>
                          {saved
                            ? "Unsave Property"
                            : "Save Property"}
                        </button>
                      ) : (
                        <button
                          type={"button"}
                          disabled={recent.property_owner}
                          onClick={() =>
                            this.saveProperty(
                              recent.id,
                              this.props.sale,
                              saved
                            )
                          }
                          className={
                            "btn btn-block new-property-button mt-3"
                          }>
                          {saved
                            ? "Unsave Property"
                            : "Save Property"}
                        </button>
                      )}
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <MakeAnOfferModal
                        sale={this.props.sale}
                        property_name={recent.property_name}
                        salePrice={
                          recent.property_for_sale !== null
                            ? recent.property_for_sale.estimated_price
                            : ""
                        }
                        rentPrice={
                          recent.property_for_rent !== null
                            ? `${recent.property_for_rent.rent_price} ${
                              recent.property_for_rent.rent_type.name
                            }`
                            : ""
                        }
                        owner={recent.property_owner}
                        address={recent.address}
                        id={recent.id}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <button
                        className={
                          "btn btn-block new-property-button mt-3"
                        }
                        disabled={recent.property_owner}
                        onClick={() =>
                          this.contactAgentModal(
                            recent.property_name,
                            recent.id
                          )
                        }>
                        Request More Info
                      </button>
                      <Modal
                        isOpen={this.state.contactAgentModal}
                        toggle={() =>
                          this.contactAgentModal(
                            recent.property_name,
                            recent.id
                          )
                        }
                        style={{
                          maxWidth: "100%"
                        }}>
                        <ModalHeader
                          toggle={() =>
                            this.contactAgentModal(
                              recent.property_name,
                              recent.id
                            )
                          }
                        />
                        <Form
                          onSubmit={this.props.handleSubmit(
                            this.onSubmit
                          )}>
                          <div className={"row p-5"}>
                            <h4
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontWeight: 300
                              }}>
                              Contact Cobbletons
                            </h4>
                            <FormGroup className={"col-12 mt-3"}>
                              <Label>Subject</Label>
                              <Field
                                name={"subject"}
                                component={TextInput}
                                type={"text"}
                              />
                            </FormGroup>
                            <FormGroup className={"col-12"}>
                              <Label>Description</Label>
                              <Field
                                name={"description"}
                                component={"textarea"}
                                style={{
                                  width: "100%",
                                  minHeight: 300
                                }}
                              />
                            </FormGroup>
                            <FormGroup className={"col-12"}>
                              <Button
                                className={
                                  "btn cbls-dark-grey-button btn-block"
                                }>
                                {this.state.submitting ? (
                                  <img
                                    src={"/images/ui/Rolling.svg"}
                                    alt={"loading deals"}
                                    style={{ width: 15, height: 15 }}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </FormGroup>
                          </div>
                        </Form>
                      </Modal>
                    </div>
                  </div>
                  :
                  <div className="row button-row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <button
                        className={
                          "btn btn-block new-property-button mt-3"
                        }
                        disabled={recent.property_owner}
                        onClick={() =>
                          this.contactAgentModal(
                            recent.property_name,
                            recent.id
                          )
                        }>
                        Request More Info
                      </button>
                      <Modal
                        isOpen={this.state.contactAgentModal}
                        toggle={() =>
                          this.contactAgentModal(
                            recent.property_name,
                            recent.id
                          )
                        }
                        style={{
                          maxWidth: "100%"
                        }}>
                        <ModalHeader
                          toggle={() =>
                            this.contactAgentModal(
                              recent.property_name,
                              recent.id
                            )
                          }
                        />
                        <Form
                          onSubmit={this.props.handleSubmit(
                            this.onSubmit
                          )}>
                          <div className={"row p-5"}>
                            <h4
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontWeight: 300
                              }}>
                              Contact Cobbletons
                            </h4>
                            <FormGroup className={"col-12 mt-3"}>
                              <Label>Subject</Label>
                              <Field
                                name={"subject"}
                                component={TextInput}
                                type={"text"}
                              />
                            </FormGroup>
                            <FormGroup className={"col-12"}>
                              <Label>Description</Label>
                              <Field
                                name={"description"}
                                component={"textarea"}
                                style={{
                                  width: "100%",
                                  minHeight: 300
                                }}
                              />
                            </FormGroup>
                            <FormGroup className={"col-12"}>
                              <Button
                                className={
                                  "btn cbls-dark-grey-button btn-block"
                                }>
                                {this.state.submitting ? (
                                  <img
                                    src={"/images/ui/Rolling.svg"}
                                    alt={"loading deals"}
                                    style={{ width: 15, height: 15 }}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </FormGroup>
                          </div>
                        </Form>
                      </Modal>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4" style={{ padding: 5 }}>
                      <button
                        className={
                          "btn btn-block new-property-button mt-3"
                        }
                        onClick={() => {
                          window.open(`/dashboard/development/${recent.slug}`, "_blank");
                        }
                        }>
                        View all properties
                      </button>
                    </div>
                  </div>
                }
                {!window.location.search.startsWith("?type=development") && recent.parent_id !== null &&
                <p className={"development-text"}><i className="fas fa-link"/>&nbsp;&nbsp;This property is part of a
                  development. <Link to={`/dashboard/development/${recent.slug}?type=development`} target={"_blank"}>Click
                    here to view other properties.</Link></p>
                }
              </div>
            </div>
          </div>
        </div>
        
        {/*<hr />*/}
        {/*<div className={'col-12 recentlyViewedWrapper mb-5'}>*/}
        {/*<div className={'row full-border mb-4'}>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'col-12 col-lg-3 property-image d-block d-md-none d-lg-block'*/}
        {/*}*/}
        {/*onClick={() => this.openLightBox()}>*/}
        {/*</div>*/}
        {/*<div className={'col-12 col-lg-9'}>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12 col-md-7 pl-4 pr-4'}>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12 col-md-8 order-2 order-md-1'}>*/}
        {/*<h3 className={'property_name'}>{recent.property_name}</h3>*/}
        {/*<p>{recent.address.address_city}</p>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*className={`${*/}
        {/*this.props.sale ? 'sale' : 'rental'*/}
        {/*} col-md-4 col-12 pl-lg-0 pl-xl-3 order-1 order-md-2`}>*/}
        {/*{this.props.sale ? (*/}
        {/*<div*/}
        {/*className={*/}
        {/*'d-flex align-items-center justify-content-center mt-4'*/}
        {/*}>*/}
        {/*For Sale*/}
        {/*</div>*/}
        {/*) : (*/}
        {/*<div*/}
        {/*className={*/}
        {/*'d-flex align-items-center justify-content-center mt-4'*/}
        {/*}>*/}
        {/*To Let*/}
        {/*</div>*/}
        {/*)}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className={'d-none d-md-block'}>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<hr />*/}
        {/*<p*/}
        {/*dangerouslySetInnerHTML={{*/}
        {/*__html: `${recent.brief_description.slice(0, 250)}...`*/}
        {/*}}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<hr />*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12 col-md-6'}>*/}
        {/*<ul>*/}
        {/*<li>*/}
        {/*Property Type:{' '}*/}
        {/*{recent.property_types &&*/}
        {/*recent.property_types.length > 0*/}
        {/*? recent.property_types.map(*/}
        {/*(type, typeIndex) => (*/}
        {/*<span key={typeIndex}>*/}
        {/*{type.name}*/}
        {/*{recent.property_types.length*/}
        {/*? ''*/}
        {/*: ','}*/}
        {/*</span>*/}
        {/*)*/}
        {/*)*/}
        {/*: 'n/a'}*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*Bedrooms:{' '}*/}
        {/*{recent.bedrooms_from !== null*/}
        {/*? recent.bedrooms_from*/}
        {/*: 'n/a'}{' '}*/}
        {/*-{' '}*/}
        {/*{recent.bedrooms !== null*/}
        {/*? recent.bedrooms*/}
        {/*: 'n/a'}*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*Bathrooms:{' '}*/}
        {/*{recent.bathrooms_from !== null*/}
        {/*? recent.bathrooms_from*/}
        {/*: 'n/a'}{' '}*/}
        {/*-{' '}*/}
        {/*{recent.bathrooms !== null*/}
        {/*? recent.bathrooms*/}
        {/*: 'n/a'}*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*Receptions:{' '}*/}
        {/*{recent.reception_rooms_from !== null*/}
        {/*? recent.reception_rooms_from*/}
        {/*: 'n/a'}{' '}*/}
        {/*-{' '}*/}
        {/*{recent.reception_rooms !== null*/}
        {/*? recent.reception_rooms*/}
        {/*: 'n/a'}*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</div>*/}
        {/*<div className={'col-12 col-md-6'}>*/}
        {/*<ul>*/}
        {/*<li>No. Viewed: {recent.search_counter}</li>*/}
        {/*<li>No. Viewings: {recent.viewings_count}</li>*/}
        {/*<li>No. Offers: {recent.offers_count}</li>*/}
        {/*<li>*/}
        {/*Price Reduction:{' '}*/}
        {/*{recent.price_reduction ? 'Yes' : 'No'}*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className={'col-12 col-md-5 border-left'}>*/}
        {/*<hr className={'d-md-none mb-0'} />*/}
        {/*<div className="line-border d-none d-xl-block" />*/}
        {/*<div className={'row h-lg-inherit'}>*/}
        {/*<div*/}
        {/*className={'col-12 d-none d-md-block d-lg-none'}*/}
        {/*style={{*/}
        {/*background: `${*/}
        {/*recent.all_images.length > 0*/}
        {/*? `url(${recent.all_images[0].image.url})`*/}
        {/*: `url(${defaultImage})`*/}
        {/*} no-repeat center center`,*/}
        {/*backgroundSize: 'cover',*/}
        {/*height: 175,*/}
        {/*cursor: 'pointer'*/}
        {/*}}*/}
        {/*onClick={() => this.openLightBox()}>*/}
        {/*<div*/}
        {/*className={'propertyGalleryText'}*/}
        {/*style={{*/}
        {/*height: '25%',*/}
        {/*width: '100%',*/}
        {/*position: 'absolute',*/}
        {/*bottom: 0,*/}
        {/*left: 0,*/}
        {/*backgroundImage:*/}
        {/*'linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 97%)',*/}
        {/*textAlign: 'center',*/}
        {/*color: 'white',*/}
        {/*alignItems: 'center',*/}
        {/*justifyContent: 'center',*/}
        {/*display: 'flex'*/}
        {/*}}>*/}
        {/*<i className={'fas fa-camera'} />*/}
        {/*&nbsp;&nbsp;Gallery*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className={'col-12 h-lg-inherit'}>*/}
        {/*<div className={'row h-lg-inherit'}>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'col-12 col-xl-6 justify-content-center align-items-center'*/}
        {/*}>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'row justify-content-center align-items-center'*/}
        {/*}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<div className={'row'}>*/}
        {/*<BookAViewingModal*/}
        {/*sale={this.props.sale}*/}
        {/*propertyID={recent.id}*/}
        {/*property_name={recent.property_name}*/}
        {/*owner={recent.property_owner}*/}
        {/*address={recent.address}*/}
        {/*salePrice={*/}
        {/*recent.property_for_sale !== null*/}
        {/*? recent.property_for_sale.estimated_price*/}
        {/*: ''*/}
        {/*}*/}
        {/*rentPrice={*/}
        {/*recent.property_for_rent !== null*/}
        {/*? `${recent.property_for_rent.rent_price} ${*/}
        {/*recent.property_for_rent.rent_type.name*/}
        {/*}`*/}
        {/*: ''*/}
        {/*}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*{this.state.saving === recent.id.toString() ? (*/}
        {/*<button*/}
        {/*type={'button'}*/}
        {/*disabled={recent.property_owner}*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}>*/}
        {/*<img*/}
        {/*src={'/images/ui/Rolling.svg'}*/}
        {/*alt={'loading deals'}*/}
        {/*style={{ width: 15, height: 15 }}*/}
        {/*/>*/}
        {/*</button>*/}
        {/*) : this.state.saving ? (*/}
        {/*this.props.sale ? (*/}
        {/*<button*/}
        {/*type={'button'}*/}
        {/*disabled*/}
        {/*onClick={() =>*/}
        {/*this.saveProperty(*/}
        {/*recent.id,*/}
        {/*this.props.sale,*/}
        {/*saved*/}
        {/*)*/}
        {/*}*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}>*/}
        {/*{saved*/}
        {/*? 'Unsave Property'*/}
        {/*: 'Save Property'}*/}
        {/*</button>*/}
        {/*) : (*/}
        {/*<button*/}
        {/*type={'button'}*/}
        {/*disabled*/}
        {/*onClick={() =>*/}
        {/*this.saveProperty(*/}
        {/*recent.id,*/}
        {/*this.props.sale,*/}
        {/*saved*/}
        {/*)*/}
        {/*}*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}>*/}
        {/*{saved*/}
        {/*? 'Unsave Property'*/}
        {/*: 'Save Property'}*/}
        {/*</button>*/}
        {/*)*/}
        {/*) : this.props.sale ? (*/}
        {/*<button*/}
        {/*type={'button'}*/}
        {/*disabled={recent.property_owner}*/}
        {/*onClick={() =>*/}
        {/*this.saveProperty(*/}
        {/*recent.id,*/}
        {/*this.props.sale,*/}
        {/*saved*/}
        {/*)*/}
        {/*}*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}>*/}
        {/*{saved*/}
        {/*? 'Unsave Property'*/}
        {/*: 'Save Property'}*/}
        {/*</button>*/}
        {/*) : (*/}
        {/*<button*/}
        {/*type={'button'}*/}
        {/*disabled={recent.property_owner}*/}
        {/*onClick={() =>*/}
        {/*this.saveProperty(*/}
        {/*recent.id,*/}
        {/*this.props.sale,*/}
        {/*saved*/}
        {/*)*/}
        {/*}*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}>*/}
        {/*{saved*/}
        {/*? 'Unsave Property'*/}
        {/*: 'Save Property'}*/}
        {/*</button>*/}
        {/*)}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<MakeAnOfferModal*/}
        {/*sale={this.props.sale}*/}
        {/*property_name={recent.property_name}*/}
        {/*salePrice={*/}
        {/*recent.property_for_sale !== null*/}
        {/*? recent.property_for_sale.estimated_price*/}
        {/*: ''*/}
        {/*}*/}
        {/*rentPrice={*/}
        {/*recent.property_for_rent !== null*/}
        {/*? `${recent.property_for_rent.rent_price} ${*/}
        {/*recent.property_for_rent.rent_type.name*/}
        {/*}`*/}
        {/*: ''*/}
        {/*}*/}
        {/*owner={recent.property_owner}*/}
        {/*address={recent.address}*/}
        {/*id={recent.id}*/}
        {/*/>*/}
        {/*<div className={'row'}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<button*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button'*/}
        {/*}*/}
        {/*disabled={recent.property_owner}*/}
        {/*onClick={() =>*/}
        {/*this.contactAgentModal(*/}
        {/*recent.property_name,*/}
        {/*recent.id*/}
        {/*)*/}
        {/*}>*/}
        {/*Contact Cobbletons*/}
        {/*</button>*/}
        {/*<Modal*/}
        {/*isOpen={this.state.contactAgentModal}*/}
        {/*toggle={() =>*/}
        {/*this.contactAgentModal(*/}
        {/*recent.property_name,*/}
        {/*recent.id*/}
        {/*)*/}
        {/*}*/}
        {/*style={{*/}
        {/*maxWidth: '100%'*/}
        {/*}}>*/}
        {/*<ModalHeader*/}
        {/*toggle={() =>*/}
        {/*this.contactAgentModal(*/}
        {/*recent.property_name,*/}
        {/*recent.id*/}
        {/*)*/}
        {/*}*/}
        {/*/>*/}
        {/*<Form*/}
        {/*onSubmit={this.props.handleSubmit(*/}
        {/*this.onSubmit*/}
        {/*)}>*/}
        {/*<div className={'row p-5'}>*/}
        {/*<h4*/}
        {/*style={{*/}
        {/*width: '100%',*/}
        {/*textAlign: 'center',*/}
        {/*fontWeight: 300*/}
        {/*}}>*/}
        {/*Contact Agent*/}
        {/*</h4>*/}
        {/*<FormGroup className={'col-12 mt-3'}>*/}
        {/*<Label>Subject</Label>*/}
        {/*<Field*/}
        {/*name={'subject'}*/}
        {/*component={TextInput}*/}
        {/*type={'text'}*/}
        {/*/>*/}
        {/*</FormGroup>*/}
        {/*<FormGroup className={'col-12'}>*/}
        {/*<Label>Description</Label>*/}
        {/*<Field*/}
        {/*name={'description'}*/}
        {/*component={'textarea'}*/}
        {/*style={{*/}
        {/*width: '100%',*/}
        {/*minHeight: 300*/}
        {/*}}*/}
        {/*/>*/}
        {/*</FormGroup>*/}
        {/*<FormGroup className={'col-12'}>*/}
        {/*<Button*/}
        {/*className={*/}
        {/*'btn cbls-dark-grey-button btn-block'*/}
        {/*}>*/}
        {/*{this.state.submitting ? (*/}
        {/*<img*/}
        {/*src={'/images/ui/Rolling.svg'}*/}
        {/*alt={'loading deals'}*/}
        {/*style={{ width: 15, height: 15 }}*/}
        {/*/>*/}
        {/*) : (*/}
        {/*'Submit'*/}
        {/*)}*/}
        {/*</Button>*/}
        {/*</FormGroup>*/}
        {/*</div>*/}
        {/*</Form>*/}
        {/*</Modal>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'row mb-3 justify-content-center align-items-center text-center'*/}
        {/*}>*/}
        {/*{recent.virtual_tour_url ? (*/}
        {/*<div className={'col-12'}>*/}
        {/*<button*/}
        {/*className={*/}
        {/*'btn btn-block cbls-dark-grey-button outline recent-button border-0'*/}
        {/*}*/}
        {/*onClick={() =>*/}
        {/*this.toggleVirtualTourModal(recent.id)*/}
        {/*}*/}
        {/*style={{*/}
        {/*height: 'auto',*/}
        {/*whiteSpace: 'normal'*/}
        {/*}}>*/}
        {/*<div className={'col-12'}>*/}
        {/*<div className={'row'}>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'col-3 d-flex align-items-center justify-content-center'*/}
        {/*}>*/}
        {/*<i className={'fa fa-flag'} />*/}
        {/*</div>*/}
        {/*<div className={'col'}>*/}
        {/*Virtual Tour Available*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</button>*/}
        {/*<VirtualTourModal*/}
        {/*url={recent.virtual_tour_url}*/}
        {/*isOpen={this.state.virtualTourModal}*/}
        {/*index={recent.id}*/}
        {/*toggle={this.toggleVirtualTourModal}*/}
        {/*/>*/}
        {/*</div>*/}
        {/*) : null}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*className={*/}
        {/*'col-6 d-none d-xl-flex justify-content-center align-items-center text-center'*/}
        {/*}>*/}
        {/*{this.props.sale*/}
        {/*? recent.property_for_sale !== null && (*/}
        {/*<h3*/}
        {/*style={{*/}
        {/*fontSize: 18,*/}
        {/*width: '100%',*/}
        {/*marginBottom: 0*/}
        {/*}}>*/}
        {/*&pound;&nbsp;*/}
        {/*{recent.property_for_sale.estimated_price <*/}
        {/*1000000*/}
        {/*? recent.property_for_sale.estimated_price*/}
        {/*.toString()*/}
        {/*.replace(/\B(?=(\d{3})+(?!\d))/g, ',')*/}
        {/*: recent.property_for_sale.estimated_price <*/}
        {/*1000000000*/}
        {/*? `${(*/}
        {/*recent.property_for_sale.estimated_price /*/}
        {/*1000000*/}
        {/*)*/}
        {/*.toFixed(3)*/}
        {/*.replace(/0+$/g, '')*/}
        {/*.replace(/\B(?=(\d{3})+(?!\d))/g, '.')*/}
        {/*.replace(/\.$/g, '')}m`*/}
        {/*: `${(*/}
        {/*recent.property_for_sale.estimated_price /*/}
        {/*1000000000*/}
        {/*)*/}
        {/*.toFixed(3)*/}
        {/*.replace(/0+$/g, '')*/}
        {/*.replace(/\\B(?=(\\d{3})+(?!\\d))/g, '.')*/}
        {/*.replace(/\.$/g, '')}b`}*/}
        {/*</h3>*/}
        {/*)*/}
        {/*: recent.property_for_rent !== null && (*/}
        {/*<div className={'text-center'}>*/}
        {/*<h3*/}
        {/*style={{*/}
        {/*fontSize: 18,*/}
        {/*width: '100%',*/}
        {/*marginBottom: 0*/}
        {/*}}>*/}
        {/*&pound;&nbsp;*/}
        {/*{recent.property_for_rent.rent_price*/}
        {/*.toString()*/}
        {/*.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
        {/*</h3>*/}
        {/*<p>{recent.property_for_rent.rent_type.name}</p>*/}
        {/*</div>*/}
        {/*)}*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
      </React.Fragment>
    ) : null;
  }
}

export default connect()(
  reduxForm({ form: "contactAgentForm" })(PropertyComponent)
);
