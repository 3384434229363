import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, FieldArray } from 'redux-form';
import { Label, Button } from 'reactstrap';

import DatePickerInput from '../../../../../components/Fields/DatePicker';

import './Availability.scss';
import moment from 'moment';

const TimesComponent = ({ fields, day }) => (
  <React.Fragment>
    {fields.map((time, i) => (
      <React.Fragment key={i}>
        <div className={'row'}>
          <div className={'col-10'}>
            <div className={'row'}>
              <div className={'col-12 col-md-6 mt-2'}>
                <Label>Time From</Label>
                <Field
                  name={`${time}.time_from`}
                  component={DatePickerInput}
                  showTimeSelectOnly
                  showTimeSelect
                  dateFormat={'HH:mm a'}
                />
              </div>
              <div className={'col-12 col-md-6 mt-2'}>
                <Label>Time To</Label>
                <Field
                  name={`${time}.time_to`}
                  component={DatePickerInput}
                  showTimeSelectOnly
                  showTimeSelect
                  dateFormat={'HH:mm a'}
                />
              </div>
            </div>
          </div>
          <div
            className={
              'col align-items-end justify-content-center d-flex w-100'
            }>
            <Button
              className={'w-100 ml-0'}
              style={{ height: 38 }}
              onClick={() => fields.remove(i)}
              type={'button'}>
              <i className={'fas fa-trash'} />
            </Button>
          </div>
        </div>
      </React.Fragment>
    ))}
    <Button
      type={'button'}
      className={'btn cbls-dark-grey-button mt-2 w-auto ml-0'}
      onClick={() => fields.push({ day, time_to: '', time_from: '' })}>
      Add Another Timeframe
    </Button>
  </React.Fragment>
);

const DaysComponent = ({ fields, dayTab }) =>
  fields.map(
    (day, i) =>
      i + 1 === dayTab && (
        <div key={i}>
          <Label className={'mt-4 font-weight-bold'}>
            I am available every {i === 0
              ? 'Monday'
              : i === 1
              ? 'Tuesday'
              : i === 2
              ? 'Wednesday'
              : i === 3
              ? 'Thursday'
              : i === 4
              ? 'Friday'
              : i === 5
              ? 'Saturday'
              : 'Sunday'} between the times below
          </Label>
          <hr />
          <FieldArray
            name={`${day}.times`}
            day={i + 1}
            component={TimesComponent}
          />
          <hr />
        </div>
      )
  );

class Availability extends React.Component {
  state = {
    dayTab: 1
  };

  toggleTab = num => {
    if (num !== this.state.dayTab) {
      this.setState({
        dayTab: num
      });
    }
  };

  render() {
    const { props } = this;
    const { fields } = props;
    return (
      <React.Fragment>
        {fields.length === 0 ? (
          <div>
            <p>
              Default Availability is 9am - 5pm, Monday - Friday, Click Add New
              to set a custom availability
            </p>
            <button
              className={'btn cbls-dark-grey-button mt-2'}
              type={'button'}
              onClick={() =>
                fields.push({
                  days: [
                    {
                      times: [
                        {
                          day: 1,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 2,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 3,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 4,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 5,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 6,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    },
                    {
                      times: [
                        {
                          day: 7,
                          time_from: moment()
                            .hour(9)
                            .minute(0),
                          time_to: moment()
                            .hour(17)
                            .minute(0)
                        }
                      ]
                    }
                  ]
                })
              }>
              Add Custom Availability
            </button>
          </div>
        ) : (
          <div>
            {fields.map((timeSlot, i) => (
              <React.Fragment>
                <div className={'d-inline availability'}>
                  <div
                    className={'tab-buttons d-flex justify-content-end mb-3'}>
                    <button
                      onClick={() => this.toggleTab(1)}
                      type={'button'}
                      className={`${this.state.dayTab === 1 && 'active'}`}>
                      Mon
                    </button>
                    <button
                      onClick={() => this.toggleTab(2)}
                      type={'button'}
                      className={`${this.state.dayTab === 2 && 'active'}`}>
                      Tue
                    </button>
                    <button
                      onClick={() => this.toggleTab(3)}
                      type={'button'}
                      className={`${this.state.dayTab === 3 && 'active'}`}>
                      Wed
                    </button>
                    <button
                      onClick={() => this.toggleTab(4)}
                      type={'button'}
                      className={`${this.state.dayTab === 4 && 'active'}`}>
                      Thu
                    </button>
                    <button
                      onClick={() => this.toggleTab(5)}
                      type={'button'}
                      className={`${this.state.dayTab === 5 && 'active'}`}>
                      Fri
                    </button>
                    <button
                      onClick={() => this.toggleTab(6)}
                      type={'button'}
                      className={`${this.state.dayTab === 6 && 'active'}`}>
                      Sat
                    </button>
                    <button
                      onClick={() => this.toggleTab(7)}
                      type={'button'}
                      className={`${this.state.dayTab === 7 && 'active'}`}>
                      Sun
                    </button>
                  </div>
                  <div className={'tab'}>
                    <FieldArray
                      name={`${timeSlot}.days`}
                      component={DaysComponent}
                      dayTab={this.state.dayTab}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <Button className={'btn cbls-dark-grey-button mt-2'}>Save</Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('ScheduleForm');

const mapStateToProps = state => {
  return {
    availabilities: selector(state, 'availabilities')
  };
};

export default connect(mapStateToProps)(Availability);
