import React from 'react'

const Loading = props => (
  <div className={'container mt-5 mb-5'}>
    <div className={'col-12 mt-5 mb-5'}>
      <div className={'row d-flex justify-content-center mt-5 mb-5'}>
        <img className={"mt-5 mb-5"} src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: props.dimensions ? props.dimensions : 75, height: props.dimensions ? props.dimensions : 75 }} />
      </div>
    </div>
  </div>
);

export default Loading
