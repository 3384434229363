import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LogoBanner from '../LogoBanner';
import LandingNav from '../LandingNav';
import Video from '../Video';

import './index.scss';

const LandingContent = props => (
  <div className={"landing-content"}>
    <LogoBanner />
    <div className="cbls-container">
      <Video Banner_Subheading_Type_Wysiwyg={props.Banner_Subheading_Type_Wysiwyg} />
    </div>
  </div>
)

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LandingContent);
