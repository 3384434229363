import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SaleProperties from './SaleProperties';
import Offers from './Offers';
import Viewings from './Viewing';
import { withStyles } from "@material-ui/core/styles";

const TabContainer = ({ children, dir }) => <div dir={dir}>{children}</div>;

const styles = () => ({
  tabsIndicator: {
    backgroundColor: '#1a1a1a',
    zIndex: 4
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontFamily: '"proxima-nova", sans-serif',
  }
});
class SalesTabs extends React.Component {
  state = {
    index: 0
  };
  
  componentDidMount() {
    this.checkRef()
  }
  
  checkRef = () => {
    let url = new Map(window.location.search.slice(1).split('&').map(kv => kv.split('=')));
    this.setState({
      index: parseInt(url.get('tab') !== undefined ? url.get('tab') : 0, 10)
    })
  };
  
  handleChange = (event, value) => {
    this.setState({ index: value });
  };

  handleChangeIndex = value => {
    this.setState({ index: value });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar
          position="static"
          color="inherit"
          style={{ boxShadow: 'none' }}
          >
          <div className={'row'}>
            <div className={'col-10'}>
              <Tabs
                classes={{ indicator: classes.tabsIndicator }}
                value={this.state.index}
                onChange={this.handleChange}
                fullWidth>
                <Tab label="My Sales Properties" classes={{ root: classes.tabRoot }} />
                <Tab label="Offers" classes={{ root: classes.tabRoot }} />
                <Tab label="Viewings" classes={{ root: classes.tabRoot }} />
              </Tabs>
              <div className={'default-indicator'} />
            </div>
          </div>
        </AppBar>
        <SwipeableViews
          axis={'x'}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}>
          <TabContainer dir={'ltr'}>
            {this.state.index === 0 && <SaleProperties />}
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.index === 1 && <Offers />}
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.index === 2 && <Viewings />}
          </TabContainer>
        </SwipeableViews>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SalesTabs);
