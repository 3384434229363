import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Wysiwyg from "../../Fields/Wysiwyg";
import moment from "moment";
import SelectDropdown from '../../Fields/SelectDropdown';
import DatePickerInput from "../../Fields/DatePicker";

import * as Validations from '../../../constants/Validation';

class ViewingForm extends React.Component {
  state = {
    step: 0
  };
  
  nextStep = () => {
    if (this.state.step !== 2) {
      this.setState({
        step: this.state.step + 1
      });
    }
  };

  prevStep = () => {
    if (this.state.step !== 0) {
      this.setState({
        step: this.state.step - 1
      });
    }
  };

  render() {
    const { props } = this;
    const { onSubmit, handleSubmit, address, value, property_name } = props;
    const self = this;
    return (
      <Form>
        <div className={'row p-5 mb-3 justify-content-center'}>
          <h4
            style={{
              width: '100%',
              textAlign: 'center',
              marginBottom: 50,
              fontWeight: 300
            }}>
            Book a Viewing
          </h4>
          <div className={'col-12 text-center d-flex justify-content-center'}>
            <p
              style={{
                fontFamily: 'ProximaNova-Regular, sans-serif',
                fontSize: 14,
                color: '#383B41',
                letterSpacing: '0px'
              }}>
              <b>Property:</b>{' '}
              {`${property_name}`}
            </p>
            <p
              style={{
                fontFamily: 'ProximaNova-Regular, sans-serif',
                fontSize: 14,
                color: '#383B41',
                letterSpacing: '0px',
                marginLeft: 25
              }}>
              <b>Price:</b> £
              {this.props.sale
                ? this.props.salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                : this.props.rentPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
              }
            </p>
          </div>
        </div>
        <div className={'row justify-content-center'}>
          <div className={'col-8 border-bottom'} />
        </div>
        <div className={'row steps justify-content-center'}>
          <div
            className={`col-4 pl-0 step ${this.state.step === 0 && 'active'}`}>
            <div className={'circle mr-auto pt-2'}>1</div>
          </div>
          <div
            className={`col-4 text-center step pr-0 ${this.state.step === 1 &&
              'active'}`}>
            <div className={'circle ml-auto pt-2'}>2</div>
          </div>
        </div>
        <div className={'row mt-4'}>
          {this.state.step === 0 && (
            <FormGroup className={'col-12'}>
              <div className="row d-flex justify-content-center">
              <div className={'col-10'}>
                <Label className={"d-block"} style={{ textAlign: 'center' }}>Select an available date and time</Label>
                <div className={"mx-auto d-table"}>
                <Field name={'time_from'} component={DatePickerInput} inline dateFormat={"DD/MM/YYYY"} includeDates={props.dates.length > 0 ? props.dates.map((date) => moment(date.date)) : false} options={props.dates} validate={Validations.required} />
                </div>
              </div>
              </div>
              {this.props.time_from !== null && this.props.time_from !== undefined && this.props.time_from !== '' && props.dates.length > 0 ?
                  <div className="row d-flex justify-content-center">
                    <div className={'col-10'}>
                  <Label>AM or PM</Label>
                  <Field
                    name={"time_of_day"}
                    component={SelectDropdown}
                    options={
                      props.dates[props.dates.findIndex((date) => moment(date.date).format("DD/MM/YYYY").toString() === moment(self.props.time_from).format("DD/MM/YYYY").toString())].time_of_day === "AM" ? [{ label: "AM", value: "AM" }] : props.dates[props.dates.findIndex((date) => moment(date.date).format("DD/MM/YYYY").toString() === moment(self.props.time_from).format("DD/MM/YYYY").toString())].time_of_day === "PM" ? [{ label: "PM", value: "PM" }] : [{ label: "AM", value: "AM" }, { label: "PM", value: "PM" }]
                    }
                    validate={Validations.required}
                  />
                    </div>
                </div>
                : this.props.time_from !== null && this.props.time_from !== undefined && this.props.time_from !== '' ?
                  <div className="row d-flex justify-content-center">
                    <div className={'col-6'}>
                      <Label>AM or PM</Label>
                      <Field
                        name={"time_of_day"}
                        component={SelectDropdown}
                        options={
                          // [{ label: "AM", value: "AM" }, { label: "PM", value: "PM" }]
                          [
                            {
                              label: '8 AM',
                              value: '8 AM'
                            },
                            {
                              label: '9 AM',
                              value: '9 AM'
                            },
                            {
                              label: '10 AM',
                              value: '10 AM'
                            },
                            {
                              label: '11 AM',
                              value: '11 AM'
                            },
                            {
                              label: '12 PM',
                              value: '12 PM'
                            },
                            {
                              label: '1 PM',
                              value: '1 PM'
                            },
                            {
                              label: '2 PM',
                              value: '2 PM'
                            },
                            {
                              label: '3 PM',
                              value: '3 PM'
                            },
                            {
                              label: '4 PM',
                              value: '4 PM'
                            },
                            {
                              label: '5 PM',
                              value: '5 PM'
                            },
                            {
                              label: '6 PM',
                              value: '6 PM'
                            },
                            {
                              label: '7 PM',
                              value: '7 PM'
                            },
                            {
                              label: '8 PM',
                              value: '8 PM'
                            }
                          ]
                        }
                        validate={Validations.required}
                      />
                    </div>
                  </div>
                  : null
              }
            </FormGroup>
          )}
          {this.state.step === 1 && (
            <FormGroup className={'col-12 justify-content-center d-flex'}>
              <div className={'col-10'}>
                <p className={'text-center bold'}>
                  <b>Additional Message (Optional)</b>
                </p>
                <Field
                  name={'comments'}
                  component={Wysiwyg}
                  toolbarConfig={{
                    display: [],
                    INLINE_STYLE_BUTTONS: [],
                    BLOCK_TYPE_DROPDOWN: [],
                    BLOCK_TYPE_BUTTONS: []
                  }}
                  style={{ width: '100%', height: "calc(42px * 2)" }}
                />
              </div>
            </FormGroup>
          )}
        </div>
        <div className={'row justify-content-center mt-4'}>
          <div className={'col-10'}>
            <div className={'row mb-3 d-flex justify-content-center'}>
              {this.state.step !== 0 && (
                <div
                  className={
                    'col-6 justify-content-center d-flex align-items-center'
                  }>
                  <Button
                    className={'btn cbls-dark-grey-button'}
                    type={'button'}
                    onClick={() => this.prevStep()}
                    style={{ width: 200 }}>
                    Back
                  </Button>
                </div>
              )}
              {this.state.step === 1 ? (
                <div
                  className={
                    'col-6 justify-content-center d-flex align-items-center'
                  }>
                  <Button
                    className={'btn cbls-dark-grey-button'}
                    type={'button'}
                    onClick={handleSubmit(values => onSubmit(values))}
                    style={{ width: 200 }}>
                    {this.props.saving ? (
                      <img
                        src={'/images/ui/Rolling.svg'}
                        alt={'loading deals'}
                        style={{ width: 15, height: 15 }}
                      />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </div>
              ) : (
                <div
                  className={
                    'col-6 justify-content-center d-flex align-items-center'
                  }>
                  <Button
                    className={'btn cbls-dark-grey-button'}
                    type={'button'}
                    onClick={() => this.nextStep()}
                    style={{ width: 200 }}>
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const selector = formValueSelector('BookAViewingForm');

const mapStateToProps = state => ({
  time_from: selector(state, 'time_from')
});

export default connect(mapStateToProps)(reduxForm({ form: 'BookAViewingForm' })(ViewingForm));
