import React from 'react';
import { connect } from 'react-redux';
import SearchBox from '../../../../components/SearchBox';

import './index.scss';
import MySalesSearchTabs from './components/Tabs';

class MySalesSearch extends React.Component {
  render() {
    return (
      <div className={'MyCBLSWrapper mySalesSearch'}>
        <div className={'london-bg-day h-400'}>
          <div className={'container align-items-center justify-content-center h-100 d-flex'}>
            <div className={'d-flex  mt-5 justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container mt-4 mb-4'}>
          <div className={'row mt-5 mb-5'}>
            <div className={'col-12 col-md-7 mt-5 mb-3'}>
              <h2 className={'dashboard-title tk-essonnes-headline'}>My Buying Search</h2>
              <p>You are logged in as: {this.props.user.primary_email}</p>
            </div>
          </div>
          <MySalesSearchTabs />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user
});

export default connect(mapStateToProps)(MySalesSearch);
