import React from 'react';
import moment from 'moment';

import './index.scss';

class HorizontalDatePicker extends React.Component {
  state = {
    dateToday: moment().startOf('day'),
    selectedDate: this.props.defaultValue,
    firstDate: moment(),
    lastDate: moment()
      .add(2, 'days')
      .startOf('day')
  };

  selectDate = date => {
    this.setState({
      selectedDate: date
    });
    this.props.input.onChange(date);
  };

  nextThree = () => {
    const newFirstDate = moment(this.state.lastDate)
      .add(1, 'days')
      .startOf('day');
    const newLastDate = moment(this.state.lastDate)
      .add(3, 'days')
      .startOf('day');
    this.setState({
      firstDate: newFirstDate,
      lastDate: newLastDate
    });
  };

  prevThree = () => {
    const newFirstDate = moment(this.state.firstDate)
      .subtract(3, 'days')
      .startOf('day');
    const newLastDate = moment(this.state.firstDate)
      .subtract(1, 'days')
      .startOf('day');
    this.setState({
      firstDate: newFirstDate,
      lastDate: newLastDate
    });
  };

  render() {
    const { state } = this;
    const { firstDate, selectedDate } = state;
    return (
      <div className={'row justify-content-center horizontalDatePicker'}>
        <div className={'dateBoxButton dateBox col-1'}>
          <button type={'button'} onClick={() => this.prevThree()}>
            <i className={'fa fa-chevron-left'} />
          </button>
        </div>
        <div className={'col-8 pl-0 pr-0'}>
          <div
            className={'row mb-3'}
            style={{ justifyContent: 'center' }}>
            <div className={'dateBox col-3'}>
              <button
                disabled={moment(firstDate).isBefore(moment().startOf('day'))}
                className={` ${moment(firstDate).format('DD/MM/YYYY') ===
                  moment(selectedDate).format('DD/MM/YYYY') && 'selected'}`}
                type={'button'}
                onClick={() => this.selectDate(moment(firstDate))}>
                {moment(firstDate).format('DD/MM/YYYY') ===
                moment(selectedDate).format('DD/MM/YYYY') ? (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate).format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate).format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate).format('MMM')}
                    </div>
                  </div>
                ) : (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate).format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate).format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate).format('MMM')}
                    </div>
                  </div>
                )}
              </button>
            </div>
            <div className={'dateBox col-3'}>
              <button
                disabled={moment(firstDate)
                  .add(1, 'days')
                  .isBefore(moment().startOf('day'))}
                className={` ${moment(firstDate)
                  .add(1, 'days')
                  .format('DD/MM/YYYY') ===
                  moment(selectedDate).format('DD/MM/YYYY') && 'selected'}`}
                type={'button'}
                onClick={() =>
                  this.selectDate(moment(firstDate).add(1, 'days'))
                }>
                {moment(firstDate)
                  .add(1, 'days')
                  .format('DD/MM/YYYY') ===
                moment(selectedDate).format('DD/MM/YYYY') ? (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('MMM')}
                    </div>
                  </div>
                ) : (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate)
                        .add(1, 'days')
                        .format('MMM')}
                    </div>
                  </div>
                )}
              </button>
            </div>
            <div className={'dateBox col-3'}>
              <button
                disabled={moment(firstDate)
                  .add(2, 'days')
                  .isBefore(moment().startOf('day'))}
                className={` ${moment(firstDate)
                  .add(2, 'days')
                  .format('DD/MM/YYYY') ===
                  moment(selectedDate).format('DD/MM/YYYY') && 'selected'}`}
                type={'button'}
                onClick={() =>
                  this.selectDate(moment(firstDate).add(2, 'days'))
                }>
                {moment(firstDate)
                  .add(2, 'days')
                  .format('DD/MM/YYYY') ===
                moment(selectedDate).format('DD/MM/YYYY') ? (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('MMM')}
                    </div>
                  </div>
                ) : (
                  <div className={'row'}>
                    <div className={'col-12 dayOfWeek'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('ddd')}
                    </div>
                    <div className={'col-12 day'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('D')}
                    </div>
                    <div className={'col-12 month'}>
                      {moment(firstDate)
                        .add(2, 'days')
                        .format('MMM')}
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={'dateBoxButton dateBox col-1'}>
          <button type={'button'} onClick={() => this.nextThree()}>
            <i className={'fa fa-chevron-right'} />
          </button>
        </div>
      </div>
    );
  }
}

export default HorizontalDatePicker;
