import React from 'react';
import moment from 'moment';
import Http from '../../../../../constants/Http';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Overview from './Overview';
import Profile from './Profile';
import Schedule from './Schedule';
import swal from "sweetalert";

const TabContainer = ({ children, dir }) => <div dir={dir}>{children}</div>;

const styles = () => ({
  tabsIndicator: {
    backgroundColor: '#1a1a1a',
    zIndex: 4
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontFamily: '"proxima-nova", sans-serif',
  }
});


class MyCblsTabs extends React.Component {
  state = {
    value: 0,
    overview: null,
    profile: null,
    events: null,
    availabilities: null,
    activities: null
  };
  
  componentDidMount() {
    this.checkRef();
  }
  
  checkRef = () => {
    let url = new Map(window.location.search.slice(1).split('&').map(kv => kv.split('=')));
    this.setState({
      value: parseInt(url.get('tab') !== undefined ? url.get('tab') : 0, 10)
    })
  };

  getEvents = () => {
    Http.get('/auth/my-cobbletons/schedule')
      .then(res => {
        let events = [];
        res.data.data.viewings.data.forEach((date, i) => {
          events.push({
            title: date.title,
            start: moment(date.time_from)
              .utc()
              .toDate(),
            end: moment(date.time_to)
              .utc()
              .toDate(),
            type: 'viewing',
            tooltip: `${date.title} : ${moment(date.time_from)
              .utc()
              .format('LLL')} - ${moment(date.time_to)
              .utc()
              .format('LLL')}`
          });
        });
        this.setState({
          events,
          availabilities: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  getProfile = () => {
    Http.get('/auth/my-cobbletons/profile')
      .then(res => {
        this.setState({
          profile: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  getOverview = () => {
    Http.get('/auth/my-cobbletons/overview')
      .then(res => {
        this.setState({
          overview: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
    Http.get('/auth/latest-activities')
      .then(res => {
        this.setState({
          activities: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  read = (id, index) => {
    Http.post('/auth/latest-activities', { ids: [id] })
      .then(res => {
        let activities = this.state.activities;
        this.setState({
          activities
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  setSaved = (id, tab) => {
    let data = this.state.overview;
    let property = data.properties[tab ? 'sales' : 'rentals'][id]
    
    property[tab ? 'is_saved_sale' : 'is_saved_rent'] = !property[tab ? 'is_saved_sale' : 'is_saved_rent'];
    
    data.properties[tab ? 'sales' : 'rentals'][id] = property;
    
    this.setState({
      overview: data
    })
  };

  updateProfile = values => {
    console.log(values);
    Http.put('/auth/my-cobbletons/profile', values)
      .then(res => {
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar
          position="static"
          color="inherit" style={{ boxShadow: 'none' }}>
          <div className={'row'}>
            <div className={'col col-sm-8 col-xl-6'}>
              <Tabs
                classes={{ indicator: classes.tabsIndicator }}
                value={this.state.value}
                onChange={this.handleChange}
                fullWidth>
                <Tab label="Overview" classes={{ root: classes.tabRoot }} />
                <Tab label="Profile" classes={{ root: classes.tabRoot }} />
                <Tab label="Schedule" classes={{ root: classes.tabRoot }} />
              </Tabs>
              <div className={'default-indicator'} />
            </div>
          </div>
        </AppBar>
        <SwipeableViews
          axis={'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}>
          <TabContainer dir={'ltr'}>
            {this.state.value === 0 && (
              <Overview
                data={this.state.overview}
                read={this.read}
                activities={this.state.activities}
                get={() => this.getOverview()}
                setSaved={(id) => this.setSaved(id)}
              />
            )}
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.value === 1 && (
              <Profile
                initialValues={this.state.profile ? this.state.profile : null}
                enableReinitialize={true}
                updateProfile={this.updateProfile}
                get={() => this.getProfile()}
              />
            )}
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.value === 2 && (
              <Schedule
                events={this.state.events}
                enableReinitialize={true}
                initialValues={
                  this.state.availabilities ? this.state.availabilities : null
                }
                get={() => this.getEvents()}
              />
            )}
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(MyCblsTabs));
