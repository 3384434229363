import React from 'react';
import ReactStars from 'react-stars';

class StarRating extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      value: null
    };
    
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(newRating) {
    this.setState({
      value: newRating
    });
    this.props.input.onChange(newRating);
  }
  
  render() {
    const { count } = this.props;
    return (
      <div>
        <div className="star-rating">
          <ReactStars
            count={count}
            onChange={this.onChange}
            size={32}
            value={this.props.input.value}
            color2={this.props.color}
            half={!!this.props.half}
            edit={!this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

export default StarRating;