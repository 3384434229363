import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import { reduxForm, Field, FieldArray } from 'redux-form';

import TextInput from '../../../../../components/Fields/TextInput';
import SelectDropdown from "../../../../../components/Fields/SelectDropdown";
import * as validations from "../../../../../components/Validations";
import Http from "../../../../../constants/Http";
import swal from "sweetalert";

const Emails = ({ fields }) => (
  <FormGroup className={'col-10'}>
    {fields.map((email, i) => (
      <React.Fragment>
        <Label className={'mt-2'}>Email</Label>
        <div className={'row'}>
          <FormGroup className={'col-10'}>
            <Field name={`${email}.value`} component={TextInput} type={'email'} />
          </FormGroup>
          <FormGroup
            className={'col-2 d-flex justify-content-center align-items-center'}
            onClick={() => fields.remove(i)}>
            <i className={'fa fa-trash'} />
          </FormGroup>
        </div>
      </React.Fragment>
    ))}
    <Button
      className={'btn cbls-dark-grey-button mt-2'}
      style={{ width: 150, fontSize: 14 }}
      onClick={() => fields.push()}
      type={'button'}>
      Add Email
    </Button>
  </FormGroup>
);

class Profile extends React.Component {
  state = {
    titles: []
  };
  
  componentWillMount() {
    this.props.get();
    this.getTitles();
  }
  
  getTitles = () => {
    Http.get('/auth/titles')
      .then(res => {
        this.setState({
          titles: res.data
        })
      })
      .catch(err => {
        swal({
          title: "Something went wrong.",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
      })
  };
  
  render() {
    const { props } = this;
    return (
      <Form onSubmit={props.handleSubmit(props.updateProfile)}>
        <div className={'TabOverviewWrapper'}>
          <div className={'col-12 mt-4'}>
            <div className={'row row-eq-height'}>
              <div className={'col-12 col-xl-6 pl-0 mb-4'}>
                <div className={'d-flex'}>
                  <div className={'ViewingAppointments w-100 h-100'}>
                    <p className={'title'}>Account</p>
                    <FormGroup className={'col-6 mt-4'}>
                      <Label>Title</Label>
                      <Field
                        name="title"
                        component={SelectDropdown}
                        type="text"
                        valueKey={"id"}
                        labelKey={"name"}
                        options={this.state.titles}
                        validate={validations.required}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>First Name</Label>
                      <Field
                        name={'first_name'}
                        component={TextInput}
                        type={'text'}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>Last Name</Label>
                      <Field
                        name={'last_name'}
                        component={TextInput}
                        type={'text'}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>Email (Primary)</Label>
                      <Field
                        name={'primary_email'}
                        component={TextInput}
                        type={'email'}
                      />
                    </FormGroup>
                    <FieldArray name={'secondary_emails'} component={Emails} />
                    <FormGroup className={"col-10 mt-3"}>
                      <Label>Password</Label>
                      <Field
                        name={'password'}
                        component={TextInput}
                        type={'password'}
                      />
                    </FormGroup>
                    <FormGroup className={"col-10 mt-3"}>
                      <Label>Password Confirmation</Label>
                      <Field
                        name={'password_confirm'}
                        component={TextInput}
                        type={'password'}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className={'col-12 col-xl-6 pl-0 mb-4'}>
                <div className={'d-flex'}>
                  <div className={'ViewingAppointments w-100 h-100'}>
                    <p className={'title'}>Contact Details</p>
                    <FormGroup className={'col-10 mt-4'}>
                      <Label>Address Line 1</Label>
                      <Field
                        name={'address.address_line_one'}
                        component={TextInput}
                        type={'text'}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>Address Line 2</Label>
                      <Field
                        name={'address.address_line_two'}
                        component={TextInput}
                        type={'text'}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>City</Label>
                      <Field name={'address.address_city'} component={TextInput} type={'text'} />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>Phone Number</Label>
                      <Field
                        name={'contact.phone'}
                        component={TextInput}
                        type={'phone'}
                      />
                    </FormGroup>
                    <FormGroup className={'col-10 mt-3'}>
                      <Label>Mobile Number</Label>
                      <Field
                        name={'contact.mobile'}
                        component={TextInput}
                        type={'phone'}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className={'row row-eq-height mt-4'}>
              <div className={'col-12 pl-0 mb-4'}>
                <div className={'d-flex'}>
                  <div className={'w-100 h-100'} style={{
                    border: '1px solid #C1C7D0',
                    padding: '30px 35px'
                  }}>
                    <p className={'title'}>Contact Preference</p>
                    <FormGroup className={'col-10 mt-4'}>
                      <Field
                        name={'contact_preference.email'}
                        component={'input'}
                        type={'checkbox'}
                      />
                      <Label
                        style={{ marginLeft: 10, marginBottom: 0, padding: 0 }}>
                        Email
                      </Label>
                    </FormGroup>
                    <FormGroup className={'col-10 mt-4'}>
                      <Field
                        name={'contact_preference.text_message'}
                        component={'input'}
                        type={'checkbox'}
                      />
                      <Label
                        style={{ marginLeft: 10, marginBottom: 0, padding: 0 }}>
                        SMS (Text Message)
                      </Label>
                    </FormGroup>
                    <FormGroup className={'col-10 mt-4'}>
                      <Field
                        name={'contact_preference.marketing'}
                        component={'input'}
                        type={'checkbox'}
                      />
                      <Label
                        style={{ marginLeft: 10, marginBottom: 0, padding: 0 }}>
                        Marketing from Cobbletons
                      </Label>
                    </FormGroup>
                    <FormGroup className={'col-10 mt-4'}>
                      <p>
                        If ticked you agree to receive that type of communication
                      </p>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className={"row mt-4"}>
              <Button
                className={'btn cbls-dark-grey-button mt-2'}
                style={{ width: 150, fontSize: 14 }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) =>({});

export default connect(mapStateToProps)(reduxForm({
  form: 'ProfileForm',
})(Profile));
