import React from 'react';
import { push } from 'connected-react-router';
import { reduxForm, Field } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';

import { connect } from 'react-redux';
import Http from '../../../../../constants/Http';
import SelectDropdown from '../../../../../components/Fields/SelectDropdown';
import TextInput from '../../../../../components/Fields/TextInput';
import Loading from '../../../../../constants/Loading';
import {
  UPDATE_SEARCH_RESULTS,
  updateAlertData,
  saveSearchValues,
  saveSearchResults
} from '../../../../../modules/search';
import EmptySection from '../../../../../components/EmptySection';
import swal from "sweetalert";

class SavedAlerts extends React.Component {
  state = {
    list: true,
    editData: {},
    alerts: null
  };

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    Http.get('/auth/alerts/sale')
      .then(res => {
        this.setState({
          alerts: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  deleteAlert = id => {
    swal({
      title: "Warning",
      text: "Are you sure you want to delete this Alert / Saved Search?",
      icon: 'warning',
      buttons: ["No Cancel", "Yes Delete"]
    })
      .then(res => {
        if (res) {
          Http.delete('/auth/alerts/sale/' + id, {})
            .then(res => {
              swal({
                title: "Success",
                text: "Alert Deleted Successfully",
                icon: 'success'
              }).then(res => {
                window.location.reload();
              })
            })
            .catch(err => {
              swal({
                title: "Oops! Something went wrong",
                text: `${err.response.data.error.message}`,
                icon: "error",
                button: "OK",
              });
              console.error(err);
            })
        }
      })
  };

  editAlert = id => {
    this.state.alerts.forEach((alert, i) => {
      if (alert.id === id) {
        this.setState({
          editData: alert,
          list: false
        });
        this.props.updateAlertData({
          ...alert.search,
          id: alert.id
        });
      }
    });
  };

  search = values => {
    this.props.dispatch({
      type: UPDATE_SEARCH_RESULTS,
      payload: null
    });
    Http.post('/auth/search', values.values)
      .then(res => {
        this.props.saveSearchValues(values.values).then(() => {
          this.props.saveSearchResults(res.data).then(() => {
            this.props.push('/dashboard/search');
          });
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  onSubmit = values => {
    Http.put('/auth/alerts/update/' + values.id, {
      search: { ...values }
    })
      .then(res => {
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    return (
      <div className={'col-12 mt-4 pb-5'}>
        {this.state.alerts !== null ? (
          this.state.alerts.length > 0 ? (
            this.state.list ? (
              <div className={'row ViewingAppointments'}>
                <p className={'title pb-3 mb-0 w-100 border-bottom'}>Alerts</p>
                {this.state.alerts.map((alert, i) => (
                  <div
                    className={
                      'd-flex align-items-center pt-2 pb-2 border-bottom w-100'
                    }
                    key={i}>
                    <div className={'col-6'}>
                      {alert.search.name
                        ? `Alert: ${alert.id}: ${alert.search.name}`
                        : `Alert: ${alert.id}: Unamed`}
                    </div>
                    <div className={'col-6 d-flex justify-content-end'}>
                      <button
                        className={'cbls-dark-grey-button'}
                        style={{
                          height: 40,
                          width: 200,
                          marginRight: 15,
                          border: 'none'
                        }}
                        onClick={() => this.editAlert(alert.id)}>
                        Edit/View
                      </button>
                      <button className={"btn btn-danger"} style={{ height: 40, borderRadius: 0 }} onClick={() => this.deleteAlert(alert.id)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <React.Fragment>
                <p className={'title pt-4 pl-3 pb-4'}>
                  <button
                    className={'btn cbls-dark-grey-button'}
                    onClick={() => this.setState({ list: true, editData: {} })}
                    style={{ height: 30 }}>
                    <i className={'fa fa-chevron-left'} />
                  </button>
                  &nbsp;&nbsp;Alerts
                </p>
                <Form
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                  style={{
                    marginTop: 40,
                    paddingLeft: 15
                  }}>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Min Price (£)</Label>
                    <Field
                      name={'min_price'}
                      placeholder={'Min Price (£)'}
                      component={SelectDropdown}
                      options={[
                        {
                          id: 1500000,
                          label: '£1,500,000'
                        },
                        {
                          id: 1750000,
                          label: '£1,750,000'
                        },
                        {
                          id: 2000000,
                          label: '£2,000,000'
                        },
                        {
                          id: 2500000,
                          label: '£2,500,000'
                        },
                        {
                          id: 3000000,
                          label: '£3,000,000'
                        },
                        {
                          id: 3500000,
                          label: '£3,500,000'
                        },
                        {
                          id: 4000000,
                          label: '£4,000,000'
                        },
                        {
                          id: 4500000,
                          label: '£4,500,000'
                        },
                        {
                          id: 5000000,
                          label: '£5,000,000'
                        },
                        {
                          id: 7500000,
                          label: '£7,500,000'
                        },
                        {
                          id: 15000000,
                          label: '£15,000,000'
                        },
                        {
                          id: 20000000,
                          label: '£20,000,000'
                        },
                        {
                          id: 25000000,
                          label: '£25,000,000 +'
                        }
                      ]}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Max Price (£)</Label>
                    <Field
                      name={'max_price'}
                      placeholder={'Max Price (£)'}
                      component={SelectDropdown}
                      options={[
                        {
                          id: 1500000,
                          label: '£1,500,000'
                        },
                        {
                          id: 1750000,
                          label: '£1,750,000'
                        },
                        {
                          id: 2000000,
                          label: '£2,000,000'
                        },
                        {
                          id: 2500000,
                          label: '£2,500,000'
                        },
                        {
                          id: 3000000,
                          label: '£3,000,000'
                        },
                        {
                          id: 3500000,
                          label: '£3,500,000'
                        },
                        {
                          id: 4000000,
                          label: '£4,000,000'
                        },
                        {
                          id: 4500000,
                          label: '£4,500,000'
                        },
                        {
                          id: 5000000,
                          label: '£5,000,000'
                        },
                        {
                          id: 7500000,
                          label: '£7,500,000'
                        },
                        {
                          id: 15000000,
                          label: '£15,000,000'
                        },
                        {
                          id: 20000000,
                          label: '£20,000,000'
                        },
                        {
                          id: 25000000,
                          label: '£25,000,000 +'
                        }
                      ]}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6 pt-3'}>
                    <Label>Property Type</Label>
                    <Field
                      name={'property_type'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.property_types}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Property Style</Label>
                    <Field
                      name={'property_style'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.property_style}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Outside Features</Label>
                    <Field
                      name={'outside_features'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.outside}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Parking</Label>
                    <Field
                      name={'parking'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.parking}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Age</Label>
                    <Field
                      name={'age'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.age}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Situation</Label>
                    <Field
                      name={'situation'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.situation}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Property Tenure</Label>
                    <Field
                      name={'property_tenure'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.property_tenure}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Decoration</Label>
                    <Field
                      name={'decoration'}
                      component={SelectDropdown}
                      labelKey={'name'}
                      valueKey={'id'}
                      options={this.props.lookups.decoration}
                      multi
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Bedrooms</Label>
                    <Field
                      name={'bedrooms'}
                      component={SelectDropdown}
                      options={[
                        {
                          id: 1,
                          label: '1'
                        },
                        {
                          id: 2,
                          label: '2'
                        },
                        {
                          id: 3,
                          label: '3'
                        },
                        {
                          id: 4,
                          label: '4'
                        },
                        {
                          id: 5,
                          label: '5'
                        },
                        {
                          id: 6,
                          label: '6'
                        },
                        {
                          id: 7,
                          label: '7'
                        },
                        {
                          id: 8,
                          label: '8'
                        },
                        {
                          id: 9,
                          label: '9'
                        },
                        {
                          id: 10,
                          label: '10'
                        },
                        {
                          id: 11,
                          label: '11+'
                        }
                      ]}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Reception Rooms</Label>
                    <Field
                      name={'reception_rooms'}
                      component={SelectDropdown}
                      options={[
                        {
                          id: 1,
                          label: '1'
                        },
                        {
                          id: 2,
                          label: '2'
                        },
                        {
                          id: 3,
                          label: '3'
                        },
                        {
                          id: 4,
                          label: '4'
                        },
                        {
                          id: 5,
                          label: '5'
                        },
                        {
                          id: 6,
                          label: '6'
                        },
                        {
                          id: 7,
                          label: '7'
                        },
                        {
                          id: 8,
                          label: '8'
                        },
                        {
                          id: 9,
                          label: '9'
                        },
                        {
                          id: 10,
                          label: '10'
                        },
                        {
                          id: 11,
                          label: '11+'
                        }
                      ]}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Bathrooms</Label>
                    <Field
                      name={'bathrooms'}
                      component={SelectDropdown}
                      options={[
                        {
                          id: 1,
                          label: '1'
                        },
                        {
                          id: 2,
                          label: '2'
                        },
                        {
                          id: 3,
                          label: '3'
                        },
                        {
                          id: 4,
                          label: '4'
                        },
                        {
                          id: 5,
                          label: '5'
                        },
                        {
                          id: 6,
                          label: '6'
                        },
                        {
                          id: 7,
                          label: '7'
                        },
                        {
                          id: 8,
                          label: '8'
                        },
                        {
                          id: 9,
                          label: '9'
                        },
                        {
                          id: 10,
                          label: '10'
                        },
                        {
                          id: 11,
                          label: '11+'
                        }
                      ]}
                    />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Location</Label>
                    <Field name={'location'} component={TextInput} />
                  </FormGroup>
                  <FormGroup className={'col-12 col-lg-6'}>
                    <Label>Alert Name</Label>
                    <Field name={'name'} component={TextInput} />
                  </FormGroup>
                  <div className={'row'}>
                    <div className={'col-12 d-flex'}>
                      <div className={'col-6 col-md-4'}>
                        <Button className={'btn cbls-dark-grey-button'}>
                          Save
                        </Button>
                      </div>
                      <div className={'col-6 col-md-4'}>
                        <Button
                          className={'btn cbls-dark-grey-button'}
                          type={'button'}
                          onClick={() => {
                            this.search(this.props.data);
                          }}>
                          Search Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            )
          ) : (
            <div className={"row"}>
            <EmptySection
              title={"You don't have any Alerts / Saved searches"}
            />
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    lookups: state.search.lookups,
    data: state.form.AlertForm,
    alertData: state.search.alertData,
    initialValues: {
      ...state.search.alertData
    }
  }),
  {
    updateAlertData,
    saveSearchValues,
    saveSearchResults,
    push
  }
)(
  reduxForm({
    form: 'AlertForm',
    enableReinitialize: true
  })(SavedAlerts)
);
