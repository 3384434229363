import React from 'react';
import Http from '../../../../../constants/Http';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import BigCalendar from 'react-big-calendar';
import { Form } from 'reactstrap';
import moment from 'moment';
import swal from 'sweetalert';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import Availability from './Availability';

BigCalendar.momentLocalizer(moment);

class Schedule extends React.Component {
  componentWillMount() {
    this.props.get();
  }

  submitSchedule = values => {
    Http.post('/auth/my-cobbletons/schedule/create-times', values)
      .then(res => {
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    return (
      <div className={'mt-5'}>
        <BigCalendar
          events={props.events !== null ? props.events : []}
          views={['month', 'week', 'day']}
          showMultiDayTimes
          tooltipAccessor={'tooltip'}
          startAccessor={'start'}
          endAccessor={'end'}
          defaultView={'week'}
          culture={'en-GB'}
          style={{
            height: 1000
          }}
        />
        <div className={'col-12 border mt-4 pt-4 pl-4 pr-4 mb-5'}>
          <h5>My Availability</h5>
          <Form
            initialValues={props.initialValues}
            onSubmit={props.handleSubmit(values => this.submitSchedule(values))}
            className={'mb-5'}>
            <FieldArray name={'availabilities'} component={Availability} />
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ScheduleForm',
    rerenderOnEveryChange: true
  })(Schedule)
);
