import React from 'react';
import ReactGA from 'react-ga';
import withTracker from './withTracker';
import { Route, Switch } from 'react-router-dom';
import Landing from '../Landing';
import Complaints from '../Complaints';
import Register from '../Register';
import RegisterAgent from '../RegisterAgent';
import ThankYou from '../ThankYou';
import Login from '../Login';
import PrivacyPolicy from '../PrivacyPolicy';
import CookiePolicy from '../CookiePolicy';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import MembershipConditions from '../MembershipConditions';
import Dashboards from '../Dashboards';
import ERROR from '../ERROR';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from '../../store';

import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-bootstrap';
import { update_url } from "../../modules/routes";
import About from "../About";

class App extends React.Component {
  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
      this.props.dispatch(update_url(nextProps.location.pathname.split('/')[2] !== (null || undefined || "") ? nextProps.location.pathname.split('/')[2] : nextProps.location.pathname.split('/')[1]));
    }
    if (document.getElementsByClassName('bm-overlay').length !== 0) {
      if (
        nextProps.location.pathname.split('/')[1] === 'dashboard' &&
        document.getElementsByClassName('bm-overlay')[0].style.opacity === '1'
      ) {
        console.log(
          document
            .getElementsByClassName('bm-cross-button')[0]
            .getElementsByTagName('button')[0]
            .click()
        );
      }
    }
  }

  componentWillMount() {
    ReactGA.initialize('UA-66697407-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Fragment>
        <NotificationsSystem theme={theme} />
        <Switch>
          <Route exact path="/" component={withTracker(Landing)} />
          <Route exact path="/complaints" component={withTracker(Complaints)} />
          <Route exact path="/register" component={withTracker(Register)} />
          <Route exact path="/register-agent" component={withTracker(RegisterAgent)} />
          <Route exact path="/thank-you" component={withTracker(ThankYou)} />
          <Route exact path="/login" component={withTracker(Login)} />
          <Route exact path={'/trusting-cobbletons'} component={withTracker(About)} />
          <Route
            exact
            path="/forgot-password"
            component={withTracker(ForgotPassword)}
          />
          <Route
            exact
            path="/forgot-password/:hash"
            component={withTracker(ResetPassword)}
          />
          <Route
            exact
            path={"/privacy-policy"}
            component={withTracker(PrivacyPolicy)}
          />
          <Route
            exact
            path={"/cookie-policy"}
            component={withTracker(CookiePolicy)}
          />
          <Route
            exact
            path={"/membership-conditions"}
            component={withTracker(MembershipConditions)}
          />
          <Route
            path="/dashboard"
            render={Dashboards}
          />
          <Route
            path={'/*'}
            component={ERROR}
          />
        </Switch>
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
