import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './containers/app';

import './index.scss';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// render(<App />, target);

// render(
//   <Provider store={store}>
//     <ConnectedRouter history={history}>
//       <App />
//     </ConnectedRouter>
//   </Provider>,
//   target
// );
