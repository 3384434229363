import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { Button, Label, Modal, Form, FormGroup, ModalHeader } from 'reactstrap';
import TextInput from '../../../../../components/Fields/TextInput';
import Http from "../../../../../constants/Http";
import Loading from "../../../../../constants/Loading";
import EmptySection from "../../../../../components/EmptySection";
import swal from "sweetalert";


class Offers extends React.Component {
  state = {
    data: null,
    list: true,
    offerData: {},
    contactAgentModal: false
  };
  
  componentWillMount() {
    this.getData();
  }
  
  getData = () => {
    Http.get('/user/lettings/offers')
      .then(res => {
        this.setState({
          data: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  onSubmit = values => {
    this.setState({
      submitting: true
    });
    Http.post('/auth/contact/send', values)
      .then(res => {
        console.log(res.data);
        this.setState({
          submitting: false,
        });
        this.contactAgentModal(null, null);
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        this.setState({
          submitting: false,
        });
        this.contactAgentModal(null, null);
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  contactAgentModal = (name, offerID) => {
    this.props.dispatch(change('contactAgentForm', 'subject', `Regarding Offer #${offerID} on property ${name}`));
    this.setState({
      contactAgentModal: !this.state.contactAgentModal
    });
  };
  
  viewOffer = id => {
    this.state.data.forEach((offer, i) => {
      if (offer.id === id) {
        this.setState({
          list: false,
          offerData: offer
        });
      }
    });
  };
  
  acceptOffer = id => {
    Http.post('/auth/offers/lettings/accept-offer/' + id)
      .then(res => {
        console.log(res.data);
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };
  
  render() {
    const { state } = this;
    const { data } = state;
    return (
      <div className={'TabOverviewWrapper'}>
        <div className={'col-12'}>
          <div className={'row row-eq-height'}>
            <div className={'col-12 pl-0'}>
              <div className={'col-12'}>
                {data !== null ? (
                  data.length > 0 ? (
                  <div className={'ViewingAppointments w-100'}>
                    {!this.state.list ? (
                      <React.Fragment>
                        {console.log(this.state.offerData)}
                        <p className={'title pt-4 pl-3 pb-4'}>
                          <button
                            className={'btn cbls-dark-grey-button'}
                            onClick={() =>
                              this.setState({ list: true, offerData: {} })
                            }
                            style={{ height: 30 }}>
                            <i className={'fa fa-chevron-left'} />
                          </button>&nbsp;&nbsp;Offer Details
                        </p>
                        <div className={'row justify-content-center'}>
                          <div className={'col-12 col-md-8 mt-4'}>
                            <div className={'row'}>
                              <div className={'col-12'}>
                                <Label>
                                  <b>Offer Amount</b>
                                </Label>
                                <p>&pound;{this.state.offerData.offer}</p>
                                <div className={'border-bottom mb-2'} />
                              </div>
                            </div>
                            <div className={'row'}>
                              <div className={'col-12'}>
                                <Label>
                                  <b>Offer Status</b>
                                </Label>
                                <p>{this.state.offerData.status.name}</p>
                                <div className={'border-bottom mb-2'} />
                              </div>
                            </div>
                            <div className={'row'}>
                              <div className={'col-12'}>
                                <Label>
                                  <b>Date</b>
                                </Label>
                                <p>{this.state.offerData.date}</p>
                                <div className={'border-bottom mb-2'} />
                              </div>
                            </div>
                            <div className={'row mt-3'}>
                              <div className={'col-12 col-sm-6'}>
                                <Button
                                  className={'btn cbls-dark-grey-button'}
                                  type={'button'}
                                  style={{ width: 200 }}
                                  disabled={this.state.offerData.status.name === 'Accepted'}
                                  onClick={() => this.acceptOffer(this.state.offerData.id)}>
                                  {this.state.offerData.status.name === 'Accepted' ? 'Accepted' : 'Accept' }
                                </Button>
                              </div>
                              <div className={'col-12 col-sm-6 mt-2 mt-sm-0'}>
                                <Button
                                  className={'btn cbls-dark-grey-button'}
                                  type={'button'}
                                  style={{ width: 200 }}
                                  onClick={() => this.contactAgentModal()}>
                                  Contact Cobbletons
                                </Button>
                                <Modal
                                  isOpen={this.state.contactAgentModal}
                                  toggle={() => this.contactAgentModal()}
                                  style={{ width: '50%', maxWidth: '100%' }}>
                                  <ModalHeader toggle={() => this.contactAgentModal()} />
                                  <Form
                                    onSubmit={this.props.handleSubmit(
                                      this.onSubmit
                                    )}>
                                    <div className={'row p-5'}>
                                      <h4
                                        style={{
                                          width: '100%',
                                          textAlign: 'center',
                                          fontWeight: 300
                                        }}>
                                        Contact Cobbletons
                                      </h4>
                                      <FormGroup className={'col-12 mt-3'}>
                                        <Label>Subject</Label>
                                        <Field
                                          name={'subject'}
                                          defaultValue={`RE: ${
                                            this.state.offerData.property
                                            }`}
                                          component={TextInput}
                                          type={'text'}
                                        />
                                      </FormGroup>
                                      <FormGroup className={'col-12'}>
                                        <Label>Description</Label>
                                        <Field
                                          name={'description'}
                                          component={'textarea'}
                                          style={{
                                            width: '100%',
                                            minHeight: 300
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup className={'col-12'}>
                                        <Button
                                          className={
                                            'btn cbls-dark-grey-button btn-block'
                                          }>
                                          {this.state.submitting ?
                                            <img
                                              src={'/images/ui/Rolling.svg'}
                                              alt={'loading deals'}
                                              style={{ width: 15, height: 15 }}
                                            />
                                            :
                                            "Submit"
                                          }
                                        </Button>
                                      </FormGroup>
                                    </div>
                                  </Form>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <p className={'title'} style={{ fontWeight: 'bold' }}>
                          Offers
                        </p>
                        <div className={'border-top mt-4'} />
                        <div
                          className={
                            'border-bottom pt-2 pb-2 d-flex align-items-center'
                          }>
                          <div className={'col'}>
                            <b>Property</b>
                          </div>
                          <div className={'col'}>
                            <b>Offer Amount</b>
                          </div>
                          <div className={'col'}>
                            <b>Status</b>
                          </div>
                          <div className={'col'}>
                            <b>Date</b>
                          </div>
                          <div className={'col'}>&nbsp;</div>
                        </div>
                        {data.map((offer, i) => (
                          <div
                            className={
                              'd-flex align-items-center pt-2 pb-2 border-bottom'
                            }
                            key={i}>
                            <div className={'col'}>{offer.property}</div>
                            <div className={'col'}>{offer.offer}</div>
                            <div className={'col'}>{offer.status.name}</div>
                            <div className={'col'}>{offer.date}</div>
                            <div className={'col'}>
                              <Button
                                className={
                                  'btn cbls-dark-grey-button btn-block'
                                }
                                type={'button'}
                                onClick={() => this.viewOffer(offer.id)}>
                                View
                              </Button>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                  ) : (
                    <div className={"row"}>
                    <EmptySection title={"You haven't got any offers yet"} />
                    </div>
                  )
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'contactAgentForm'
})(Offers);
