import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

class LandlordServices extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Landlord Services</h2>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 30 }}>
              <h4>Cobbletons is a leading residential lettings agents. We are trusted by the most experienced and successful landlords due to our professional and personal approach, along with our focus on matching quality properties with quality tenants.</h4>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 60 }}>
              <p>If you have a house or flat to rent in North West London, our agents are here to help you throughout the lettings process, from our initial appraisal of the property and valuation, through to proactive marketing, securing a quality tenant and on-going property management. We can also provide sensible unbiased advice to help you understand how to improve the yield from your property and feel confident as a landlord.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We have built a first-class reputation by combining our local knowledge with a global reach, through an extensive database of corporate, domestic and international tenants. We recognise that having a property to rent in London is a significant asset and we are focused on maximising the return on your investment, while also ensuring your property is in safe hands throughout the tenancy period.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We have an unrivalled approach to marketing, including a dedicated Marketing, Advertising and Communications Manager and committed PR agency, who will generate high levels of exposure for your property. You will be provided with quality, professional photography and floor plans, a strong web presence on our own website alongside all the major property portals, as well as far-reaching PR to ensure that the right tenants are actively engaged.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>If you have a flat or house for rent in London, get in touch and we will assign you with an agent dedicated to you and your property. At Cobbletons, all viewings are accompanied, and you will receive regular feedback throughout the entire process. Once a suitable tenant has been identified, we will negotiate the best rent possible before handling the checks, contracts and other administration.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We pride ourselves on taking the pain out of renting property and ensure the whole process runs smoothly and effortlessly. Our client’s interests sit at the heart of everything we do, and you can be sure you will be fully supported at all times.</p>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    )
  }
}

export default LandlordServices;