import React from 'react';
import { formValueSelector } from 'redux-form';
import { Modal, ModalHeader } from 'reactstrap';
import ViewingForm from '../PropertyComponent/ViewingForm';

import Http from '../../constants/Http';
import swal from "sweetalert";
import moment from "moment";


class BookAViewingModal extends React.Component {
  state = {
    isOpen: false,
    saving: false,
    step: 0
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  onSubmit = values => {
    this.setState({
      saving: true
    });
    const newValues = {
      ...values,
      property_id: this.props.propertyID
    };
    Http.post(
      `/auth/viewings/${this.props.sale ? 'sales' : 'lettings'}/book-viewing`,
      newValues
    )
      .then(res => {
        this.setState({
          saving: false
        });
        this.toggle();
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <button
          className={
            'btn btn-block new-property-button mt-3'
          }
          type={'button'}
          disabled={props.owner}
          onClick={() => this.toggle()}>
          Book a viewing
        </button>
        <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()} className={"viewing-modal"}>
          <ModalHeader toggle={() => this.toggle()} />
          <ViewingForm saving={this.state.saving} dates={props.dates} onSubmit={(values) => this.onSubmit(values)} {...props} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default BookAViewingModal;
