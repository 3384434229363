export const SUBMIT_FORM = 'register/SUBMIT_FORM';

const initialState = {
  formSubmitted: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SUBMIT_FORM:
      return {
        formSubmitted: true,
        ...state,
      };
    default:
      return {
        ...state,
      }
  }
}