import { combineReducers } from 'redux';
import { reducer as form } from "redux-form";
import { reducer as notifications } from "reapop";
import { connectRouter } from 'connected-react-router'
import register from './register';
import login from './login';
import search from './search';
import routes from './routes';

export default (history) => combineReducers({
  form,
  router: connectRouter(history),
  register,
  login,
  search,
  routes,
  notifications: notifications()
});
