import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import logo from '../../images/CBLS_SPLASH_LOGO.svg';

import './index.scss';

const scrollToTop = () => {
  let i = 0;
  var int = window.setInterval(() => {
    i += 1;
    if(window.scrollY > 0) {
      window.scrollTo(0, window.scrollY - i)
    } else {
      window.clearInterval(int);
    }
  }, 10);
}

const LogoBanner = props => (
  <div>
    <div className="NavLogo">
    {props.bottom && <a onClick={() => scrollToTop() }><img src={logo} alt="" /></a> }
    </div>
  </div>
)

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogoBanner);