import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Label, FormGroup, Modal, Button, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';

import Http from '../../constants/Http';
import TextInput from '../Fields/TextInput';

class ContactAgentModal extends React.Component {
  state = {
    isOpen: false,
    submitting: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  
  onSubmit = values => {
    this.setState({
      submitting: true,
    });
    Http.post('/auth/contact/send', values)
      .then(res => {
        this.toggle();
        this.setState({
          submitting: false
        });
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        this.toggle();
        this.setState({
          submitting: false
        });
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    return (
      <React.Fragment>
        <button
          className={'btn cbls-dark-grey-button'}
          style={{ width: 250, height: 50 }}
          type={'button'}
          onClick={() => this.toggle()}>
          Contact Cobbletons
        </button>
        <Modal
          isOpen={this.state.isOpen}
          toggle={() => this.toggle()}
          style={{ maxWidth: '100%' }}>
          <ModalHeader toggle={() => this.toggle()} />
          <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div className={'row p-5'}>
              <h4
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontWeight: 300
                }}>
                Contact Cobbletons
              </h4>
              <FormGroup className={'col-12 mt-3'}>
                <Label>Subject</Label>
                <Field name={'subject'} component={TextInput} type={'text'} />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Description</Label>
                <Field
                  name={'description'}
                  component={'textarea'}
                  style={{
                    width: '100%',
                    minHeight: 300
                  }}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Button className={'btn cbls-dark-grey-button btn-block'}>
                  {this.state.submitting ?
                    <img
                      src={'/images/ui/Rolling.svg'}
                      alt={'loading deals'}
                      style={{ width: 15, height: 15 }}
                    />
                    :
                    "Submit"
                  }
                </Button>
              </FormGroup>
            </div>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default reduxForm({ form: 'ContactAgentForm' })(ContactAgentModal);
