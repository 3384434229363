import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';

import Http from '../../constants/Http';
import LogoBanner from "../../components/LogoBanner";
import TextInput from "../../components/Fields/TextInput";
import * as Validations from '../../components/Validations';
import swal from "sweetalert";

class ForgotPassword extends React.Component {
  
  onSubmit = values => {
    Http.post('/auth/forgot-password', values)
      .then(res => {
        swal({
          title: "Success",
          text: `Please check your email and follow the link.
          Check your spam folder if you are unable to find it`,
          icon: "success",
          button: "OK",
        })
          .then(() => {
            this.props.dispatch(push('/'));
          });
      })
      .catch(err => {
        swal({
          title: "Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.log(err);
      });
  };
  
  render() {
    const { props } = this;
    const { handleSubmit } = props;
    return (
      <div>
        <LogoBanner />
        <div className={'cbls-container login-container'}>
          <div className={'container pl-2 pr-2 pb-5'}>
            <div className={'row justify-content-center'}>
              <div className={'col-12 text-center text-white mt-5 d-none d-md-inline'}>
                <h1>Forgotten Password</h1>
              </div>
              <div className={'col-12 col-md-8 bg-white text-center mt-3 pl-5 pr-5 mt-md-5 pt-md-5'}>
                <div className={'pt-3 pl-sm-5 pr-sm-5 pb-5'}>
                  <p>
                    Please enter your email to request a password reset.
                  </p>
                  <Form
                    onSubmit={handleSubmit(this.onSubmit)}
                    className="text-left pt-5">
                    <FormGroup className={"col-12 col-md-8 mx-auto"}>
                      <Label>Email</Label>
                      <Field
                        name={'email'}
                        component={TextInput}
                        validate={[Validations.required, Validations.email]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className="row justify-content-center">
                        <div className="mt-5 mb-2 pt-4 col-sm-6 col-md-6 text-center">
                          <Button className="btn btn-block cbls-dark-grey-button">
                            Send Reset Email
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(reduxForm({
  form: 'ForgotPasswordForm'
})(ForgotPassword));
