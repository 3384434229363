import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import swal from 'sweetalert';

import Http from '../../../../../constants/Http';
import Loading from '../../../../../constants/Loading';
import ValuationModal from '../../../../../components/Modals/ValuationModal';
import EmptySection from "../../../../../components/EmptySection";

class Overview extends React.Component {
  state = {
    data: null
  };

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    Http.get('/user/lettings/my-lettings-opportunities')
      .then(res => {
        this.setState({
          data: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    const { props, state } = this;
    const { data } = state;
    return (
      <div className={'TabOverviewWrapper'}>
        <div className={'col-12 mt-4'}>
          <div className={'row row-eq-height'}>
            <div
              className={`col-12 ${
                data !== null && data.activities.length > 0 ? 'col-xl-8' : ''
              } pl-xl-0`}>
              <div className={''}>
                {data !== null ? (
                  data.properties.data.length > 0 ? (
                    <React.Fragment>
                    <div className={'ViewingAppointments w-100 h-100'}>
                      <p className={'title'} style={{ fontWeight: 'bold' }}>
                        My Letting Properties
                      </p>
                      <div className={'border-top mt-4'} />
                      <div
                        className={
                          'border-bottom pt-2 pb-2 d-flex align-items-center'
                        }>
                        <div className={'col'}>
                          <b>Property</b>
                        </div>
                        <div className={'col'}>
                          <b>Price</b>
                        </div>
                        <div className={'col'}>
                          <b>Status</b>
                        </div>
                        <div className={'col'}>
                          <b>Viewings</b>
                        </div>
                        <div className={'col'}>
                          <b>Views</b>
                        </div>
                        <div className={'col'}>
                          <b>Offers</b>
                        </div>
                      </div>
                      {data.properties.data.map((data, i) => (
                        <div
                          className={
                            'd-flex align-items-center pt-2 pb-2 border-bottom'
                          }
                          key={i}>
                          <div className={'col'}>{data.property}</div>
                          <div className={'col'}>£{data.price}</div>
                          <div className={'col'}>{data.status.name}</div>
                          <div className={'col'}>{data.viewings}</div>
                          <div className={'col'}>{data.search_counter}</div>
                          <div className={'col'}>{data.offers}</div>
                        </div>
                      ))}
                    </div>
                      <EmptySection title={"Got more properties? Let us know about them."}>
                        <div style={{ marginTop: -50, marginBottom: 50, width: '100%' }} className={"col-12 col-md-6"}>
                          <ValuationModal />
                        </div>
                      </EmptySection>
                    </React.Fragment>
                  ) : (
                    <div
                      className={
                        'border d-flex justify-content-center align-items-center row w-100 h-100'
                      }
                      style={{ marginLeft: 1 }}>
                      <div
                        className={'col-10 d-flex justify-content-center'}
                        style={{ marginTop: 70, marginBottom: 15 }}>
                        <img
                          src={'/images/logo_grey.svg'}
                          alt={'grey cbls logo'}
                          style={{ height: 40 }}
                        />
                      </div>
                      <div className={'col-10 d-flex justify-content-center'}>
                        <h3
                          style={{
                            fontFamily: 'proxima-nova, sans-serif',
                            fontSize: 20,
                            color: '#383B41',
                            letterSpacing: '0px',
                            textAlign: 'center',
                            lineHeight: '25px',
                            fontWeight: 'bold'
                          }}>
                          You haven't got any properties yet
                        </h3>
                      </div>
                      <div
                        className={'col-6 d-flex justify-content-center mt-3'}
                        style={{ marginBottom: 70 }}>
                        <ValuationModal />
                      </div>
                    </div>
                  )
                ) : (
                  <Loading />
                )}
              </div>
            </div>
            {data !== null &&
              (data.activities.length > 0 ? (
                <div
                  className={
                    'col-xl-4 pl-0 pl-xl-3 col-12 pr-xl-0 mt-xl-0 mt-4'
                  }>
                  <div className={'d-flex'}>
                    <div
                      className={'LatestActivities w-100'}
                      style={{ overflow: 'scroll' }}>
                      <p className={'title'} style={{ fontWeight: 'bold' }}>
                        Latest Activities
                      </p>
                      <div className={'border-top mt-4'} />
                      {data.activities.map((activity, i) => (
                        <div
                          key={i}
                          className={
                            'd-flex align-items-center pt-2 pb-2 border-bottom'
                          }>
                          <div className={'col'}>
                            {activity.read ? (
                              activity.title
                            ) : (
                              <b>{activity.title}</b>
                            )}
                          </div>
                          <div className={'col-xl-4 col-3'}>
                            <button
                              className={'cbls-dark-grey-button btn-block'}
                              style={{ height: 30 }}
                              onClick={() =>
                                props.dispatch(push(activity.link))
                              }>
                              View
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null)}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Overview);
