import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { Parallax } from 'react-parallax';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import Http from '../../../../constants/Http';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import LandingNav from '../../../../components/LandingNav';
import PropertyComponent from '../../../../components/PropertyComponent';

import './index.scss';
import Loading from '../../../../constants/Loading';
import ValuationModal from '../../../../components/Modals/ValuationModal';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";
import InfiniteScrollPagination from "../../../../components/InfiniteScrollPagination";

let timer = null;
let circleTimer = null;

const items = [
  {
    src: '/images/new/carousel/london-bg-day.jpg',
  },
  {
    src: '/images/new/carousel/house1.jpg',
  },
  {
    src: '/images/new/carousel/house2.jpg',
  }
];

class DashHome extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  
  
  state = {
    toggle: 'vendor',
    tab: true,
    data: null,
    activeIndex: 0,
    loading: true,
    loadingMore: true,
    endOfStream: false,
    feedErr: null,
    nextPage: null,
    prevPage: null,
    PageContent: null,
  };

  componentWillMount() {
    this.getPageContent();
    this.getData();
  }
  
  getPageContent = () => {
    Http.post('/get-page-content', { url: window.location.pathname })
      .then(res => {
        this.setState({
          PageContent: res.data.data
        })
      })
      .catch(err => {
        console.log(err);
      })
  };
  
  setSaved = id => {
    let data = this.state.data;
    let property = data.properties[this.state.tab ? 'sales' : 'rentals'][id];

    property[this.state.tab ? 'is_saved_sale' : 'is_saved_rent'] = !property[
      this.state.tab ? 'is_saved_sale' : 'is_saved_rent'
    ];

    data.properties[this.state.tab ? 'sales' : 'rentals'][id] = property;

    this.setState({
      data
    });
  };

  getData = () => {
    Http.get('/auth/homepage')
      .then(res => {
        this.setState({
          data: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  changeTab = val => {
    this.setState({
      tab: val
    });
  };

  offset = el => {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };
  
  scrollCircle = () => {
    try {
      if (window.pageYOffset >= 250) {
        this.setState({
          circleResetPoint: window.pageYOffset,
        });
        var doc = document.documentElement, body = document.body;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        var _p = document.querySelector('.moveme');
        var height = Math.max(body.scrollHeight, body.offsetHeight, doc.clientHeight, doc.scrollHeight, doc.offsetHeight) - (350);
        var current = top - (350);
        var pct = current / height;
        var pathlength = _p.getTotalLength();
        var dashoffset = -(pathlength * pct);
        _p.setAttribute('stroke-dashoffset', dashoffset * 1.5);
      }
      if (window.pageYOffset < this.state.circleResetPoint) {
    
      }
    } catch (e) {
      console.log(e);
    }
  };

  scroll = () => {
    const hero = document.getElementById('scrollToHere');
    const heroBounds = this.offset(hero);
    window.scrollTo({
      top: heroBounds.top,
      left: 0,
      behavior: 'smooth'
    });
  };

  buildSVG = () => {
    try {
      const svgDiv = document.getElementsByClassName('GuideLineSVG')[0];
      let line1x1 =
        document
          .getElementsByClassName('ToggleButton')[0]
          .getBoundingClientRect().left -
        document.body.getBoundingClientRect().left +
        document.getElementsByClassName('ToggleButton')[0].offsetWidth;
      if (
        document
          .getElementsByClassName('hover-section')[0]
          .classList.contains('pinned') &&
        window.innerWidth >= 992
      ) {
        line1x1 -= 265;
      } else if (
        document
          .getElementsByClassName('hover-section')[0]
          .classList.contains('pinned')
      ) {
        line1x1 -= 265;
      } else if (window.innerWidth >= 992) {
        line1x1 -= 70;
      }
      const line1y1 =
        document
          .getElementsByClassName('ToggleButton')[0]
          .getBoundingClientRect().top -
        document.body.getBoundingClientRect().top +
        82;
      const circleLast =
        document.getElementsByClassName('circleLast')[0].getBoundingClientRect()
          .top - document.body.getBoundingClientRect().top;
      const circleLastLeft = document.getElementsByClassName('circleLast')[0]
        .offsetLeft;
      
      const svg = `<svg id="FixScrollSVG" style="position: absolute; top: 0; z-index: 1049; pointer-events: none;  width: ${
        document
          .getElementsByClassName('hover-section')[0]
          .classList.contains('pinned')
          ? 'calc(100% - 265px)'
          : window.innerWidth <= 991
            ? '100%'
            : 'calc(100% - 70px)'
      }; height: ${document.documentElement.scrollHeight}px" fill="#044B94" fill-opacity="0">

        <path d="M ${line1x1} ${line1y1 + 20}
        L ${line1x1} ${document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top + document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight}
        L ${circleLastLeft + 13} ${document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top + document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight}
        L ${circleLastLeft + 13} ${circleLast - 3}
        " stroke-dasharray="10, 5" stroke-width="5" stroke-linejoin="round" stroke="#EBA94F" />
        
        <path d="M ${line1x1} ${line1y1}
        L ${line1x1} ${document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top + document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight}
        L ${circleLastLeft + 13} ${document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top + document.getElementsByClassName('ToggleButton')[0].parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.offsetHeight}
        L ${circleLastLeft + 13} ${circleLast - 3}
        " stroke-dasharray="1 6000" stroke-width="25" stroke-linejoin="round" stroke-linecap="round" class="moveme" stroke="#EBA94F" />
        
      </svg>`;

      svgDiv.innerHTML = svg;
    } catch (e) {
      console.log(e);
    }
  };

  changeToggle = () => {
    if (this.state.toggle === 'vendor') {
      this.setState({
        toggle: 'buyer'
      });
    } else {
      this.setState({
        toggle: 'vendor'
      });
    }
  };
  
  onExiting() {
    this.animating = true;
  }
  
  onExited() {
    this.animating = false;
  }
  
  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }
  
  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    const { activeIndex } = this.state;
    if (window.localStorage.getItem('token') !== null) {
      return (
        <div id={'DashHomeWrapper'} className={'DashHomeWrapper'}>
          <div className={'GuideLineSVG'} />
          <div className={"force-scroll"}>
          <div
            className={'homePageHero'} style={{ height: '100vh' }}>
            {this.state.PageContent !== null &&
              <Carousel
                activeIndex={activeIndex}
                next={this.next}
                slide={false}
                previous={this.previous}
              >
                {this.state.PageContent.content.Banner_Images_Type_FileUpload.map((item) => {
                  return (
                    <CarouselItem
                      className="custom-tag"
                      tag="div"
                      key={item.url}
                      onExiting={this.onExiting}
                      onExited={this.onExited}
                    >
                      <img src={item.url} />
                    </CarouselItem>
                  );
                })}
              </Carousel>
            }
            <div
              className={'container heroContainer'}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: '100%'
              }}
              >
              <div className={'row w-100'}>
                <div className="col-12 text-center new-hero">
                  <img src={"/images/new/Landing/logo-new.svg"} className={"logo-new"} />
                  <h1><img className={'img-fluid'} src={'/images/new/Landing/Cobbletons.svg'} /></h1>
                  <h3>FIND YOUR OPPORTUNITY</h3>
                </div>
                <div className={'col-12'}>
                  <SearchBox />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: -60, cursor: 'pointer' }}
            className={'w-100 d-flex justify-content-center'}
            onClick={() => this.scroll()}>
            <img src={'/images/more.svg'} alt={""} />
          </div>
          <div
            className={'col-12'}
            id={'scrollToHere'}
            style={{ marginTop: 23 }}>
          </div>
            <div className={'container latest-properties mt-5'}>
              <div className={'row'}>
                {this.state.data !== null ? (
                  <div className={'col-12'}>
                    <p className={"title"}>COBBLETONS LATEST OPPORTUNITIES</p>
                    {/*<div className={'mx-auto w-100 w-md-50 w-xl-25 mb-4 text-button-toggle'}>*/}
                    {/*<button*/}
                    {/*type={'button'}*/}
                    {/*onClick={() => this.changeTab(true)}*/}
                    {/*style={{*/}
                    {/*height: 25,*/}
                    {/*minHeight: 15,*/}
                    {/*fontSize: 14,*/}
                    {/*borderRadius: 0,*/}
                    {/*border: 'none',*/}
                    {/*borderRight: '',*/}
                    {/*textAlign: 'right',*/}
                    {/*paddingRight: 15,*/}
                    {/*backgroundColor: 'transparent',*/}
                    {/*color: this.state.tab ? 'black' : 'darkgrey'*/}
                    {/*}}*/}
                    {/*className={`w-50`}>*/}
                    {/*FOR SALE*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*type={'button'}*/}
                    {/*onClick={() => this.changeTab(false)}*/}
                    {/*style={{*/}
                    {/*height: 25,*/}
                    {/*minHeight: 15,*/}
                    {/*fontSize: 14,*/}
                    {/*borderRadius: 0,*/}
                    {/*border: 'none',*/}
                    {/*textAlign: 'left',*/}
                    {/*paddingLeft: 15,*/}
                    {/*backgroundColor: 'transparent',*/}
                    {/*color: this.state.tab ? 'darkgrey' : 'black'*/}
                    {/*}}*/}
                    {/*className={`w-50`}>*/}
                    {/*TO LET*/}
                    {/*</button>*/}
                    {/*</div>*/}
                    {this.state.tab
                      ? this.state.data.properties.sales.map((p, i) => (
                        <PropertyComponent
                          afterUnsave={() => this.setSaved(i)}
                          data={p}
                          index={i}
                          sale={true}
                        />
                      ))
                      : this.state.data.properties.rental.map((p, i) => (
                        <PropertyComponent
                          afterUnsave={() => this.setSaved(i)}
                          data={p}
                          index={i}
                          sale={false}
                        />
                      ))}
                  </div>
                ) : (
                  <div className={'col-12'}>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
            
            {this.state.PageContent !== null &&
              <Parallax
                bgImage={this.state.PageContent.content.Parallax_Image_Type_FileUpload[0].url}
                strength={500}
                className={"banner-image mb-0"}
              >
                &nbsp;
              </Parallax>
            }
            <div className={"path-container"}>
              <div className="path">
                <div className="content">
                  <div className="container">
                    <div className="row d-flex justify-content-center">
                      <div className="col-12 col-xl-8">
                        {this.state.PageContent !== null &&
                        <div className={"home-intro text-gradient"} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Gold_Type_Wysiwyg }}/>
                        }
                        {this.state.PageContent !== null &&
                        <p className={"sub-intro"} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_White_Type_Wysiwyg }} />
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className={"col-12 col-xl-4"}>
                        <div className="dark-card">
                          {this.state.PageContent !== null &&
                          <h4 className="title" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_One_Title_Type_Wysiwyg }}/>
                          }
                          {this.state.PageContent !== null &&
                          <p className="info" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_One_Text_Type_Wysiwyg }}/>
                          }
                          <button onClick={() => window.location.href = '/dashboard/how-to-use'}>READ MORE</button>
                        </div>
                      </div>
                      <div className={"col-12 col-xl-4"}>
                        <div className="dark-card valuation">
                          {this.state.PageContent !== null &&
                          <h4 className="title" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_Two_Title_Type_Wysiwyg }}/>
                          }
                          {this.state.PageContent !== null &&
                          <p className="info" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_Two_Text_Type_Wysiwyg }}/>
                          }
                          <ValuationModal />
                        </div>
                      </div>
                      <div className={"col-12 col-xl-4"}>
                        <div className="dark-card contact">
                          {this.state.PageContent !== null &&
                          <h4 className="title" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_Three_Title_Type_Wysiwyg }}/>
                          }
                          {this.state.PageContent !== null &&
                          <p className="info" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Box_Three_Text_Type_Wysiwyg }} />
                          }
                          <ContactAgentModal />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DashFooter />
        </div>
      );
    } else {
      return <div>{this.props.dispatch(push('/login'))}</div>;
    }
  }
}

const mapStateToProps = state => ({
  user: state.login.user
});

export default connect(mapStateToProps)(DashHome);
