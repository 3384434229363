import React from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import SearchBox from '../../../../components/SearchBox';
import Loading from '../../../../constants/Loading';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Form, Label, ModalHeader } from 'reactstrap';
import SelectDropdown from '../../../../components/Fields/SelectDropdown';
import {
  saveSearchResults,
  saveSearchValues,
  setSaved,
  nextPage,
} from '../../../../modules/search';
import Http from '../../../../constants/Http';
import { push } from 'connected-react-router';
import TextInput from '../../../../components/Fields/TextInput';
import PropertyComponent from '../../../../components/PropertyComponent';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import EmptySection from '../../../../components/EmptySection';
import InfiniteScrollPagination from "../../../../components/InfiniteScrollPagination";

import './index.scss';

const required = value => (value ? undefined : 'Required');

class Search extends React.Component {
  constructor(props) {
    super(props);
  
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    
    this.state = {
      modal: false,
      modalCBLS: false,
      development: null,
      activeIndex: 0,
      items: null,
    };
  }
  
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(state.properties) !== JSON.stringify(props.properties)) {
      if (props.properties !== undefined) {
        return {
          properties: props.properties
        }
      } else return null;
    } else return null;
  }
  
  componentDidMount() {
    if (window.innerWidth < 768) {
      window.scrollTo(0, 525);
    }
    if (window.location.search !== '?type=development') window.location.href = window.location.href + '?type=development';
    this.getDevelopments();
  }
  
  onExiting() {
    this.animating = true;
  }
  
  onExited() {
    this.animating = false;
  }
  
  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }
  
  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }
  
  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  
  getDevelopments = () => {
    Http.get(`/auth/development/${this.props.match.params.slug}`)
      .then(res => {
        this.setState({
          development: res.data
        });
        this.makeItems(res.data.images);
      })
      .catch(err => {
      
      });
    Http.get(`/auth/development/${this.props.match.params.slug}/children`)
      .then(res => {
        this.props.saveSearchResults(res.data);
      })
      .catch(err => {
      
      })
  };
  
  makeItems = (images) => {
    const items = images.map(img => {
      return {
        src: img.url,
      }
    });
    
    this.setState({
      items
    });
  };
  
  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  
  toggleModalCBLS = () => {
    this.setState({
      modalCBLS: !this.state.modalCBLS
    });
  };
  
  loadMore = () => {
    if (this.props.nextPageURL !== null) {
      this.setState({
        loading: true
      });
      this.props.nextPage()
        .then(res => {
          this.setState({
            loading: false
          })
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({
        endOfStream: true
      })
    }
  };
  
  render() {
    const { activeIndex } = this.state;
    return (
      <div className={'MyCBLSWrapper development-properties'}>
        <div className={'london-bg-day h-400'}>
          <div className={'container pl-2 pr-2 pb-5 pt-5'}>
            <div className={'d-flex mt-5 justify-content-center'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container mb-4'}>
          
          {this.state.development !== undefined && this.state.development !== null &&
          this.state.development.length !== 0 && (
            <div className={'row mb-3'}>
              <div className={'col-12 mb-4'}>
                <h1
                  style={{
                    height: 44,
                    color: '#1A1A1A',
                    fontFamily: 'proxima-nova, sans-serif',
                    fontSize: 36,
                    fontWeight: 'bold',
                    lineHeight: '44px',
                    marginBottom: 20,
                    marginTop: 75
                  }}>
                  {this.state.development.property_name}
                </h1>
              </div>
              <div className="col-12 mb-4" style={{ marginBottom: 70 }}>
                <p dangerouslySetInnerHTML={{ __html: this.state.development.brief_description }}/>
              </div>
              {this.props.total !== 0 &&
              <div className="col-12">
                <h3
                  style={{
                    height: 22,
                    color: '#1A1A1A',
                    fontFamily: 'proxima-nova, sans-serif',
                    fontSize: 18,
                    fontWeight: 'bold',
                    lineHeight: '22px',
                  }}>
                  View {this.props.total} Propert
                  {this.props.total === 1 ? 'y' : 'ies'} Within {this.state.development.property_name}
                </h3>
              </div>
              }
            </div>
          )}
          {this.state.properties !== undefined && this.state.properties !== null ? (
            this.state.properties.length !== 0 ? (
              <div className={'row mb-5'}>
                <InfiniteScrollPagination
                  loadMore={() => this.loadMore()}
                  loading={this.state.loading}
                  loadingMoreComponent={<span className={"col-12"}>Loading more propertites</span>}
                  endOfStream={this.state.endOfStream}
                  endOfStreamComponent={<span className={"col-12"}>We could not find any more properties matching your search.</span>}
                >
                  {this.state.properties.map((p, i) => (
                    <div className={'col-12'}>
                      <PropertyComponent
                        data={p}
                        index={i}
                        sale={
                          this.props.type.option !== 2
                        }
                        afterUnsave={() =>
                          this.props.setSaved(i, this.props.type.option)
                        }
                      />
                    </div>
                  ))}
                </InfiniteScrollPagination>
              </div>
            ) : null
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  properties: state.search.results,
  type: 3,
  nextPageURL: state.search.nextPageURL,
  total: state.search.total,
});

export default connect(
  mapStateToProps,
  {
    saveSearchValues,
    saveSearchResults,
    push,
    setSaved,
    nextPage
  }
)(
  Search
);
