import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Fade } from 'react-reveal';

import Http from '../../constants/Http';
import LogoBanner from '../../components/LogoBanner';
import RegisterForm from '../../components/RegisterForm';

import './index.scss';
import swal from "sweetalert";
import { Link } from 'react-router-dom';


class Register extends Component {
  state = {
    submitting: false,
    titles: [],
  };
  
  componentWillMount() {
    window.scrollTo(0,0);
    if (window.localStorage.getItem('token') !== null && window.localStorage.getItem('token') !== undefined) {
      this.props.dispatch(push('/dashboard'));
    }
  }
  componentDidMount() {
    window.setTimeout(() => {
      const moveGrey = document.getElementsByClassName("LoadingSplash");
      moveGrey[0].classList.add('slide');
    }, 666.666);
    this.getLookups();
  }
  
  getLookups = () => {
    Http.get('/auth/titles')
      .then(res => {
        this.setState({
          titles: res.data
        })
      })
      .catch(err => {
        swal({
          title: "Something went wrong.",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
      })
  };
  
  handleSubmit = (values, dispatch) => {
    this.setState({
      submitting: true
    });
    Http.post('/auth/user-register', values)
      .then(res => {
        this.setState({
          submitting: false
        });
        dispatch(push('/thank-you'));
      })
      .catch(err => {
        this.setState({
          submitting: false
        });
        swal({
          title: "Something went wrong.",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
      });
  };
  
  render() {
    return (
      <Fade duration={2000} delay={200}>
      <div>
        <div className="LoadingSplash" style={{top:-50, height: "100vh"}}/>
          <LogoBanner />
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <div className={"d-flex justify-content-center "}>
                  <Link to={'/'}><img width={"50"} height={"50"} src={"/images/new/Landing/logo-new.svg"} /></Link>
                </div>
                <h1 className={"register-h1"}>Find your opportunity</h1>
                <p>Register to be considered for membership</p>
              </div>
            </div>
            <div className="row registerForm">
              <div className="col-10 offset-1 col-md-6 offset-md-3">
              <div className={"col-12"}>
                <hr />
                <ol>
                  <li>Fill out details below</li>
                  <li>Cobbletons will be in contact with you shortly</li>
                  <li>Begin searching for your exclusive opportunities</li>
                </ol>
                <hr />
              </div>
              </div>
            </div>
            <RegisterForm isSubmitting={this.state.submitting} titles={this.state.titles} onSubmit={(values) => this.handleSubmit(values, this.props.dispatch)} />
          </div>
      </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(Register);
