import Http from '../constants/Http';
import update from 'immutability-helper';

export const UPDATE_SEARCH_VALUES = 'search/UPDATE_SEARCH_VALUES';
export const UPDATE_SEARCH_RESULTS = 'search/UPDATE_SEARCH_RESULTS';
export const UPDATE_ALERT_DATA = 'search/UPDATE_ALERT_DATA';
export const UPDATE_LOOKUPS = 'search/UPDATE_LOOKUPS';
export const NEXT_PAGE = 'search/NEXT_PAGE';

const initialState = {
  values: null,
  results: null,
  alertData: null,
  lookups: {
    property_types: [],
    property_style: [],
    outside: [],
    parking: [],
    age: [],
    situation: [],
    property_tenure: [],
    decoration: []
  }
};

export default (state = initialState, action) => {
  switch(action.type) {
    case NEXT_PAGE: {
      const newProperties = update(state.results, { $push: action.payload })
      return {
        ...state,
        results: newProperties,
        nextPageURL: action.nextPageURL,
        prevPageURL: action.prevPageURL,
        total: action.total
      }
    }
    case UPDATE_LOOKUPS: {
      return {
        ...state,
        lookups: action.payload
      }
    }
    case UPDATE_ALERT_DATA: {
      return {
        ...state,
        alertData: action.payload
      }
    }
    case UPDATE_SEARCH_RESULTS: {
      return {
        ...state,
        results: action.payload,
        nextPageURL: action.nextPageURL,
        prevPageURL: action.prevPageURL,
        total: action.total
      }
    }
    case UPDATE_SEARCH_VALUES: {
      return {
        ...state,
        values: action.payload
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export const setSaved = (id, option) => (dispatch, getState) => {
  let data = getState().search.results;
  let property = data[id];
  
  property[option === 1 || option === 3 ? 'is_saved_sale' : 'is_saved_rent'] = !property[option === 1 || option === 3 ? 'is_saved_sale' : 'is_saved_rent'];
  
  data[id] = property;
  
  dispatch({
    type: UPDATE_SEARCH_RESULTS,
    payload: data
  });
};

export const updateLookups = values => dispatch => {
  dispatch({
    type: UPDATE_LOOKUPS,
    payload: {...values}
  });
};

export const updateAlertData = values => dispatch => {
  dispatch({
    type: UPDATE_ALERT_DATA,
    payload: values
  });
};

export const saveSearchValues = values => dispatch => {
  dispatch({
    type: UPDATE_SEARCH_VALUES,
    payload: values
  });
  return Promise.resolve();
};

export const saveSearchResults = data => dispatch => {
  dispatch({
    type: UPDATE_SEARCH_RESULTS,
    payload: data.data,
    nextPageURL: data.next_page_url,
    prevPageURL: data.prev_page_url,
    total: data.total
  });
  return Promise.resolve();
};

export const nextPage = () => (dispatch, getState) => Http.post(getState().search.nextPageURL, { ...getState().search.values })
  .then(res => {
    dispatch({
      type: NEXT_PAGE,
      payload: res.data.data,
      nextPageURL: res.data.next_page_url,
      prevPageURL: res.data.prev_page_url,
      total: res.data.total
    })
  })
  .catch(err => {
    console.log(err)
  });
