import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import './index.scss';
import { update_url } from "../../modules/routes";

const checkUrl = (match, location) => {
  // return match.url !== location.pathname;
};

class SideBarNav extends React.Component {
  state = {
    pinned: false,
    hovered: false,
    url: window.location.pathname.split('/')[2]
  };
  
  togglePin = () => {
    const toggle = document.getElementsByClassName('pin-section')[0];
    const menu = document.getElementsByClassName('hover-section')[0];
    const dashContent = document.getElementsByClassName('dash-content')[0];
    if (!this.state.pinned) {
      this.setState({
        pinned: true
      });
      
      toggle.classList.add('pinned');
      menu.classList.add('pinned');
      toggle.classList.add('pinning');
      dashContent.classList.add('pinned');
      window.setTimeout(() => {
        try {
          const menuLinks = document.getElementsByClassName('menu-links')[0];
          menuLinks.classList.remove('d-none');
        } catch (error) {}
      }, 150);

      window.setTimeout(() => {
        toggle.classList.remove('pinning');
      }, 100);
    } else {
      this.setState({
        pinned: false,
      });
      toggle.classList.remove('pinned');
      menu.classList.remove('pinned');
      toggle.classList.add('pinning');
      dashContent.classList.remove('pinned');
      window.setTimeout(() => {
      try {
        const menuLinks = document.getElementsByClassName('menu-links')[0];
        menuLinks.classList.add('d-none');
      } catch (error) {}
      }, 150);
      
      window.setTimeout(() => {
        toggle.classList.remove('pinning');
      }, 100);
    }
  };
  
  updateUrl = val => {
    this.props.dispatch(update_url(val));
  };

  render() {
    const { props } = this;
    const { match } = props;
    return (
      <div
        className="SideBarNav d-none d-lg-block ">
        <div
          className="pin-section d-none d-lg-block"
          onClick={() => this.togglePin()}>
          <div
            className={`pin-box-cover ${
              this.state.pinned ? 'pinned' : ''
            }`}>
            &nbsp;
          </div>
          <div
            className={`w-100 h-100 ${
              this.state.pinned ? '' : 'text-center'
            }`}>
            {this.state.pinned ? (
              <React.Fragment>
                <i
                  className="fa fa-chevron-left float-right"
                  style={{
                    color: 'white',
                    transform: 'scale(1.5)',
                    marginTop: 14,
                    paddingRight: 15,
                    zIndex: 1
                  }}
                />
              </React.Fragment>
            ) : (
              <i
                className="fa fa-chevron-right mx-auto"
                style={{
                  color: 'white',
                  transform: 'scale(1.5)',
                  marginTop: 14
                }}
              />
            )}
          </div>
        </div>
        <div className="hover-section">
          {this.state.pinned ? (
            <React.Fragment>
              <div className={`menu-item-logo`} style={{ marginLeft: 2 }}>
                <Link to={`${match}`} onClick={() => this.updateUrl('null')}>
                  <img
                    className={'img-fluid'}
                    src={'/images/dashboard/SideBarLogo.svg'}
                    alt={'cobbletons dashboard home'}
                  />
                </Link>
              </div>
              <div className={'menu-links d-none'}>
                <NavLink activeClassName="activeRoute" to={`${match}/my-cobbletons`} onClick={() => this.updateUrl('my-cobbletons')}>
                  <div
                    className={`menu-item ${
                      this.props.url === 'my-cobbletons'
                        ? 'active'
                        : ''
                    }`}>
                    <span>My Cobbletons</span>
                    <i className={'fa fa-chevron-right float-right pt-1'} />
                  </div>
                </NavLink>
                <NavLink activeClassName="activeRoute" to={`${match}/sales-search`} onClick={() => this.updateUrl('sales-search')}>
                  <div
                    className={`menu-item ${
                      this.props.url === 'sales-search'
                        ? 'active'
                        : ''
                    }`}>
                    <span>My Buying Search</span>
                    <i className={'fa fa-chevron-right float-right pt-1'} />
                  </div>
                </NavLink>
                <NavLink activeClassName="activeRoute" to={`${match}/lettings-search`} onClick={() => this.updateUrl('lettings-search')}>
                  <div
                    className={`menu-item ${
                      this.props.url === 'lettings-search'
                        ? 'active'
                        : ''
                    }`}>
                    <span>My Rental Search</span>
                    <i className={'fa fa-chevron-right float-right pt-1'} />
                  </div>
                </NavLink>
                <div className={'menu-item-spacer'}>&nbsp;</div>
                <NavLink activeClassName="activeRoute" to={`${match}/sale-opportunities`} onClick={() => this.updateUrl('sale-opportunities')}>
                  <div
                    className={`menu-item ${
                      this.props.url === 'sale-opportunities'
                        ? 'active'
                        : ''
                    }`}>
                    <span>My Sales Properties</span>
                    <i className={'fa fa-chevron-right float-right pt-1'} />
                  </div>
                </NavLink>
                <NavLink activeClassName="activeRoute" to={`${match}/letting-opportunities`} onClick={() => this.updateUrl('letting-opportunities')}>
                  <div
                    className={`menu-item ${
                      this.props.url === 'letting-opportunities'
                        ? 'active'
                        : ''
                    }`}>
                    <span>My Lettings Properties</span>
                    <i className={'fa fa-chevron-right float-right pt-1'} />
                  </div>
                </NavLink>
                <div className={'menu-item-spacer'}>&nbsp;</div>
                <div className={''}>
                  <a
                    onClick={() => {
                      window.localStorage.removeItem('token');
                      window.location.href = '/';
                    }}>
                    <div className={'menu-item'}>
                      <span>Logout</span>
                      <i className={'fas fa-sign-out-alt float-right pt-1'} />
                    </div>
                  </a>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Link to={`${match}`}>
                <img
                  style={{ marginTop: 30, marginLeft: 12, marginRight: 12 }}
                  src={'/images/dashboard/SideBarLogoVertical.svg'}
                  alt={'cobbletons dashboard home'}
                />
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  url: state.routes.url
});

export default connect(mapStateToProps)(SideBarNav);
