import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import Loading from '../../../../../constants/Loading';

import PropertyComponent from '../../../../../components/PropertyComponent';
import Feedback from '../../../../../components/Feedback';

class Overview extends React.Component {
  state = {
    activeFeedback: {
      viewingID: null,
      propertyID: null
    },
    tab: true
  };

  componentWillMount() {
    this.props.get();
  }

  toggleFeedback = (viewingID, propertyID) => {
    this.setState({
      activeFeedback: {
        viewingID,
        propertyID
      }
    });
  };

  changeTab = val => {
    this.setState({
      tab: val
    });
  };

  render() {
    const { props } = this;
    if (props.data !== null) {
      return (
        <div className={'TabOverviewWrapper'}>
          <div className={'col-12 mt-4'}>
            {props.data.viewings.data.map((viewing, i) => (
              <React.Fragment key={i}>
                {this.state.activeFeedback.viewingID === viewing.id &&
                  this.state.activeFeedback.propertyID ===
                    viewing.property_id && (
                    <Feedback
                      imgs={viewing.property.images}
                      propertyForSale={viewing.property.property_for_sale}
                      propertyForLet={viewing.property.property_for_rent}
                      address={viewing.property.address}
                      initialValues={viewing.feedback}
                      toggle={() => this.toggleFeedback(null, null)}
                    />
                  )}
              </React.Fragment>
            ))}
            {this.state.activeFeedback.viewingID === null &&
              this.state.activeFeedback.propertyID === null && (
                <div className={'row row-eq-height'}>
                  {props.data.sellerLandlord && (
                    <div
                      className={`col-12 pl-0  ${
                        props.activities !== null && props.activities.length > 0
                          ? 'col-xl-8'
                          : ''
                      } pr-0 pr-xl-3`}
                      style={{ height: 350 }}>
                      <div className={'d-flex'}>
                        {props.data.viewings.data.length > 0 ? (
                          <div
                            className={'ViewingAppointments w-100'}
                            style={{ height: 350 }}>
                            <p
                              className={'title'}
                              style={{ fontWeight: 'bold' }}>
                              Latest Viewing Appointments (For Sellers and Landlords)
                            </p>
                            <div className={'border-top mt-4 w-767'} />
                            <div
                              className={
                                'border-bottom pt-2 pb-2 d-flex align-items-center w-767'
                              }>
                              <div className={'col'}>
                                <b style={{ fontSize: 16 }}>Property</b>
                              </div>
                              <div className={'col'}>
                                <b style={{ fontSize: 16 }}>Status</b>
                              </div>
                              <div className={'col'}>
                                <b style={{ fontSize: 16 }}>Date</b>
                              </div>
                              <div className={'col'}><b style={{ fontSize: 16 }}>Feedback</b></div>
                            </div>
                            {props.data.viewings.data.map((viewing, i) => (
                              i >= 3 ? null :
                              <div
                                className={
                                  'd-flex align-items-center pt-2 pb-2 border-bottom w-767'
                                }
                                key={i}>
                                <div className={'col'} style={{ fontSize: 16 }}>
                                  {viewing.property.property_name}
                                </div>
                                <div className={'col'} style={{ fontSize: 16 }}>
                                  {viewing.status.name}
                                </div>
                                <div className={'col'} style={{ fontSize: 16 }}>
                                  {moment(viewing.time_from).format('LLL')}
                                </div>
                                <div className={'col'}>
                                  {viewing.feedback ? (
                                    <button
                                      className={
                                        'cbls-dark-grey-button btn-block'
                                      }
                                      style={{
                                        height: 30,
                                        cursor: 'pointer'
                                      }}
                                      type={'button'}
                                      onClick={() =>
                                        this.toggleFeedback(
                                          viewing.id,
                                          viewing.property_id
                                        )
                                      }>
                                      See Feedback
                                    </button>
                                  ) : "No Feedback Yet"}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div
                            className={
                              'border d-flex justify-content-center align-items-center row w-100 h-100'
                            }
                            style={{ marginLeft: 1 }}>
                            <div
                              className={'col-10 d-flex justify-content-center'}
                              style={{ marginTop: 70, marginBottom: 15 }}>
                              <img
                                src={'/images/logo_grey.svg'}
                                alt={'grey cbls logo'}
                                style={{ height: 40 }}
                              />
                            </div>
                            <div
                              className={'col-10 d-flex justify-content-center'}
                              style={{ marginBottom: 70 }}>
                              <h3
                                className={'font-weight-bold'}
                                style={{
                                  fontFamily: 'proxima-nova, sans-serif',
                                  fontSize: 20,
                                  color: '#383B41',
                                  letterSpacing: '0px',
                                  textAlign: 'center',
                                  lineHeight: '25px'
                                }}>
                                You haven't got any viewings yet
                              </h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {props.activities !== null ? (
                    props.activities.length > 0 ? (
                      <div
                        className={`col pl-0 pr-0 mt-xl-0 mt-4`}
                        style={{ height: 350 }}>
                        <div className={'d-flex'}>
                          <div
                            className={'LatestActivities w-100'}
                            style={{ height: 350 }}>
                            <p
                              className={'title'}
                              style={{ fontWeight: 'bold' }}>
                              Latest Activities
                            </p>
                            <div className={'border-top mt-4 w-767'} />
                            {props.activities.map((activity, i) => {
                              if (i + 1 >= 5) {
                                return null;
                              } else {
                                return (
                                  <div
                                    key={i}
                                    className={
                                      'd-flex align-items-center pt-2 pb-2 border-bottom w-767'
                                    }>
                                    <div className={'col'}>
                                      {activity.read_at !== null ? (
                                        activity.data.message
                                      ) : (
                                        <b>NEW: {activity.data.message}</b>
                                      )}
                                    </div>
                                    {activity.data.message !==
                                    'Your account has been accepted' && activity.data.message !== 'Welcome email sent' ? (
                                      <div className={'col-xl-4 col-3'}>
                                        <button
                                          className={
                                            'cbls-dark-grey-button btn-block'
                                          }
                                          style={{ height: 30 }}
                                          onClick={() => {
                                            props.dispatch(
                                              push(
                                                activity.data.message ===
                                                'You have requested a viewing'
                                                  ? '/dashboard/sales-search?tab=2'
                                                  : activity.data.message ===
                                                    'You have made an offer on a property'
                                                    ? '/dashboard/sales-search?tab=1'
                                                    : '/dashboard'
                                              )
                                            );
                                            props.read(activity.id, i);
                                          }}>
                                          View
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
              )}
          </div>
          <p className={''} style={{ marginTop: 150 }}>Cobbletons latest 14 day opportunities</p>
          {this.state.tab
            ? props.data.properties.sales.map((p, i) => (
                <PropertyComponent
                  afterUnsave={() => props.setSaved(i, this.state.tab)}
                  data={p}
                  index={i}
                  sale={true}
                />
              ))
            : props.data.properties.rental.map((p, i) => (
                <PropertyComponent
                  afterUnsave={() => props.setSaved(i, this.state.tab)}
                  data={p}
                  index={i}
                  sale={false}
                />
              ))}
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect()(Overview);
