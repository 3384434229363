import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import { push } from 'connected-react-router';
import swal from 'sweetalert';


import Http from '../../constants/Http';
import LogoBanner from "../../components/LogoBanner";
import TextInput from "../../components/Fields/TextInput";
import * as Validations from '../../components/Validations';

class ForgotPassword extends React.Component {
  
  onSubmit = values => {
    Http.post('/auth/reset-password', values)
      .then(res => {
        swal({
          title: "Password Reset",
          text: "Your Password Has Been Reset.",
          icon: "success",
          button: "Login",
        })
          .then(() => {
            this.props.dispatch(push('/login'))
          });
      })
      .catch(err => {
        swal({
          title: "Password Reset Failed",
          text: `Password Reset Failed. The Reason Was: ${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.log(err);
      });
  };
  
  render() {
    const { props } = this;
    const { handleSubmit } = props;
    return (
      <div>
        <LogoBanner />
        <div className={'cbls-container login-container'}>
          <div className={'container pl-2 pr-2 pb-5'}>
            <div className={'row justify-content-center'}>
              <div className={'col-12 text-center text-white mt-5 d-none d-md-inline'}>
                <h1>Reset Password</h1>
              </div>
              <div className={'col-12 col-md-8 bg-white text-center mt-3 pl-5 pr-5 mt-md-5 pt-md-5'}>
                <div className={'pt-3 pl-sm-5 pr-sm-5 pb-5'}>
                  <p>
                    Please enter your new password.
                  </p>
                  <Form
                    onSubmit={handleSubmit(this.onSubmit)}
                    className="text-left pt-5">
                    <FormGroup className={"col-12 col-md-8 mx-auto"}>
                      <Label>Password</Label>
                      <Field
                        name={'password'}
                        component={TextInput}
                        type={'password'}
                        validate={[Validations.required]}
                      />
                    </FormGroup>
                    <FormGroup className={"col-12 col-md-8 mx-auto"}>
                      <Label>Confirm Password</Label>
                      <Field
                        name={'password_confirmation'}
                        component={TextInput}
                        type={"password"}
                        validate={[Validations.required]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className="row justify-content-center">
                        <div className="mt-5 mb-2 pt-4 col-sm-6 col-md-6 text-center">
                          <Button className="btn btn-block cbls-dark-grey-button">
                            Reset Password
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    token: ownProps.match.params.hash
  }
});

export default connect(mapStateToProps)(reduxForm({
  form: 'ForgotPasswordForm'
})(ForgotPassword));
