import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { FormFeedback } from "reactstrap";

import RichTextEditor from "react-rte";

import "react-quill/dist/quill.snow.css";
import "./index.scss";

class Wysiwyg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createValueFromString(
        this.props.input.value,
        "html"
      )
    };
    this.onChange = this.onChange.bind(this);
  }
  
  onChange = value => {
    this.setState({ value });
    this.props.input.onChange(value.toString("html"));
  };
  
  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" }
      ]
    };
    return (
      <div>
        <RichTextEditor
          value={this.state.value}
          onChange={this.onChange}
          toolbarConfig={this.props.toolbarConfig ? this.props.toolbarConfig : toolbarConfig}
          className={this.props.class}
        />
        {this.props.meta.touched &&
        this.props.meta.error && (
          <FormFeedback style={{ display: "block" }}>
            {this.props.meta.error}
          </FormFeedback>
        )}
      </div>
    );
  }
}

export default Wysiwyg;