import React from 'react';
import swal from 'sweetalert';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Http from '../../../../../constants/Http';
import PropertyComponent from '../../../../../components/PropertyComponent';

import SavedAlerts from './SavedAlerts';
import Viewings from './Viewings';
import Loading from '../../../../../constants/Loading';
import { withStyles } from '@material-ui/core/styles';
import EmptySection from '../../../../../components/EmptySection';
import Offers from "./Offers";

const TabContainer = ({ children, dir }) => <div dir={dir}>{children}</div>;

const styles = () => ({
  tabsIndicator: {
    backgroundColor: '#1a1a1a',
    zIndex: 4
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontFamily: '"proxima-nova", sans-serif',
  }
});

class MyLettingsSearchTabs extends React.Component {
  state = {
    index: 0,
    savedProperties: null,
    offers: null
  };

  componentWillMount() {
    this.getSaved();
    this.checkRef();
  }

  checkRef = () => {
    let url = new Map(
      window.location.search
        .slice(1)
        .split('&')
        .map(kv => kv.split('='))
    );
    this.setState({
      index: parseInt(url.get('tab') !== undefined ? url.get('tab') : 0, 10)
    });
  };

  setSaved = id => {
    let data = this.state.data;
    let property = data[id];

    property['is_saved_rent'] = !property['is_saved_rent'];

    data[id] = property;

    this.setState({
      data
    });
  };

  getSaved = () => {
    Http.get('/user/lettings/my-lettings-search')
      .then(res => {
        this.setState({
          savedProperties: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  handleChange = (event, value) => {
    this.setState({ index: value });
  };

  handleChangeIndex = value => {
    this.setState({ index: value });
  };

  render() {
    const { savedProperties } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar position="static" color="inherit" style={{ boxShadow: 'none' }}>
          <div className={'row'}>
            <div className={'col'}>
              <Tabs
                classes={{ indicator: classes.tabsIndicator }}
                value={this.state.index}
                onChange={this.handleChange}
                fullWidth>
                <Tab
                  label="Saved Properties"
                  classes={{ root: classes.tabRoot }}
                />
                <Tab label="Offers Made" classes={{ root: classes.tabRoot }} />
                <Tab label="Viewings Made" classes={{ root: classes.tabRoot }} />
                <Tab
                  label="Alerts/Saved Search"
                  classes={{ root: classes.tabRoot }}
                />
              </Tabs>
              <div className={'default-indicator'} />
            </div>
          </div>
        </AppBar>
        <SwipeableViews
          axis={'x'}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}>
          <TabContainer dir={'ltr'}>
            <div className={'col-12 mt-4'}>
              {savedProperties !== null ? (
                savedProperties.length > 0 ? (
                  <React.Fragment>
                    <h3 className={'w-100 ml-3 mb-4 font-weight-bold'}>
                      {savedProperties.length} Saved Proper
                      {savedProperties.length === 1 ? 'ty' : 'ties'}:
                    </h3>
                    {savedProperties.map((p, i) => (
                      <PropertyComponent
                        afterUnsave={() => this.setSaved(i)}
                        data={p}
                        index={i}
                        sale={false}
                      />
                    ))}
                  </React.Fragment>
                ) : (
                  <div className={"row"}>
                  <EmptySection
                    title={"You haven't saved any properties yet"}
                  />
                  </div>
                )
              ) : (
                <Loading />
              )}
            </div>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <div className={'col-12 mt-4'}>
              {this.state.index === 1 && <Offers/>}
            </div>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.index === 2 && <Viewings />}
          </TabContainer>
          <TabContainer dir={'ltr'}>
            {this.state.index === 3 && <SavedAlerts />}
          </TabContainer>
        </SwipeableViews>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MyLettingsSearchTabs);
