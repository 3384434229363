import React, { Component } from 'react';
import { reduxForm, Field, reset } from 'redux-form';
import { Modal, ModalHeader, Form, Label } from 'reactstrap';

import Http from '../../../constants/Http';
import * as Validations from '../../../constants/Validation';
import TextInput from '../../../components/Fields/TextInput';
import SelectDropdown from '../../../components/Fields/SelectDropdown';
import swal from 'sweetalert';

const form = 'propertyToListForm';

class PropertyToList extends Component {
  state = {
    tab: 0,
    loading: false
  };

  onSubmit = values => {
    this.setState({
      loading: true
    });
    Http.post('/request-valuation', { ...values, valuation_type: { label: "Sale" } })
      .then(res => {
        swal({
          title: 'Success',
          text: `Thank you we will be in contact to discuss listing your opportunity`,
          icon: 'success',
          button: 'OK'
        });
        this.props.dispatch(reset(form));
        this.props.toggle();
        this.setState({
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        swal({
          text: 'Oops! Something went wrong',
          title: 'Error',
          icon: 'error',
          button: 'OK'
        });
      });
  };

  next = () => {
    this.setState({
      tab: this.state.tab + 1
    });
  };

  prev = () => {
    this.setState({
      tab: this.state.tab - 1
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className={'TEST'}>
        <ModalHeader toggle={() => this.props.toggle()} />
        <Form
          onSubmit={this.props.handleSubmit(values => this.onSubmit(values))}>
          <div className="container">
            <div className="row">
              <div className="col-10 mx-auto">
                <div className={'row justify-content-center d-flex'}>
                  <div className={'col-12'}>
                    <h3
                      className={'text-center'}
                      style={{
                        marginTop: 35,
                        height: 24,
                        color: '#1A1A1A',
                        fontFamily: 'proxima-nova, sans-serif',
                        fontSize: 20,
                        fontWeight: 'bold',
                        letterSpacing: '0.33px'
                      }}>
                      I have a property to list
                    </h3>
                  </div>
                </div>
                <div className="row registerForm">
                  <div className="col-10 offset-1 col-md-10 offset-md-1">
                    <div className={"col-12"}>
                      <hr />
                      <ol>
                        <li>Enter your details below to begin listing your property with Cobbletons</li>
                        <li>A member of our team will be in contact to discuss the next steps, once we have everything your property will be listed for 14 days with Cobbletons!</li>
                        <li>Begin finding an opportunity from our community!</li>
                      </ol>
                      <hr />
                    </div>
                  </div>
                </div>
                {this.state.tab === 0 && (
                  <React.Fragment>
                    <div className={'row justify-content-center d-flex mb-2 mt-4'}>
                      <div className="col-12 col-lg-6">
                        <Label>First Name</Label>
                        <Field
                          name={'first_name'}
                          component={TextInput}
                          type={'text'}
                          validate={Validations.required}
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                        <Label>Last Name</Label>
                        <Field
                          name={'last_name'}
                          component={TextInput}
                          type={'text'}
                          validate={Validations.required}
                        />
                      </div>
                    </div>
                    <div className={'row justify-content-center d-flex mb-2'}>
                      <div className="col-12 col-lg-6">
                        <Label>Email</Label>
                        <Field
                          name={'email'}
                          component={TextInput}
                          type={'email'}
                          validate={[Validations.required, Validations.email]}
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                        <Label>Phone Number</Label>
                        <Field
                          name={'phone'}
                          component={TextInput}
                          type={'text'}
                          validate={[
                            Validations.required,
                            Validations.phoneNumber
                          ]}
                        />
                      </div>
                    </div>
                    <div className={'row justify-content-center d-flex mb-2'}>
                      <div className="col-12">
                        <Label>Postcode</Label>
                        <Field
                          name={'postcode'}
                          component={TextInput}
                          type={'text'}
                          validate={Validations.required}
                        />
                      </div>
                      {/*<div className="col-12 col-lg-6">*/}
                      {/*<Label>Valuation Type</Label>*/}
                      {/*<Field*/}
                      {/*name={'valuation_type'}*/}
                      {/*component={SelectDropdown}*/}
                      {/*valueKey={"id"}*/}
                      {/*labelKey={'label'}*/}
                      {/*options={[*/}
                      {/*{*/}
                      {/*id: 1,*/}
                      {/*label: 'Sell'*/}
                      {/*},*/}
                      {/*{*/}
                      {/*id: 2,*/}
                      {/*label: 'Rent'*/}
                      {/*},*/}
                      {/*{*/}
                      {/*id: 3,*/}
                      {/*label: 'Both'*/}
                      {/*}*/}
                      {/*]}*/}
                      {/*validate={Validations.required}*/}
                      {/*/>*/}
                      {/*</div>*/}
                    </div>
                    {/*<div className={'row'} style={{ marginBottom: 50, marginTop: 50 }}>*/}
                    {/*<div className={'col justify-content-center d-flex'}>*/}
                    {/*<button*/}
                    {/*type={'button'}*/}
                    {/*className={'cbls-dark-grey-button w-25'}*/}
                    {/*onClick={() => this.next()}>*/}
                    {/*Next*/}
                    {/*</button>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    <div
                      className={'row justify-content-center d-flex'}
                      style={{ marginBottom: 50, marginTop: 50 }}>
                      <div
                        className={
                          'col-12 col-lg-6 justify-content-center d-flex'
                        }>
                        <button className={'cbls-dark-grey-button w-50'}>
                          {this.state.loading ? <img src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: 25, height: 25 }} /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                {/*{this.state.tab === 1 && (*/}
                {/*<React.Fragment>*/}
                {/*<div className={'row justify-content-center d-flex mb-2'}>*/}
                {/*<div className="col-12">*/}
                {/*<Label>Additional Message</Label>*/}
                {/*<Field*/}
                {/*name={"additional_message"}*/}
                {/*component={'textarea'}*/}
                {/*style={{*/}
                {/*width: '100%',*/}
                {/*minHeight: 250*/}
                {/*}}*/}
                {/*validate={Validations.required}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className={'row'} style={{ marginBottom: 50, marginTop: 50 }}>*/}
                {/*<div className={'col justify-content-center d-flex'}>*/}
                {/*<button*/}
                {/*type={'button'}*/}
                {/*className={'cbls-dark-grey-button w-50'}*/}
                {/*onClick={() => this.prev()}>*/}
                {/*Back*/}
                {/*</button>*/}
                {/*</div>*/}
                {/*<div className={'col justify-content-center d-flex'}>*/}
                {/*<button*/}
                {/*className={'cbls-dark-grey-button w-50'}*/}
                {/*>*/}
                {/*Submit*/}
                {/*</button>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</React.Fragment>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default reduxForm({ form })(PropertyToList);
