import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import { updateLookups } from '../../modules/search';
import SearchForm from '../SearchForm';
import Http from '../../constants/Http';

import './index.scss';

class SearchBox extends React.Component {
  state = {
    tab: '0'
  };

  componentWillMount() {
    this.getLookups();
    this.getType();
  }

  getType = () => {
    let url = new Map(
      window.location.search
        .slice(1)
        .split('&')
        .map(kv => kv.split('='))
    );
    this.setState({
      tab:
        url.get('type') === 'development'
          ? '2'
          : url.get('type') === 'rent'
            ? '1'
            : '0'
    });
  };

  getLookups = () => {
    Http.get('/auth/alerts/lookups')
      .then(res => {
        this.props.updateLookups(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  changeTab = tab => {
    window.location.href = '/dashboard/search' + `?type=${tab === '0' ? 'sale' : tab === '1' ? 'rent' : 'development'}`
    // this.setState({
    //   tab
    // });
  };

  render() {
    const { props } = this;
    const { tab } = this.state;
    return (
      <div className="SearchBoxWrapper mt-2 pt-4 pb-4 pl-5 pr-5">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${tab === '0' && 'active'}`}
              onClick={() => this.changeTab('0')}>
              For Sale
            </NavLink>
          </NavItem>
          {/*<NavItem>*/}
          {/*  <NavLink*/}
          {/*    className={`${tab === '1' && 'active'}`}*/}
          {/*    onClick={() => this.changeTab('1')}>*/}
          {/*    To Rent*/}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
          <NavItem>
            <NavLink
              className={`${tab === '2' && 'active'}`}
              onClick={() => this.changeTab('2')}>
              Development
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="0">
            {tab === '0' && (
              <SearchForm
                type="sales"
                enableReinitialize={true}
                initialValues={{
                  ...props.values,
                  option: 1,
                  order: { id: 1, label: 'Price (Low - High)' }
                }}
              />
            )}
          </TabPane>
          <TabPane tabId="1">
            {tab === '1' && (
              <SearchForm
                type="rent"
                enableReinitialize={true}
                initialValues={{
                  ...props.values,
                  option: 2,
                  order: { id: 1, label: 'Price (Low - High)' }
                }}
              />
            )}
          </TabPane>
          <TabPane tabId="2">
            {tab === '2' && (
              <SearchForm
                type="development"
                enableReinitialize={true}
                initialValues={{
                  ...props.values,
                  option: 3,
                  order: { id: 1, label: 'Price (Low - High)' }
                }}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  values: state.search.values
});

export default connect(
  mapStateToProps,
  { updateLookups, push }
)(SearchBox);
