import React from 'react';
import Http from '../../constants/Http';
import { reduxForm, Field } from 'redux-form';
import { Form, FormGroup, Label, Button } from 'reactstrap';

import StarRating from '../Fields/StarRating';

class Feedback extends React.Component {
  state = {
    loading: false,
  };
  
  
  onSubmit = (values, propertyID, viewingID) => {
    this.setState({
      loading: true
    });
    const newValues = {
      ...values,
      property_id: propertyID,
      viewing_id: viewingID,
    };
    if(this.props.lettings) {
      Http.post('/user/lettings/my-lettings-search/feedback', newValues)
        .then(res => {
          this.props.toggle(null, null);
          this.setState({
            loading: false
          });
        })
        .catch(err => {
          console.error(err);
        })
    } else {
      Http.post('/user/sales/my-sales-search/feedback', newValues)
        .then(res => {
          this.props.toggle(null, null);
          this.setState({
            loading: false
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  render() {
    const { props } = this;
    const { address, propertyForLet, propertyForSale, imgs, create, handleSubmit, propertyID, viewingID } = props;
    return (
      <React.Fragment>
        <p className={'title pt-4 pl-3 pb-4'}>
          <button
            className={'btn cbls-dark-grey-button'}
            type={"button"}
            onClick={() => this.props.toggle(null, null)}
            style={{ height: 30 }}>
            <i className={'fa fa-chevron-left'} />
          </button>&nbsp;&nbsp;Viewing Feedback
        </p>
        <Form
          onSubmit={handleSubmit((values) => this.onSubmit(values, propertyID, viewingID))}
          style={{
            marginTop: 40,
            paddingLeft: 15
          }}>
          <div className={'row'}>
            <div className={'col-12 col-lg-6'}>
              <FormGroup className={'col-12'}>
                <Label>Location</Label>
                <Field
                  name={'location'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Condition</Label>
                <Field
                  name={'condition'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Size/Layout</Label>
                <Field
                  name={'size_layout'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Outdoors</Label>
                <Field
                  name={'outdoors'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Comparative</Label>
                <Field
                  name={'comparative'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Offer</Label>
                <Field
                  name={'offer'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>Value</Label>
                <Field
                  name={'value'}
                  component={StarRating}
                  count={5}
                  disabled={!create}
                />
              </FormGroup>
              <FormGroup className={'col-12'}>
                <Label>General Comments</Label>
                <br />
                <Field
                  name={'comments'}
                  component={'textarea'}
                  style={{
                    width: '100%',
                    minHeight: 250
                  }}
                  disabled={!create}
                />
              </FormGroup>
            </div>
            <div className={'d-none d-lg-inline col-lg-6'}>
              <div className={'col-12'}>
                <img className={"img-fluid"} src={`${imgs[0] ? imgs[0].url : 'https://picsum.photos/450/350'}`} alt={""} />
              </div>
              <div className={'col-12 mt-4'}>
                <Label>Address</Label>
                <p>{address.house_number} {address.address_line_one} {address.address_line_two} {address.address_city}</p>
              </div>
              <div className={'col-12'}>
                {propertyForSale && (
                  <React.Fragment>
                    <Label>Sale Price</Label>
                    <p>£{propertyForSale.estimated_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                  </React.Fragment>
                )}
                {propertyForLet && (
                  <React.Fragment>
                    <Label>Letting Price</Label>
                    <p>£{propertyForLet.rent_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {propertyForLet.rent_type.name}</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {create && (
            <div className={'row'}>
              <div className={'col-12'}>
                <div className={'col-12'}>
                  <Button
                    className={'btn cbls-dark-grey-button mt-3'}
                    style={{ width: 200 }}>
                    {this.state.loading ? <img className={"mt-5 mb-5"} src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: 50, height: 50 }} /> : 'Save Feedback' }
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>
      </React.Fragment>
    );
  }
}

export default reduxForm({ form: 'viewingFeedbackForm' })(Feedback);
