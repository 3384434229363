import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './index.scss'

class BuyingSelling extends Component {
  render() {
    const { props } = this;
    const { isOpen, toggle } = props;
    return (
      <Modal isOpen={isOpen} toggle={() => toggle()} modalClassName={"buying-selling-modal"}>
        <ModalHeader toggle={toggle} />
        <div className="container">
          <div className="row d-flex justify-content-end">
            <div className="col-12 col-xl-7">
              <h1 className="gold-title">BUYING AND SELLING</h1>
              <h1 className="black-title">COBBLETONS BENEFITS</h1>
              <h3 className="small-gold-title">Unlimited and exclusive access to buying opportunities for a 14 day period.</h3>
              <p>Cobbletons members have unlimited access to buying opportunities for 14 days that are exclusive to Cobbletons and nowhere else for that period of time. </p>
              <h3 className="small-gold-title">Confidential community to sell your property in 14 days</h3>
              <p>Cobbletons Members have a gateway to list and offer their own opportunities to a large number of pre-vetted members who are looking for opportunities. </p>
              <h3 className="small-gold-title">A totally confidential & discreet community to buy and sell opportunities</h3>
              <p>Cobbletons members are part of a fully confidential community of high net worth individuals who can buy and sell opportunities in a truly discreet way. </p>
              <button type={"button"} onClick={() => window.location.href = '/register'}>JOIN THE COMMUNITY NOW</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default BuyingSelling;