import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";

class Agents extends Component {
  render() {
    const { props } = this;
    const { isOpen, toggle } = props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} modalClassName={"buying-selling-modal"}>
        <ModalHeader toggle={toggle} />
        <div className="container">
          <div className="row d-flex justify-content-end">
            <div className="col-12 col-xl-7">
              <h1 className="gold-title">AGENTS AND PARTNERS</h1>
              <h1 className="black-title">COBBLETONS BENEFITS</h1>
              <h3 className="small-gold-title">Be in front of a power network of clientele.</h3>
              <p>Partners can enjoy access to the Cobbletons community of pre-vetted high net worth and ultra high net worth individuals. The Cobbletons members are a community of both buyers and sellers.</p>
              <h3 className="small-gold-title">Generate more business with Cobbletons.</h3>
              <p>Partners can list opportunities for 14 days exclusively to the Cobbletons community and receive offers straight away. Cobbletons partners and agents have access to all unsold opportunities, which Cobbletons have been instructed.</p>
              <h3 className="small-gold-title">Increase your revenue with Cobbletons.</h3>
              <p>Partners have access to the Cobbletons platform that functions on a confidential basis & all transactions are known only to the Cobbletons community. The Cobbletons partner/agent program will always be run on a fair and transparent basis with very fair commission sharing arrangements.</p>
                <button type={"button"} onClick={() => window.location.href = '/register'}>JOIN THE COMMUNITY NOW</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Agents;
