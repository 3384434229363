import React from 'react';
import { connect } from 'react-redux';

import SearchBox from '../../../../components/SearchBox';
import Tabs from './components/Tabs';

import './index.scss';
import DashFooter from '../../../../components/DashFooter';

const MyCBLS = (props) => {
  return (
    <div className={"MyCBLSWrapper"}>
      <div className={'london-bg-day h-400'}>
        <div className={'container align-items-center justify-content-center h-100 d-flex'}>
          <div className={'d-flex mt-5 justify-content-center w-100'}>
            <SearchBox/>
          </div>
        </div>
      </div>
      <div className={'container mt-4 mb-4'}>
        <div className={'row mt-5 mb-5'}>
          <div className={'col-12 col-md-7 mt-5 mb-3'}>
            <h1 className={'title tk-essonnes-headline'}>{new Date().getHours() < 12 ? "Good Morning" : new Date().getHours() >= 12 && new Date().getHours() < 17 ? "Good Afternoon" : "Good Evening"} {props.user.first_name}</h1>
            <p>You are logged in as: {props.user.primary_email}</p>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-12"}>
            <Tabs />
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  )
};

const mapStateToProps = state => ({
  user: state.login.user
});

export default connect(mapStateToProps)(MyCBLS);