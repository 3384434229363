import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';

import Feedback from '../../../../../components/Feedback';
import Http from '../../../../../constants/Http';
import Loading from '../../../../../constants/Loading';
import EmptySection from '../../../../../components/EmptySection';
import SmallPropertyCard from '../../../../../components/SmallPropertyCard';
import SmallViewingTable from '../../../../../components/SmallViewingTable';

class Viewings extends React.Component {
  state = {
    viewings: null,
    activeFeedback: {
      viewingID: null,
      propertyID: null
    }
  };

  componentWillMount() {
    this.getViewings();
  }

  getViewings = () => {
    Http.get('/user/sales/my-sales-search/viewings')
      .then(res => {
        this.setState({
          viewings: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };

  toggleFeedback = (viewingID, propertyID) => {
    if (viewingID === null && propertyID === null) {
      this.getViewings();
    }
    this.setState({
      activeFeedback: {
        viewingID,
        propertyID
      }
    });
  };

  render() {
    const { state } = this;
    const {
      viewings,
      activeFeedback: { viewingID, propertyID }
    } = state;
    return (
      <React.Fragment>
        {viewings !== null ? (
          viewings.length > 0 ? (
            <div className={'col-12 ViewingAppointments w-100 mt-4 pb-5'}>
              <React.Fragment>
                <p className={'title pb-3 pl-3'}>Your Offers</p>
                <div className={'border-top'} />
                <div
                  className={
                    'border-bottom pt-2 pb-2 d-flex align-items-center'
                  }>
                  <div className={'col'}>
                    <span>Property</span>
                  </div>
                </div>
                <div className={'new-table-property'}>
                  {viewings.map((property, index) => (
                    <React.Fragment>
                      {property.viewings.map(
                        (viewing, index2) =>
                          viewingID === viewing.id &&
                          propertyID === viewing.property_id &&
                          viewing.feedback === null && (
                            <Feedback
                              propertyID={viewing.property_id}
                              viewingID={viewing.id}
                              imgs={viewing.property.images}
                              propertyForSale={
                                viewing.property.property_for_sale
                              }
                              propertyForLet={
                                viewing.property.property_for_rent
                              }
                              address={viewing.property.address}
                              initialValues={viewing.feedback}
                              toggle={() => this.toggleFeedback(null, null)}
                              create
                            />
                          )
                      )}
                      {viewingID === null &&
                        propertyID === null && (
                          <SmallPropertyCard
                            property={property}
                            index={index}
                            collapseComponent={
                              <SmallViewingTable
                                offers={property.viewings}
                                toggleFeedback={this.toggleFeedback}
                              />
                            }
                          />
                        )}
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            </div>
          ) : (
            <div className={'col-12'}>
              <EmptySection title={"You haven't requested any viewings yet"} />
            </div>
          )
        ) : (
          <Loading />
        )}
      </React.Fragment>

      // viewings !== null ? (
      //   viewings.length > 0 ? (
      //     <div className={'col-12 mt-4 pb-5'}>
      //       {viewings.map((viewing, i) => (
      //         <React.Fragment>
      //           {viewingID === viewing.id &&
      //             propertyID === viewing.property_id &&
      //             viewing.feedback === null && (
      //               <Feedback
      //                 propertyID={viewing.property_id}
      //                 viewingID={viewing.id}
      //                 imgs={viewing.property.images}
      //                 propertyForSale={viewing.property.property_for_sale}
      //                 propertyForLet={viewing.property.property_for_rent}
      //                 address={viewing.property.address}
      //                 initialValues={viewing.feedback}
      //                 toggle={() => this.toggleFeedback(null, null)}
      //                 create
      //               />
      //             )}
      //         </React.Fragment>
      //       ))}
      //       {viewingID === null &&
      //         propertyID === null && (
      //           <div className={'row row-eq-height'}>
      //             <div className={`col-12 pr-0 pl-0`}>
      //               <div className={'d-flex'}>
      //                 <div className={'ViewingAppointments w-100 h-100 '}>
      //                   <p className={'title'} style={{ fontWeight: 'bold' }}>
      //                     Your Viewings
      //                   </p>
      //                   <div className={'border-top mt-4 w-767'} />
      //                   <div
      //                     className={
      //                       'border-bottom pt-2 pb-2 d-flex align-items-center w-767'
      //                     }>
      //                     <div className={'col-3'}>
      //                       <b>Property</b>
      //                     </div>
      //                     <div className={'col-3'}>
      //                       <b>Date</b>
      //                     </div>
      //                     <div className={'col-2'}>
      //                       <b>Status</b>
      //                     </div>
      //                     <div className={'col'}>&nbsp;</div>
      //                   </div>
      //                   {viewings.map((viewing, i) => (
      //                     <div
      //                       className={
      //                         'd-flex align-items-center pt-2 pb-2 border-bottom w-767'
      //                       }
      //                       key={i}>
      //                       <div className={'col-3'}>
      //                         {viewing.property.property_name}
      //                       </div>
      //                       <div className={'col-3'}>
      //                         {moment(viewing.time_from).format('LLL')}
      //                       </div>
      //                       <div className={'col-2'}>
      //                         {viewing.status.name}
      //                       </div>
      //                       <div className={'col'}>
      //                         {viewing.feedback === null && viewing.status.name === 'Completed' ? (
      //                           <button
      //                             className={
      //                               'cbls-dark-grey-button btn-block'
      //                             }
      //                             style={{
      //                               height: 30,
      //                               cursor: 'pointer'
      //                             }}
      //                             type={'button'}
      //                             onClick={() =>
      //                               this.toggleFeedback(
      //                                 viewing.id,
      //                                 viewing.property_id
      //                               )
      //                             }>
      //                             Submit Feedback
      //                           </button>
      //                         ) : viewing.status.name === 'Completed' ?
      //                           'Feeback Already Left' : 'Viewing Not Yet Completed'
      //                         }
      //                       </div>
      //                     </div>
      //                   ))}
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         )}
      //     </div>
      //   ) : (
      //     <div className={"col-12"}>
      //       <EmptySection title={"You haven't got any viewings yet"} />
      //     </div>
      //   )
      // ) : (
      //   <Loading />
      // )
    );
  }
}

export default Viewings;
