import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

export default class Rentals extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Rentals</h2>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 30 }}>
              <h4>For those looking for the perfect rental property, Cobbletons specialist team of dedicated lettings professionals are ready to show you the finest properties to rent in London. </h4>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 60 }}>
              <p>We endeavour to make your property search a pleasurable experience, relieving you of the stress and frustration associated with the extremely competitive lettings market in London.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>If you’re looking for a luxury flat or house for rent in London then please don't hesitate to contact one of the team, who have the extensive knowledge and experience to guide you through the search and administration processes involved.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Our personal service means that our agents take the time to understand what you are looking for to find the perfect match for your needs. Using our unrivalled local knowledge, we will provide a selection of the best property to rent in North London matching your requirements, then streamline the application process when you’re ready to commit.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We are here to assist you, to provide advice and to ensure that you are well represented and positioned to have the edge on other tenants. We can also advise you on your rights as a tenant and your legal obligations when signing a tenancy agreement.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We specialise in houses and flats to rent in London, managing a variety of properties spanning from Marylebone to Hampstead and everything in between. We understand the unique characteristics and diverse properties found in each area and as such, we are the trusted partner of many of its top developers and landlords.</p>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    )
  }
}