import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Fade } from 'react-reveal';
import { Parallax } from 'react-parallax';

import LandingContent from '../../components/LandingContent';
import Http from '../../constants/Http';
import LogoBanner from '../../components/LogoBanner';
import LandingFooter from '../../components/LandingFooter';

import cblsLogo from '../../images/CBLS_SPLASH_LOGO.svg';

import './index.scss';
import BuyingSelling from "./Modals/buying-selling";
import Agents from "./Modals/agents";
import PropertyToList from './Modals/PropertyToList';
import HomepagePropertyCard from "../../components/HomepagePropertyCard";
import { Link } from "react-router-dom";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.changeFade = this.changeFade.bind(this);
  }

  state = {
    zoom: true,
    fade: false,
    buyingModal: false,
    agentModal: false,
    PropertyToListModal: false,
    PageContent: null
  };

  animateLogo() {
    let letters = document.getElementsByClassName('col-cbls');

    if (window.localStorage.getItem('hideSplash') === 'true') {
      let LoadingSplash = document.getElementsByClassName('LoadingSplash');
      let loading = document.getElementsByClassName('react-reveal wrapper');
      loading[0].style.position = 'relative';
      loading[0].style.height = '0px';
      loading[0].style.width = '0px';
      LoadingSplash[0].style.display = 'none';
    } else {
      window.setTimeout(() => {
        window.scrollTo(0, 0);
        window.instantScroll();
      }, 200);

      // Letter Removal
      window.setTimeout(() => {
        letters[1].classList.add('slide'); //// O
      }, 2100);
      window.setTimeout(() => {
        letters[3].classList.add('slide'); //// B2
      }, 2400);
      window.setTimeout(() => {
        letters[5].classList.add('slide'); //// E
      }, 2700);
      window.setTimeout(() => {
        letters[6].classList.add('slide'); //// T
      }, 3000);
      window.setTimeout(() => {
        letters[7].classList.add('slide'); //// O2
      }, 3300);
      window.setTimeout(() => {
        letters[8].classList.add('slide'); //// N
      }, 3600);

      // Letter Moving
      window.setTimeout(() => {
        letters[0].classList.add('c');
        letters[2].classList.add('b');
        letters[4].classList.add('l');
        letters[9].classList.add('s');
      }, 4100);

      // Zoom Out and Slide Logo
      window.setTimeout(() => {
        let cbls = document.getElementsByClassName('cblsLetters');
        let logo = document.getElementsByClassName('cblsLogoSlide');
        logo[0].classList.add('slide');
        cbls[0].classList.add('slide');
        logo[0].style.transform = `translate(0px, -${logo[0].y}px) scale(0.58)`;
      }, 4400);

      window.setTimeout(() => {
        let text = document.getElementsByClassName('cblsLetters');
        text[0].style.display = 'none';
        let logo = document.getElementsByClassName('cblsLogoSlide');
        logo[0].style.display = 'none';
      }, 4800);

      window.setTimeout(() => {
        let LoadingSplash = document.getElementsByClassName('LoadingSplash');
        let loading = document.getElementsByClassName('react-reveal wrapper');
        loading[0].style.position = 'relative';
        loading[0].style.height = '0px';
        loading[0].style.width = '0px';
        LoadingSplash[0].classList.add('slide');
      }, 4800);

      window.setTimeout(() => {
        let LoadingSplash = document.getElementsByClassName('LoadingSplash');
        LoadingSplash[0].style.display = 'none';
        window.localStorage.setItem('hideSplash', 'true');
      }, 6200);
    }
  }

  changeFade() {
    this.setState({
      fade: !this.state.fade
    });
  }
  
  toggleBuying = () => {
    this.setState({
      buyingModal: !this.state.buyingModal
    })
  };
  
  toggleAgent = () => {
    this.setState({
      agentModal: !this.state.agentModal
    })
  };
  
  toggleList = () => {
    this.setState({
      PropertyToListModal: !this.state.PropertyToListModal
    })
  };
  
  getPageContent = () => {
    Http.post('/get-page-content', { url: window.location.pathname })
      .then(res => {
        this.setState({
          PageContent: res.data.data
        })
      })
      .catch(err => {
        console.log(err);
      })
  };

  componentWillMount() {
    this.getPageContent();
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.changeFade();
    this.animateLogo()
  }

  componentWillUnmount() {
    this.setState({
      fade: !this.state.fade
    });
  }

  render() {
    return (
      <React.Fragment>
        <BuyingSelling isOpen={this.state.buyingModal} toggle={() => this.toggleBuying()} />
        <Agents isOpen={this.state.agentModal} toggle={() => this.toggleAgent()} />
        <PropertyToList isOpen={this.state.PropertyToListModal} toggle={() => this.toggleList()} />
      <div className={'landing-v2'}>
        <Fade delay={1500} when={this.state.fade}>
          <div className="wrapper">
            <div className="LoadingSplash text-center">
              <div className="container">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-12">
                    <img
                      src={cblsLogo}
                      alt=""
                      className="cblsLogoSlide"
                      style={{ width: 90 }}
                    />
                    <div className="cblsLetters">
                      <div className="col-cbls">
                        <img src={'/images/letters/C.svg'} alt="" />
                      </div>
                      <div className="col-cbls o">
                        <img src={'/images/letters/O.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/B.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/B.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/L.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/E.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/T.svg'} alt="" />
                      </div>
                      <div className="col-cbls o">
                        <img src={'/images/letters/O.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/N.svg'} alt="" />
                      </div>
                      <div className="col-cbls">
                        <img src={'/images/letters/S.svg'} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade
          delay={
            window.localStorage.getItem('hideSplash') === 'true' ? 1000 : 3000
          }>
          <div className="LandingWrapper">
            {this.state.PageContent !== null &&
              <LandingContent Banner_Subheading_Type_Wysiwyg={this.state.PageContent.content.Banner_Subheading_Type_Wysiwyg}/>
            }
            <div className={'bg-dark pt-0'} id={"contentTop"}>
              <div className="text-center cobble-path-one" id="contentTop">
                <div className={'path-one-content container'}>
                  <div className={'row d-flex justify-content-center'}>
                    <div className={'col-12 col-lg-8 d-flex justify-content-center'}>
                      {this.state.PageContent !== null &&
                        <h4 className={"gradient-text"} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_Gold_Type_Wysiwyg }}/>
                      }
                    </div>
                    {this.state.PageContent !== null &&
                      <div className={'col-12 col-lg-8 d-flex justify-content-center'} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_One_White_Type_Wysiwyg }}/>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={'img-tile-logo'}>
              <div className={'bg-dark'} />
              <div className={'bg-normal'} />
              <div className={'img-tile-logo-img'}>
                <div className="container h-100">
                  <div className="row d-flex justify-content-center h-100">
                    <div className="col-12 col-lg-8 h-100">
                      <div className="img img-fluid h-100">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'container'} style={{ backgroundColor: 'white' }}>
              <div className={'row'}>
                <div className={'col-12 col-lg-11'}>
                  <div className="cobble-path-two">
                    <div className={'row'}>
                      <div className={'col-12 col-lg-9'}>
                        {this.state.PageContent !== null &&
                          <div className={'the-only-place-you-c'} style={{ marginTop: 100 }} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_Two_Gold_Type_Wysiwyg }}/>
                        }
                        {this.state.PageContent !== null &&
                          <div dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_Two_Bullet_Type_Wysiwyg }}/>
                        }
                        {/*<div className={'checkmark'}>*/}
                          {/*<img*/}
                            {/*src={*/}
                              {/*'/images/new/Landing/checkbox-marked-outline.svg'*/}
                            {/*}*/}
                          {/*/>*/}
                          {/*<div>*/}
                            {/*An exclusive digital community showcasing*/}
                            {/*properties seen nowhere else.*/}
                          {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className={'checkmark'}>*/}
                          {/*<img*/}
                            {/*src={*/}
                              {/*'/images/new/Landing/checkbox-marked-outline.svg'*/}
                            {/*}*/}
                          {/*/>*/}
                          {/*<div>*/}
                            {/*14 day window of opportunities for sales and*/}
                            {/*rentals.*/}
                          {/*</div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={"pre-footer bg-dark"}>
              <div className={"container"}>
                <div className={"row d-flex justify-content-center"}>
                  <div className={"col-12"}>
                    <h3>FIND YOUR OPPORTUNITY</h3>
                  </div>
                  <div className={"col-12 col-lg-9"}>
                    <h1>JOIN THE COBBLETONS COMMUNITY</h1>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-12 col-lg-6"}>
                    <button className={"huge-button"} onClick={() => this.toggleBuying()}><p className={"click-to-see"}>CLICK TO SEE</p><p>Benefits If you are selling or buying</p></button>
                  </div>
                  <div className={"col-12 col-lg-6"}>
                    <button className={"huge-button"} onClick={() => this.toggleAgent()}><p className={"click-to-see"}>CLICK TO SEE</p><p>Benefits If you are an agent or partner</p></button>
                  </div>
                </div>
              </div>
            </div>
              <div className={"huge-img-tile"}>
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-lg-10">
                    {this.state.PageContent !== null &&
                      <div className={"bg-dark pb-0"} dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Section_Four_Gold_Type_Wysiwyg }}/>
                    }
                  </div>
                  <div className="col-12 col-lg-10">
                    <div className={"bg-dark pt-0 small"}>
                      <h5 className={"text-white"}>WHY WOULD I JOIN COBBLETONS</h5>
                      <div className="row d-flex justify-content-center">
                        <div className="col-10">
                          <div className="row d-flex justify-content-center mb-3">
                            <div className="col-2 col-md-1">
                              <img src={'/images/icons/icon-home.svg'} />
                            </div>
                            <div className="col-10">
                              <p>View properties which are seen nowhere else</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-10">
                          <div className="row d-flex justify-content-center mb-3">
                            <div className="col-2 col-md-1">
                          <img src={'/images/icons/icon-watch.svg'} />
                        </div>
                        <div className="col-10">
                          <p>If you want to buy or sell. Find an opportunity in 14 days it's hassle free!</p>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-10">
                          <div className="row d-flex justify-content-center mb-3">
                            <div className="col-2 col-md-1">
                          <img src={'/images/icons/icon-lock.svg'} />
                        </div>
                        <div className="col-10">
                          <p>It's exclusive. It's discreet and it's a lifetime membership. We ONLY display our opportunities through our private platform. <span onClick={() => this.toggleBuying()}>Join Cobbletons now!</span></p>
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="call-us-footer bg-dark">
              {/*<div style={{ background: '#f5f5f5' }}>*/}
              {/*  <div className="container">*/}
              {/*  <div className="row d-flex justify-content-center pb-5 mb-4 mt-5 pt-5">*/}
              {/*    <div className="col-12 d-flex justify-content-center">*/}
              {/*      {this.state.PageContent !== null &&*/}
              {/*      <h4 className={"gradient-text text-center"} style={{ maxWidth: 700, color: '#eba94f', textAlign: 'center', background: 'none', WebkitTextFillColor: 'unset' }}>{this.state.PageContent.content.Property_Example_Title_Type_TextInput}</h4>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*    <div className="col-12">*/}
              {/*      {this.state.PageContent !== null &&*/}
              {/*      <HomepagePropertyCard PageContent={this.state.PageContent} toggle={() => this.toggleBuying()} cardNum={"One"} />*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*    <div className="col-12">*/}
              {/*      {this.state.PageContent !== null &&*/}
              {/*      <HomepagePropertyCard PageContent={this.state.PageContent} toggle={() => this.toggleBuying()} cardNum={"Two"} />*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="container pt-5">
                <div className="row d-flex justify-content-center pt-5 mt-5">
                  <div className="col-12 d-flex justify-content-center">
                    <img src={'/images/new/Landing/logo-new.svg'} />
                  </div>
                  <div className="col-12 col-lg-8">
                    <h2>Start finding your opportunity with Cobbletons</h2>
                  </div>
                  <div className="col-12 col-lg-8">
                    <button className={"join-button"} onClick={() => this.props.push('/register')}>NOT YET A MEMBER, CLICK HERE TO JOIN THE COMMUNITY</button>
                  </div>
                  <div className={"col-12 col-lg-8 d-flex justify-content-center"}>
                    <button className={"property-to-list-button"} onClick={() => this.toggleList()}>I HAVE A PROPERTY TO LIST</button>
                  </div>
                  <div className={"col-12 col-lg-8 d-flex justify-content-center mt-3"}>
                    <button className={"property-to-list-button"} onClick={() => this.props.push('/login')}>ALREADY HAVE AN ACCOUNT</button>
                  </div>
                </div>
              </div>
              <p className={"speak-to-us"} style={{bottom: 120}}>Need Help?</p>
              <p className={"phone-number"} style={{bottom: 90}}>Just call us at {this.state.PageContent !== null && <span dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Phone_Number_Type_TextInput }}></span>}</p>
              <p className={"phone-number"} style={{bottom: 60}}>Email us: <a href={"mailto:welcome@cobbletons.com"}>welcome@cobbletons.com</a></p>
              <p className={"phone-number"} style={{bottom: 30}}>Press enquiries: <a href={"mailto:press@cobbletons.com"}>press@cobbletons.com</a></p>
              <p className={"phone-number"} style={{bottom: 0}}>Have a complaint?: <a href="/complaints#complaints">Click here</a></p>
            </div>
            <div className="d-flex justify-content-center w-100 bg-dark">
              <Link to={'/trusting-cobbletons'} style={{ opacity: 0.6, fontFamily: 'Proxima Nova, sans-serif', fontSize: 18, color: '#FFFFFF', textAlign: 'center' }}>Why Trust Cobbletons</Link>
            </div>
            <div className="call-us-footer bg-dark" style={{ zIndex: 0, marginTop: 0, minHeight: 200, padding: 0, display: 'flex', justifyContent: 'center' }}>
              <div className="row d-flex justify-content-center w-100">
                <div className="col-8 d-flex justify-content-center align-items-center">
                  <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-4">
                      <img src={"/images/accreditation/naea.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                    </div>
                    <div className="col-12 col-md-4">
                      <img src={"/images/accreditation/the_property_ombudsman.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                    </div>
                    <div className="col-12 col-md-4">
                      <img src={"/images/accreditation/tsi.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing);
