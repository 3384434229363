import React from "react";
import { Input, FormFeedback } from "reactstrap";

const TextInput = (props) => {
  const {
    input,
    type,
    className,
    placeholder,
    size,
    autoFocus,
    defaultValue,
    meta: { touched, error }
  } = props;
  
  const onChangeCurrency = (event, props) => {
    const newValue = event.target.value.replace(/,|\.|£|[a-zA-z]|\s|\W/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    props.input.onChange(`£${newValue}`);
  };
  
  return (
    <div>
      {type === "currency" ?
        <Input
          {...input}
          defaultValue={defaultValue}
          className={className}
          placeholder={placeholder}
          type={type}
          bsSize={size}
          autoFocus={autoFocus}
          onChange={(event) => onChangeCurrency(event, props)}
        />
        :
        <Input
          {...input}
          defaultValue={defaultValue}
          className={className}
          placeholder={placeholder}
          type={type}
          bsSize={size}
          autoFocus={autoFocus}
        />
      }
      {touched && error && (<FormFeedback>{error}</FormFeedback>)}
    </div>
  );
};

export default TextInput;
