import React from 'react';
import { Link } from 'react-router-dom';
import Http from '../../constants/Http';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Modal, Label, Form, FormGroup, Button, ModalHeader } from 'reactstrap';
import TextInput from '../Fields/TextInput';

const required = value => (value ? undefined : 'Required');

class MakeAnOfferModal extends React.Component {
  state = {
    isOpen: false,
    submitting: false
  };

  componentDidMount() {}

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  onSubmit = values => {
    this.setState({
      submitting: true
    });
    values.property_id = this.props.id;
    Http.post(`/user/${this.props.sale ? 'sales' : 'lettings'}/offer`, values)
      .then(res => {
        this.toggle();
        this.setState({
          submitting: false
        });
        swal({
          title: 'Success',
          text: `${res.data.success.message}`,
          icon: 'success',
          button: 'OK'
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    const { address, property_name, salePrice, rentPrice } = props;
    return (
      <div className={'row'}>
        <div className={'col-12'}>
          <button
            disabled={props.owner}
            className={'btn btn-block new-property-button mt-3'}
            onClick={() => this.toggle()}>
            Make an offer
          </button>
          <Modal
            isOpen={this.state.isOpen}
            toggle={() => this.toggle()}
            style={{ maxWidth: '100%' }}>
            <ModalHeader toggle={() => this.toggle()} />
            <div className={'row justify-content-center'}>
              {address !== undefined && (
                <div className={'col-12 mt-4 text-center'}>
                  <h4
                    className={'mb-4 pb-3 pt-5'}
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: 300
                    }}>
                    Make An Offer
                  </h4>
                  <p>Property</p>
                  <p
                    className={'title font-weight-bold mb-3'}
                    style={{ fontSize: 20 }}>
                    {property_name}
                  </p>
                  <p>Price</p>
                  <p
                    className={'title font-weight-bold mb-3'}
                    style={{ fontSize: 20 }}>
                    {this.props.sale ? (
                      <span>
                        &pound;&nbsp;
                        {salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                      </span>
                    ) : (
                      <span>
                        &pound;&nbsp;
                        {rentPrice
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                      </span>
                    )}
                  </p>
                  <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <div className={'row justify-content-center mt-5'}>
                      <FormGroup className={'col-6'}>
                        <Label className={'font-weight-bold'}>Your Offer</Label>
                        <Field
                          className={'bs-placeholder-center'}
                          name={'offer'}
                          component={TextInput}
                          type={'currency'}
                          validate={[required]}
                        />
                      </FormGroup>
                      <FormGroup className={'col-12 d-inline mt-3'}>
                        <Field
                          name="TSANDCS"
                          id="TSANDCS"
                          component={'input'}
                          type="checkbox"
                          validate={[required]}
                        />
                        <label htmlFor="TSANDCS">
                          &nbsp;&nbsp;I have read and understand the <Link target={'_blank'} to={'/membership-conditions'}>Membership Conditions</Link>
                        </label>
                        <br />
                      </FormGroup>
                      <div className={'col-8'}>
                        <p>
                          <small>
                            We are required by the Estate Agents Act 1979 to
                            declare any personal interest on the part of any
                            member of our staff or their associates, before
                            negotiations can begin regarding any property
                            transaction. If you are connected to any member of
                            our staff, however remotely, you must notify us
                            without delay in order that we can fulfil our duties
                            under the Estate Agents Act 1979..
                          </small>
                        </p>
                      </div>
                      <div className={'col-12 mb-3'}>
                        <Button
                          className={'btn cbls-dark-grey-button mt-3 mb-5'}
                          disabled={!props.TSANDCS}
                          style={{ width: 200, fontSize: 14 }}>
                          {this.state.submitting ? (
                            <img
                              src={'/images/ui/Rolling.svg'}
                              alt={'loading deals'}
                              style={{ width: 15, height: 15 }}
                            />
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('makeOfferForm');

const mapStateToProps = state => ({
  TSANDCS: selector(state, 'TSANDCS')
});

export default connect(mapStateToProps)(
  reduxForm({ form: 'makeOfferForm' })(MakeAnOfferModal)
);
