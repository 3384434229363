import React from 'react';
import moment from 'moment';
import { Collapse } from 'reactstrap';

import EmptySection from '../../../../../components/EmptySection';
import Loading from '../../../../../constants/Loading';
import Http from '../../../../../constants/Http';
import swal from 'sweetalert';
import SmallPropertyCard from '../../../../../components/SmallPropertyCard';
import SmallOfferTable from '../../../../../components/SmallOfferTable';

class Offers extends React.Component {
  state = {
    offers: null
  };

  componentWillMount() {
    this.getOffers();
  }

  getOffers = () => {
    Http.get('/user/sales/my-sales-search/offers')
      .then(res => {
        this.setState({
          offers: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };

  render() {
    const { state } = this;
    const { offers } = state;

    return (
      <React.Fragment>
        {offers !== null ? (
          offers.length > 0 ? (
            <div className={'col-12 ViewingAppointments w-100 mt-4 pb-5'}>
              <p className={'title pb-3 pl-3'}>Your Offers</p>
              <div className={'border-top'} />
              <div
                className={'border-bottom pt-2 pb-2 d-flex align-items-center'}>
                <div className={'col'}>
                  <span>Property</span>
                </div>
              </div>
              <div className={"new-table-property"}>
                {offers.map((property, index) => (
                  <SmallPropertyCard property={property} index={index} collapseComponent={<SmallOfferTable offers={property.offers} />} />
                ))}
              </div>
            </div>
          ) : (
            <div className={'col-12'}>
              <EmptySection title={"You haven't sent any offers yet"} />
            </div>
          )
        ) : (
          <Loading />
        )}
        
        {/*{offers !== null ? (*/}
          {/*offers.length > 0 ? (*/}
            {/*<div className={'col-12 ViewingAppointments w-100 mt-4 pb-5'}>*/}
              {/*<p className={'title pt-4 pl-3 pb-4'}>Your Offers</p>*/}
              {/*<div className={'border-top w-767'} />*/}
              {/*<div*/}
                {/*className={'border-bottom pt-2 pb-2 d-flex align-items-center w-767'}>*/}
                {/*<div className={'col'}>*/}
                  {/*<b>Property</b>*/}
                {/*</div>*/}
                {/*<div className={'col'}>*/}
                  {/*<b>Status</b>*/}
                {/*</div>*/}
                {/*<div className={'col'}>*/}
                  {/*<b>Offer Amount</b>*/}
                {/*</div>*/}
                {/*<div className={'col'}>*/}
                  {/*<b>Date</b>*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*{offers.map((offer, i) => (*/}
                {/*<div*/}
                  {/*className={*/}
                    {/*'d-flex align-items-center pt-2 pb-2 border-bottom w-767'*/}
                  {/*}*/}
                  {/*key={i}>*/}
                  {/*<div className={'col'}>{offer.property}</div>*/}
                  {/*<div className={'col'}>{offer.status.name}</div>*/}
                  {/*<div className={'col'}>*/}
                    {/*£*/}
                    {/*{offer.offer*/}
                      {/*.toString()*/}
                      {/*.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                  {/*</div>*/}
                  {/*<div className={'col'}>*/}
                    {/*{moment(offer.date.date).format('DD / MM / YYYY')}*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*))}*/}
            {/*</div>*/}
          {/*) : (*/}
            {/*<div className={'col-12'}>*/}
              {/*<EmptySection title={"You haven't sent any offers yet"} />*/}
            {/*</div>*/}
          {/*)*/}
        {/*) : (*/}
            {/*<Loading />*/}
        {/*)}*/}
      </React.Fragment>
    );
  }
}

export default Offers;
