import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { push } from "connected-react-router";
import { Form, FormGroup, Button, Label } from 'reactstrap';

import * as validations from '../../components/Validations';

import LogoBanner from '../../components/LogoBanner';
import TextInput from '../../components/Fields/TextInput';

import { login } from '../../modules/login';

import './index.scss';

class Login extends React.Component {
  componentWillMount() {
    if (window.localStorage.getItem('token') !== null) {
      this.props.dispatch(push('/dashboard'));
    }
  }
  onSubmit = values => {
    this.props.dispatch(login(values));
  };

  render() {
    const { props } = this;
    const { handleSubmit } = props;
    return (
      <div>
        <LogoBanner />
        <div className={'cbls-container login-container'}>
          <div className={'container pl-2 pr-2 pb-5'}>
            <div className={'row justify-content-center'}>
              <div className={'col-12 text-center text-white mt-5 d-none d-md-inline'}>
                <div className={"d-flex d-none d-md-inline justify-content-center "}>
                  <Link to={'/'}><img width={"50"} height={"50"} src={"/images/new/Landing/logo-new.svg"} /></Link>
                </div>
                <h1>Find your opportunity</h1>
              </div>
              <div className={'col-12 col-md-8 bg-white text-center mt-3 pl-lg-5 pr-lg-5 mt-md-5 pt-md-5'}>
                <div className={"d-flex d-md-none justify-content-center "}>
                  <Link to={'/'}><img width={"50"} height={"50"} src={"/images/new/Landing/logo-new.svg"} /></Link>
                </div>
                <div className={'pt-3 pl-sm-5 pr-sm-5 pb-5'}>
                  <p>
                    Search, view and purchase properties which are only
                    available to Cobbletons site members and cannot be viewed
                    anywhere else.
                  </p>
                  <Form
                    onSubmit={handleSubmit(this.onSubmit)}
                    className="text-left pt-2">
                    <FormGroup>
                      <Label style={{ fontWeight: 'bold' }}>Email</Label>
                      <Field
                        name="email"
                        component={TextInput}
                        type="text"
                        placeholder="example@example.com"
                        validate={validations.required}
                      />
                    </FormGroup>
                    <FormGroup className={'mt-4'}>
                      <Label style={{ fontWeight: 'bold' }}>Password</Label>
                      <Field
                        name="password"
                        component={TextInput}
                        type="password"
                        placeholder="*********"
                        validate={validations.required}
                      />
                    </FormGroup>
                    <FormGroup>
                      <div className="row justify-content-center">
                        <div className="mt-2 mb-2 pt-4 col-sm-6 col-md-6 text-center">
                          <Button className="btn btn-block cbls-dark-grey-button">
                            Login
                          </Button>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-5">
                        <div className="col-12 text-center">
                          <Link className="url-link" to='/forgot-password'>Forgot password?</Link>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-4">
                        <div className="col-12 text-center">
                          <Link className="url-link" to='/register'>Not yet a member? Click here to join the community.</Link>
                        </div>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'loginForm'
  })(Login)
);
