import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

class CBLSBuyingSolution extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Cobbletons Buying Solution</h2>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 30 }}>
              <h4>Working on behalf of retained clients, we find and secure exceptional properties in London and the country, providing the complete solution to your residential property search. </h4>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 60 }}>
              <p>Working with all selling agents, we offer total market coverage and a high percentage of our client acquisitions are off or pre-market.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Our team of expert buying agents pride themselves on providing a tailor-made service from the beginning of your search right through to our dedicated completion service. We aim to secure your property at the best terms available, having undertaken comprehensive due-diligence, providing impartial advice on price and any other issues which could affect your future enjoyment of the property.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>The search and shortlisting of properties is only the start of the service, we also carry out extensive due diligence on every chosen property and provide impartial advice on price and any other issues which could affect a client's future with the property. These include planning and environmental issues, renovation costs and feasibility of carrying out these works, blights, income and running costs, privacy, commutability, schooling and amenities.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 40, marginBottom: 25 }}>
              <h5>The Property Search</h5>
            </div>
            <div className={"col-12 col-md-10"}>
              <p>Each property search begins with an initial consultation, either by phone or in person, where we create a bespoke plan of action that will be tailored to you based on the type of property you require as well as your ownership aims. By fully understanding and anticipating your current and future property requirements we can target specific areas and property types, shortlisting on and off-market opportunities which closely match your lifestyle needs or financial objectives.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 40, marginBottom: 25 }}>
              <h5>Negotiation</h5>
            </div>
            <div className={"col-12 col-md-10"}>
              <p>Every negotiation is different and each therefore requires a careful tactical approach. Our collective experience and expertise, combined with painstaking due diligence carried out on every property purchased, provides a high level of comfort to our clients.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Our reputation allows us to acquire your chosen properties at the best possible price, often saving substantial amounts of money throughout the process.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We are creative when it comes to negotiating on your behalf and our aim is always to secure the property on the best available terms and to place you, whenever possible, in a 'preferred purchaser' position. It is not always the case that the highest offer succeeds.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Once terms are agreed, we work with your solicitor or other advisors, assisting with any issues that might affect the terms of purchase.</p>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    )
  }
}

export default CBLSBuyingSolution;