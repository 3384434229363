import React from 'react';
import Autosuggest from 'react-autosuggest';
import { FormFeedback } from 'reactstrap';

import './index.scss';

const getSuggestionValue = suggestion => suggestion.city;

const renderSuggestion = suggestion => (
  <div style={{ width: '100%', height: 20, fontSize: 14, fontFamily: 'proxima-nova, sans-serif' }}>
    <p>{suggestion.city} <span className={"float-right"}>{suggestion.count} Properties</span></p>
  </div>
);

class PropertySearch extends React.Component {
  state = {
    value: '',
    suggestions: [],
  };
  
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.input.onChange(newValue)
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };
  
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  
  shouldRenderSuggestions = value => {
    return value.trim.length >= 0;
  };
  
  getSuggestions = value => {
    let suggestions = [];
    
    if (this.props.suggestions !== null) {
      
      this.props.suggestions.forEach((item, i) => {
        if (item.city.toLowerCase().includes(value.toLowerCase())) {
          suggestions.push(item);
        }
      });
      
      return suggestions;
    } else {
      return [];
    }
  };
  
  render() {
    const { suggestions } = this.state;
    const inputProps = {
      placeholder: `e.g. Bristol, SW6, Victoria Station`,
      value: this.props.input.value,
      onChange: this.onChange,
      onBlur: this.props.input.onBlur,
      style: {
        textIndent: 25,
        borderRight: 0,
      }
    };
    return (
      <React.Fragment>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
        />
        <span style={{ top: -37, position: 'relative', left: 18 }}><i className="fas fa-map-marker-alt" style={{ transform: 'scale(1.2)', color: '#A5ADBB' }} /></span>
        {this.props.meta.touched && this.props.meta.error && (<FormFeedback style={{ marginTop: -10 }}>{this.props.meta.error}</FormFeedback>)}
      </React.Fragment>
    )
  }
}

export default PropertySearch;