import React from 'react';
import Http from '../../../../../constants/Http';
import swal from 'sweetalert';
import EmptySection from '../../../../../components/EmptySection';
import Loading from '../../../../../constants/Loading';

class Offers extends React.Component {
  state = {
    offers: null
  };

  componentWillMount() {
    this.getOffers();
  }

  getOffers = () => {
    Http.get('/user/lettings/my-lettings-search/offers')
      .then(res => {
        this.setState({
          offers: res.data.data
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };

  render() {
    const { state } = this;
    const { offers } = state;
    return (
      <React.Fragment>
        {offers !== null ? (
          offers.length > 0 ? (
            <div className={'col-12 border pb-5'}>
              <p className={'title pt-4 pl-3 pb-4'}>Your Offers</p>
              <div className={'border-top'} />
              <div
                className={'border-bottom pt-2 pb-2 d-flex align-items-center'}>
                <div className={'col-1'}>
                  <b>Ref</b>
                </div>
                <div className={'col'}>
                  <b>Property</b>
                </div>
                <div className={'col'}>
                  <b>Status</b>
                </div>
                <div className={'col'}>
                  <b>Offer Amount</b>
                </div>
                <div className={'col'}>
                  <b>Date</b>
                </div>
              </div>
              {offers.map((offer, i) => (
                <div
                  className={
                    'd-flex align-items-center pt-2 pb-2 border-bottom'
                  }
                  key={i}>
                  <div className={'col-1'}>#{offer.id}</div>
                  <div className={'col'}>{offer.property}</div>
                  <div className={'col'}>{offer.status.name}</div>
                  <div className={'col'}>
                    £
                    {offer.offer
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    {offer.frequency}
                  </div>
                  <div className={'col'}>{offer.date}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={"row"}>
            <EmptySection title={"You haven't sent any offers yet"} />
            </div>
          )
        ) : (
          <Loading />
        )}
      </React.Fragment>
    );
  }
}

export default Offers;
