import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

export default class Sales extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Sales</h2>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 30 }}>
              <h4>If you are looking to find your next home, investment or development property, you can have confidence that Cobbletons offers the best range of properties for sale in London and the surrounding areas. </h4>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 60 }}>
              <p>Navigating the competitive London property market can be daunting and in such a rapidly evolving environment, you need experience and expertise on your side.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Representing individual and corporate clients based in the UK and overseas, we take pride in our highly professional and discreet service, as well as our unsurpassed local knowledge, encompassing properties for sale in Marylebone, Regents Park, St. Johns Wood, Maida Vale, Primrose Hill, Belsize Park, Hampstead, Highgate and many more.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>If you are looking for a flat or houses for sale in North West London, our team will take time to understand your requirements and use their local knowledge to recommend only those properties that will best meet your needs.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>Our team places emphasis on sourcing both on and off-market opportunities to match your search requirements, so you will have access to the best properties on the market before other buyers. This can make all the difference when demand is so high. We also advise on the investment potential of the different properties on offer, ensuring you achieve the best value, both in the short and long term.</p>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    )
  }
}