import React, { Component } from "react";
import LogoBanner from "../../components/LogoBanner";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Http from "../../constants/Http";

import "./index.scss";


class Complaints extends Component {
  
  state = {
    PageContent: null
  };
  
  getPageContent = () => {
    Http.post("/get-page-content", { url: window.location.pathname })
      .then(res => {
        this.setState({
          PageContent: res.data.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  
  componentWillMount() {
    this.getPageContent();
  }
  
  componentDidMount() {
    window.setTimeout(() => {
      const moveGrey = document.getElementsByClassName("LoadingSplash");
      moveGrey[0].classList.add("slide");
    }, 1000);
  }
  
  render() {
    return (
      <Fade duration={2000} delay={200}>
        <div className={"about-page"}>
          <div className="LoadingSplash" style={{ top: -50, height: "100vh" }}/>
          <LogoBanner/>
          <div className="container">
            
            
            <div className="row text-center">
              <div className="col-12">
                <div className={"d-flex justify-content-center "}>
                  <Link to={"/"}><img width={"50"} height={"50"} src={"/images/new/Landing/logo-new.svg"}/></Link>
                </div>
                <h1 className={"register-h1 mt-2"}>Complaints</h1>
              </div>
            </div>
            
            <div className="row">
              
              <div className="row text-center">
                <div className={"col-12"} style={{ marginTop: 30 }}>
                  <h4>We are committed to providing a professional service to all our clients and customers.
                    Whensomething goes wrong, we need you to tell us about it. This will help us to improve our
                    standards.</h4>
                </div>
                <div className={"col-12"} style={{ marginTop: 60 }}>
                  <p>If you have a complaint, please put it in writing, including as much detail as possible. We will
                    thenrespond in line with the timeframes set out below (if you feel we have not sought to address
                    yourcomplaints within eight weeks, you may be able to refer your complaint to the Property
                    Ombudsmanto consider without our final viewpoint on the matter). <br/><br/>You can contact us via
                    Complaints@cobbletons.com or write to us at Cobbletons Limited, Suite 5 Vanguard Business Centre,
                    Alperton Lane, London,UB6 8AA, GB.</p>
                </div>
                <div className={"col-12"} style={{ marginTop: 25 }}>
                  <p>What will happen next?</p>
                </div>
                <div className={"col-12"}>
                  <ul>
                    <li>We will send you a letter acknowledging receipt of you complaint within three working days
                      ofreceiving it, enclosing a copy of this procedure.
                    </li>
                    <br/>
                    <li>We will then investigate your complaint. This will normally be dealt with by the office
                      managerwho will review your file and speak to the member of staff who dealt with you. A formal
                      writtenoutcome of our investigation will be sent to you within 15 working days of sending
                      theacknowledgement letter.
                    </li>
                    <br/>
                    <li>If, at this stage, you are still not satisfied, you should contact us again and we will arrange
                      fora separate review to take place by a senior member of staff.
                    </li>
                    <br/>
                    <li>We will write to you within 15 working days of receiving your request for a review,
                      confirmingour final viewpoint on the matter.
                    </li>
                    <br/>
                    <li>If you are still not satisfied after the last stage of the in-house complaint procedure (or
                      morethan 8 weeks has elapsed since the complaint was first made) you can request anindependent
                      review from The Property Ombudsman without charge.
                    </li>
                  </ul>
                </div>
                <div className="col-12 center-list">
                  <ul>
                    <li>The Property Ombudsman</li>
                    <li>Milford House</li>
                    <li>43-55 Milford StreetSalisbury</li>
                    <li>Wiltshire</li>
                    <li>SP1 2BP</li>
                    <li>01722 333 306</li>
                    <li><a href="mailto:admin@tpos.co.uk">admin@tpos.co.uk</a></li>
                    <li><a href="https://www.tpos.co.uk/" target="_blank">www.tpos.co.uk</a></li>
                  </ul>
                </div>
                <div className={"col-12"} style={{ marginTop: 25 }}>
                  <p>Please note the following:</p>
                </div>
                <div className={"col-12"} style={{ marginTop: 15 }}>
                  <p>You will need to submit your complaint to The Property Ombudsman within 12 months of receiving
                    ourfinal viewpoint letter, including any evidence to support your case.</p>
                </div>
                <div className={"col-12"} style={{ marginTop: 15 }}>
                  <p>The Property Ombudsman requires that all complaints are addressed through this in-housecomplaints
                    procedure, before being submitted for an independent review.</p>
                </div>
              </div>
            </div>
          
          
          </div>
        </div>
      </Fade>
    );
  }
}


export default Complaints;
