import React from 'react';
import { connect } from 'react-redux';
import { slide as Menu } from 'react-burger-menu';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import withTracker from '../app/withTracker';

import SideBarNav from '../../components/SideBarNav';
import TopNavBar from '../../components/TopNavBar';

import DashHome from './views/DashHome';
import MyCBLS from './views/MyCBLS';
import DevelopmentProperties from './views/DevelopmentProperties';
import MySalesSearch from './views/MySalesSearch';
import MyLettingsSearch from './views/MyLettingsSearch';
import SaleOpportunities from './views/SaleOpportunities';
import LettingOpportunities from './views/LettingOpportunities';
import Search from './views/Search';
import {
  Developments,
  CBLSBuyingSolution,
  AgentsPartnership,
  LandlordServices,
  Rentals,
  Sales,
  HowToUse
} from './views/static';

import './index.scss';
import Http from "../../constants/Http";
import { UPDATE_TOKEN, UPDATE_USER } from "../../modules/login";
import { push } from "connected-react-router";

class Dashboards extends React.Component {
  state = {
    menuOpen: false,
  };
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.componentDidMount();
    }
  }
  
  componentDidMount() {
    console.log(this.props);
    this.checkAuth();
  }
  
  checkAuth = () => {
    Http.get('/auth/current')
      .then(res => {
        this.props.dispatch({
          type: UPDATE_USER,
          payload: res.data.data
        });
      })
      .catch(err => {
        window.localStorage.removeItem('token');
        this.props.dispatch({
          type: UPDATE_TOKEN,
          payload: null
        });
        this.props.dispatch(push('/login'));
      });
  };
  
  render() {
    const { props } = this;
    const { match } = props;
    return (
      <div>
        <TopNavBar match={match.url} />
        {/*<SideBarNav match={match.url}/>*/}
        <div className="dash-content">
          <Switch>
            <Route exact path={`${match.url}`} component={withTracker(DashHome)}/>
            <Route exact path={`${match.url}/how-to-use`} component={withTracker(HowToUse)} />
            <Route exact path={`${match.url}/search`} component={withTracker(Search)}/>
            <Route exact path={`${match.url}/my-cobbletons`} component={withTracker(MyCBLS)}/>
            <Route
              exact
              path={`${match.url}/sales-search`}
              component={withTracker(MySalesSearch)}
            />
            <Route
              exact
              path={`${match.url}/lettings-search`}
              component={withTracker(MyLettingsSearch)}
            />
            <Route
              exact
              path={`${match.url}/sale-opportunities`}
              component={withTracker(SaleOpportunities)}
            />
            <Route
              exact
              path={`${match.url}/letting-opportunities`}
              component={withTracker(LettingOpportunities)}
            />
            <Route
              exact
              path={`${match.url}/developments`}
              component={withTracker(Developments)}
            />
            <Route
              exact
              path={`${match.url}/cobbletons-buying-solution`}
              component={withTracker(CBLSBuyingSolution)}
            />
            <Route
              exact
              path={`${match.url}/agents-partnership`}
              component={withTracker(AgentsPartnership)}
            />
            <Route
              exact
              path={`${match.url}/landlord-services`}
              component={withTracker(LandlordServices)}
            />
            <Route exact path={`${match.url}/rentals`} component={withTracker(Rentals)}/>
            <Route exact path={`${match.url}/sales`} component={withTracker(Sales)}/>
            <Route exact path={`${match.url}/development/:slug`} component={withTracker(DevelopmentProperties)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(Dashboards));
