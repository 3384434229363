import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './index.scss';

const DashFooter = props => (
  <React.Fragment>
  <div className={`dash-footer ${props.white && 'white'}`}>
    <div className={'container h-100'}>
      <div className={'row h-100 justify-content-center align-items-center'}>
        <div className={'col-12'}>
          <div className={'row justify-content-center'}>
            <img
              src={'/images/CBLS_SPLASH_LOGO.svg'}
              style={{ height: 65, width: 65 }}
              alt={'CBLS Logo'}
            />
          </div>
          <div className={'row justify-content-center align-items-center'}>
            <ul className={'col-6 text-white justify-content-around d-xl-flex d-none'}>
              <li style={{ width: 'auto', textAlign: 'center' }}><Link to={'/dashboard/sales'}>Sales</Link></li>
              {/*<li><Link to={'/dashboard/rentals'}>Rentals</Link></li>*/}
              {/*<li><Link to={'/dashboard/landlord-services'}>Landlord Services</Link></li>*/}
              <li style={{ width: 'auto', textAlign: 'center' }}><Link to={'/dashboard/developments'}>Developments</Link></li>
              {/*<li><Link to={'/dashboard/cobbletons-buying-solution'}>Cobbletons Buying Solution</Link></li>*/}
              <li style={{ width: 'auto', textAlign: 'center' }}><Link to={'/dashboard/agents-partnership'}>Agents Partnership</Link></li>
            </ul>
            <ul className={'text-white justify-content-around d-xl-none mt-3'}>
              <li><Link to={'/dashboard/sales'}>Sales</Link></li>
            </ul>
            {/*<ul className={'text-white justify-content-around d-xl-none'}>*/}
              {/*<li><Link to={'/dashboard/rentals'}>Rentals</Link></li>*/}
            {/*</ul>*/}
            {/*<ul className={'text-white justify-content-around d-xl-none'}>*/}
              {/*<li><Link to={'/dashboard/landlord-services'}>Landlord Services</Link></li>*/}
            {/*</ul>*/}
            <ul className={'text-white justify-content-around d-xl-none'}>
              <li><Link to={'/dashboard/developments'}>Developments</Link></li>
            </ul>
            <ul className={'text-white justify-content-around d-xl-none'}>
              <li><Link to={'/dashboard/agents-partnership'}>Agents Partnership</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div className={`dash-footer bottom ${props.white && 'white'}`}>
      <div className="d-flex justify-content-center w-100">
        <Link to={'/trusting-cobbletons'} style={{ opacity: 0.6, fontFamily: 'Proxima Nova, sans-serif', fontSize: 18, color: '#FFFFFF', textAlign: 'center' }}>Why Trust Cobbletons</Link>
      </div>
      <div className={'container h-100'}>
        <div className={'row h-100 justify-content-center align-items-center'}>
          <div className={'col-12'}>
            <div className="row d-flex justify-content-center">
              <div className="col-8 d-flex justify-content-center align-items-center">
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-4 mt-md-0">
                    <img src={"/images/accreditation/naea.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                  </div>
                  <div className="col-12 col-md-4 mt-4 mt-md-0">
                    <img src={"/images/accreditation/the_property_ombudsman.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4 mt-md-0">
                    <img src={"/images/accreditation/tsi.svg"} className={"w-md-100 mx-auto mx-md-none d-block"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default connect()(DashFooter);
