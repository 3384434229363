import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, Form } from 'redux-form';
import { FormGroup, Label, Alert } from 'reactstrap';
import { formValueSelector } from 'redux-form';

import TextInput from '../Fields/TextInput';
import PhoneNumber from '../Fields/PhoneNumber';

import * as validations from '../Validations';

import './index.scss';
import SelectDropdown from "../Fields/SelectDropdown";

const selector = formValueSelector('registerForm');

let RegisterForm = (props, { fields }) => (
  <Form onSubmit={props.handleSubmit}>
    <section className="form-section">
      <div className="row registerForm">
        <div className="col-10 offset-1 col-md-6 offset-md-3">
          <div className="row mt-4">
            <FormGroup className="col-12 col-sm-6">
              <Label>Title</Label>
              <Field
                name="title"
                component={SelectDropdown}
                type="text"
                valueKey={"id"}
                labelKey={"name"}
                options={props.titles}
                validate={validations.required}
              />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="col-12 col-sm-6">
              <Label>First Name</Label>
              <Field
                name="first_name"
                component={TextInput}
                type="text"
                validate={validations.required}
              />
            </FormGroup>
            <FormGroup className="col-12 col-sm-6">
              <Label>Last Name</Label>
              <Field
                name="last_name"
                component={TextInput}
                type="text"
                validate={validations.required}
              />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="col-12">
              <Label>Email</Label>
              <Field
                name="email"
                component={TextInput}
                type="text"
                validate={[validations.required, validations.email]}
              />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="col-12">
              <Label>Phone Number</Label>
              <Field
                name="phone_number"
                component={PhoneNumber}
                type="text"
                validate={[validations.required, validations.phoneNumber]}
              />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="col-12">
              <Label>Postcode</Label>
              <Field name="postcode" component={TextInput} type="text" validate={[validations.required]} />
            </FormGroup>
          </div>
          <div className="row mt-1">
            <FormGroup className="col-1">
              <Field
                name="agent"
                component="input"
                type="checkbox"
              />
            </FormGroup>
            <div className="col-10">
              <p>
                I am an agent and want to join the agent/partnership program
              </p>
            </div>
          </div>
          {props.agent &&
            <div className="row">
              <FormGroup className="col-12">
                <Label>Business Name</Label>
                <Field
                  name="business_name"
                  component={TextInput}
                  type="text"
                  validate={validations.required}
                />
              </FormGroup>
            </div>
          }
          <div className="row">
            <FormGroup className="col-12">
              <Label>Additional Message (Optional)</Label>
              <Field
                name="additional_message"
                component={'textarea'}
                type="text"
                class="hidden form-control"
                style={{ height: 200 }}
              />
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup className="col-12 col-sm-6">
              <Label>Password</Label>
              <Field
                name="password"
                component={TextInput}
                type="password"
                validate={validations.required}
              />
            </FormGroup>
            <FormGroup className="col-12 col-sm-6">
              <Label>Confirm Password</Label>
              <Field
                name="password_confirmation"
                component={TextInput}
                type="password"
                validate={validations.required}
              />
            </FormGroup>
          </div>
          {/*<div className="row mt-1">*/}
            {/*<FormGroup className="col-1">*/}
              {/*<Field*/}
                {/*name="marketing_consent"*/}
                {/*component="input"*/}
                {/*type="checkbox"*/}
                {/*validate={validations.required}*/}
              {/*/>*/}
            {/*</FormGroup>*/}
            {/*<div className="col-10">*/}
              {/*<p>*/}
                {/*I would like to receive news and information on products and services from Cobbletons.*/}
              {/*</p>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div className="row mt-1">
            <FormGroup className="col-1">
              <Field
                name="policy_agreement"
                component={TextInput}
                type="checkbox"
                validate={validations.required}
              />
            </FormGroup>
            <div className="col-10">
              <p>
                I have read and agree to the <Link to={'/privacy-policy'} target={"_blank"} style={{ textDecoration: 'underline', cursor: 'pointer' }} >Privacy Policy,</Link> <Link to={'/cookie-policy'} target={"_blank"} style={{ textDecoration: 'underline', cursor: 'pointer' }} >Cookies Policy</Link> and <Link to={'/membership-conditions'} target={"_blank"} style={{ textDecoration: 'underline', cursor: 'pointer' }} >Membership Conditions</Link>
              </p>
            </div>
          </div>
          <div className="row mt-1">
            <FormGroup className="col-1">
              <Field
                name="marketing_consent"
                component="input"
                type="checkbox"
              />
            </FormGroup>
            <div className="col-11">
              <p>
                I would like to receive news and information on products and services from Cobbletons.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <button
                className="btn btn-dark registerBtn"
                disabled={props.isSubmitting}>
                {props.isSubmitting ? <img src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: 38, height: 38 }} /> : 'Register'}
              </button>
            </div>
            {/*{props.submitFailed && (*/}
              {/*<Alert*/}
                {/*color="danger"*/}
                {/*className="text-center mx-auto"*/}
                {/*style={{ width: 'calc(100% - 30px)' }}>*/}
                {/*There are errors in your registration. Please check you have accepted the Privacy Policy and Cookie Policy before submitting*/}
              {/*</Alert>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </section>
  </Form>
);

RegisterForm = reduxForm({
  form: 'registerForm'
})(RegisterForm);

const mapStateToProps = state => ({
  marketing_consent: selector(state, 'marketing_consent'),
  cookie_policy: selector(state, 'cookie_policy'),
  privacy_policy: selector(state, 'privacy_policy'),
  agent: selector(state, 'agent'),
});

export default connect(mapStateToProps)(
  RegisterForm
);
