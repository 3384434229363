import Http from '../constants/Http';
import axios from 'axios';
import { push } from 'connected-react-router';
import swal from 'sweetalert';

export const UPDATE_TOKEN = 'login/UPDATE_TOKEN';
export const UPDATE_USER = 'login/UPDATE_USER';

const initialState = {
  token: null,
  user: {
    first_name: '',
    primary_email: '',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload
      }
    }
    case UPDATE_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const login = values => dispatch => {
  Http.post('/auth/login', values)
    .then(res => {
      console.log(res.data);
      axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.success.token}`;
      dispatch({
        type: UPDATE_TOKEN,
        payload: res.data.success.token
      });
      window.localStorage.setItem('token', res.data.success.token);
      dispatch(push('/dashboard'));
    })
    .catch(err => {
      swal({
        text: `${typeof err.response.data.error === "string" ? err.response.data.error : err.response.data.error.message}`,
        icon: `${err.response.data.error_type !== undefined ? err.response.data.error_type : 'error'}`,
        button: "OK",
      });
    });
};
