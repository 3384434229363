import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import Tabs, { Tab } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from 'material-ui/styles';

import Textfit from 'react-textfit';

import LogoBanner from '../LogoBanner';

import './index.scss';

function TabContainer({ children, dir }) {
  return (
    <div className="Tab" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

const styles = theme => ({});

class LandingNav extends Component {
  constructor(props) {
    super(props);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFirst = this.handleChangeFirst.bind(this);

    this.state = {
      value: 0
    };
  }
  toggle = () => {
    this.setState({
      collapse: !this.state.collapse
    });
  };
  handleChangeFirst = (e, value) => {
    this.setState({ value });
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { theme } = this.props;
    return (
      <div className="LandingNavWrapper">
        <Collapse
          isOpen={this.state.collapse}
          className={'infoCollapse'}
          id={'infoCollapse'}>
          <div className="card card-body">
            <LogoBanner />
            <span>
              <a role="button" onClick={() => this.toggle()}>
                <img
                  src="/images/icons/close_icon.svg"
                  alt=""
                  className="close"
                />
              </a>
            </span>
            <Tabs
              onChange={this.handleChange}
              value={this.state.value}
              className="CollapseTabs"
              indicatorColor="transparent"
              textColor="#4A4A4A"
              disableRipple={true}>
              <Tab
                disableRipple={true}
                label="SELLING"
                className={`LandingTab ${
                  this.state.value === 0 ? 'active' : ''
                }`}
              />
              <Tab
                disableRipple={true}
                label="LETTING"
                className={`LandingTab ${
                  this.state.value === 1 ? 'active' : ''
                }`}
              />
              <Tab
                disableRipple={true}
                label="BUYING"
                className={`LandingTab ${
                  this.state.value === 2 ? 'active' : ''
                }`}
              />
              <Tab
                disableRipple={true}
                label="PARTNERSHIP"
                className={`LandingTab ${
                  this.state.value === 3 ? 'active' : ''
                }`}
              />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
              disabled>
              <TabContainer dir={theme.direction}>
                {this.state.value === 0 && (
                  <div className="text-center tabContent">
                    <h1>
                      <Textfit>A Membership With Every Opportunity</Textfit>
                    </h1>
                    <p>
                      A completely unique opportunity to market property at your
                      discretion via one of the most powerful membership groups
                      worldwide.
                    </p>
                    {window.location.pathname.split('/')[1] === 'dashboard' ? (
                      <Link to="/dashboard/sale-opportunities">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          Start Selling
                        </button>
                      </Link>
                    ) : window.localStorage.getItem('token') !== null ? (
                      <Link to="/dashboard">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          View Dashboard
                        </button>
                      </Link>
                    ) : (
                      <React.Fragment>
                        <Link to="/register">
                          <button
                            type="button"
                            className="btn btn-dark registerBtn">
                            Apply for membership
                          </button>
                        </Link>
                        <Link to={'/login'}>
                          <p>Already have an account? Log In</p>
                        </Link>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </TabContainer>
              <TabContainer dir={theme.direction}>
                {this.state.value === 1 && (
                  <div className="text-center tabContent">
                    <h1>A Membership With Every Opportunity</h1>
                    <p>
                      Discover a hidden world of everything from short to long
                      term letting prospects, available exclusively to members
                      only.
                    </p>
                    {window.location.pathname.split('/')[1] === 'dashboard' ? (
                      <Link to="/dashboard/letting-opportunities">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          Start Letting
                        </button>
                      </Link>
                    ) : window.localStorage.getItem('token') !== null ? (
                      <Link to="/dashboard">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          View Dashboard
                        </button>
                      </Link>
                    ) : (
                      <React.Fragment>
                        <Link to="/register">
                          <button
                            type="button"
                            className="btn btn-dark registerBtn">
                            Apply for membership
                          </button>
                        </Link>
                        <Link to={'/login'}>
                          <p>Already have an account? Log In</p>
                        </Link>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </TabContainer>
              <TabContainer dir={theme.direction}>
                {this.state.value === 2 && (
                  <div className="text-center tabContent">
                    <h1>A Membership With Every Opportunity</h1>
                    <p>
                      Search, view and purchase properties prior to launch on
                      open market and exclusively those which won't be released
                      in the public domain.
                    </p>
                    {window.location.pathname.split('/')[1] === 'dashboard' ? (
                      <Link to="/dashboard/sales-search">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          Start Buying
                        </button>
                      </Link>
                    ) : window.localStorage.getItem('token') !== null ? (
                      <Link to="/dashboard">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          View Dashboard
                        </button>
                      </Link>
                    ) : (
                      <React.Fragment>
                        <Link to="/register">
                          <button
                            type="button"
                            className="btn btn-dark registerBtn">
                            Apply for membership
                          </button>
                        </Link>
                        <Link to={'/login'}>
                          <p>Already have an account? Log In</p>
                        </Link>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </TabContainer>
              <TabContainer dir={theme.direction}>
                {this.state.value === 3 && (
                  <div className="text-center tabContent">
                    <h1>A Membership With Every Opportunity</h1>
                    <p>
                      Apply to become one of our chosen agent partners, present
                      your client's properties to our elite members and view the
                      most exclusive opportunities not available elsewhere.
                    </p>
                    {window.location.pathname.split('/')[1] === 'dashboard' ? (
                      <Link to="/dashboard/agents-partnership">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          Read More
                        </button>
                      </Link>
                    ) : window.localStorage.getItem('token') !== null ? (
                      <Link to="/dashboard">
                        <button
                          type="button"
                          className="btn btn-dark registerBtn">
                          View Dashboard
                        </button>
                      </Link>
                    ) : (
                      <React.Fragment>
                        <Link to="/register">
                          <button
                            type="button"
                            className="btn btn-dark registerBtn">
                            Apply for membership
                          </button>
                        </Link>
                        <Link to={'/login'}>
                          <p>Already have an account? Log In</p>
                        </Link>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </TabContainer>
            </SwipeableViews>
          </div>
        </Collapse>

        <div className="row w-custom">
          <ul className="LandingNavLinks text-center">
            <li>
              <a
                onClick={e => {
                  this.handleChangeFirst(e, 0);
                  this.toggle();
                }}
                role="button">
                SELLING
              </a>
            </li>
            <li>
              <a
                onClick={e => {
                  this.handleChangeFirst(e, 1);
                  this.toggle();
                }}
                role="button">
                LETTING
              </a>
            </li>
            <li>
              <a
                onClick={e => {
                  this.handleChangeFirst(e, 2);
                  this.toggle();
                }}
                role="button">
                BUYING
              </a>
            </li>
            <li>
              <a
                onClick={e => {
                  this.handleChangeFirst(e, 3);
                  this.toggle();
                }}
                role="button">
                PARTNERSHIP
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LandingNav);
