import React, { Component } from 'react';
import { connect } from 'react-redux';
import LandingNav from '../../../../components/LandingNav';
import SearchBox from '../../../../components/SearchBox';
import ValuationModal from '../../../../components/Modals/ValuationModal';
import { Link } from 'react-router-dom';
import DashFooter from '../../../../components/DashFooter';

let timer = null;
let circleTimer = null;

class HowToUse extends Component {
  state = {
    toggle: 'agent',
    tab: true,
    data: null
  };

  componentDidMount() {
    if (window.innerWidth > 992) {
      window.addEventListener('resize', this.buildSVG.bind(this));
      window.setTimeout(() => {
        this.buildSVG();
        timer = window.setInterval(() => {
          this.buildSVG();
          this.scrollCircle();
        }, 2000);

        window.addEventListener('scroll', this.scrollCircle);
      }, 1000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.buildSVG());
    window.removeEventListener('scroll', this.scrollCircle);
    window.clearInterval(timer);
    window.clearInterval(circleTimer);
  }

  changeTab = val => {
    this.setState({
      tab: val
    });
  };

  offset = el => {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  scrollCircle = () => {
    try {
      if (window.pageYOffset >= 10) {
        this.setState({
          circleResetPoint: window.pageYOffset
        });
        var doc = document.documentElement,
          body = document.body;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        var _p = document.querySelector('.moveme');
        var height =
          Math.max(
            body.scrollHeight,
            body.offsetHeight,
            doc.clientHeight,
            doc.scrollHeight,
            doc.offsetHeight
          ) - 10;
        var current = top - 10;
        var pct = current / height;
        var pathlength = _p.getTotalLength();
        var dashoffset = -(pathlength * pct);
        _p.setAttribute('stroke-dashoffset', dashoffset * 1.3);
      }
      if (window.pageYOffset < this.state.circleResetPoint) {
      }
    } catch (e) {
      console.log(e);
    }
  };

  scroll = () => {
    const hero = document.getElementById('scrollToHere');
    const heroBounds = this.offset(hero);
    window.scrollTo({
      top: heroBounds.top,
      left: 0,
      behavior: 'smooth'
    });
  };

  buildSVG = () => {
    try {
      const svgDiv = document.getElementsByClassName('GuideLineSVG')[0];
      let line1x1 =
        document
          .getElementsByClassName('ToggleButton')[0]
          .getBoundingClientRect().left -
        document.body.getBoundingClientRect().left +
        document.getElementsByClassName('ToggleButton')[0].offsetWidth;
      
      const line1y1 =
        document
          .getElementsByClassName('ToggleButton')[0]
          .getBoundingClientRect().top -
        document.body.getBoundingClientRect().top +
        82;
      const circleLast =
        document.getElementsByClassName('circleLast')[0].getBoundingClientRect()
          .top - document.body.getBoundingClientRect().top;
      const circleLastLeft = document.getElementsByClassName('circleLast')[0]
        .offsetLeft;

      const svg = `<svg id="FixScrollSVG" style="position: absolute; top: 0; z-index: 899; pointer-events: none;  width: 100%; height: ${
        document.documentElement.scrollHeight
      }px" fill="#044B94" fill-opacity="0">

        <path d="M ${line1x1} ${line1y1 + 20}
        L ${line1x1} ${document
        .getElementsByClassName('ToggleButton')[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()
        .top -
        document.body.getBoundingClientRect().top +
        document.getElementsByClassName('ToggleButton')[0].parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .offsetHeight}
        L ${circleLastLeft + 13} ${document
        .getElementsByClassName('ToggleButton')[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()
        .top -
        document.body.getBoundingClientRect().top +
        document.getElementsByClassName('ToggleButton')[0].parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .offsetHeight}
        L ${circleLastLeft + 13} ${circleLast - 3}
        " stroke-dasharray="10, 5" stroke-width="5" stroke-linejoin="round" stroke="#EBA94F" />
        
        <path d="M ${line1x1} ${line1y1}
        L ${line1x1} ${document
        .getElementsByClassName('ToggleButton')[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()
        .top -
        document.body.getBoundingClientRect().top +
        document.getElementsByClassName('ToggleButton')[0].parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .offsetHeight}
        L ${circleLastLeft + 13} ${document
        .getElementsByClassName('ToggleButton')[0]
        .parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()
        .top -
        document.body.getBoundingClientRect().top +
        document.getElementsByClassName('ToggleButton')[0].parentElement
          .parentElement.parentElement.parentElement.parentElement.parentElement
          .offsetHeight}
        L ${circleLastLeft + 13} ${circleLast - 3}
        " stroke-dasharray="1 6000" stroke-width="25" stroke-linejoin="round" stroke-linecap="round" class="moveme" stroke="#EBA94F" />
        
      </svg>`;

      svgDiv.innerHTML = svg;
    } catch (e) {
      console.log(e);
    }
  };

  changeToggle = () => {
    if (this.state.toggle === 'agent') {
      this.setState({
        toggle: 'buyer'
      });
    } else {
      this.setState({
        toggle: 'agent'
      });
    }
  };

  render() {
    return (
      <div
        id={'DashHomeWrapper'}
        className={'DashHomeWrapper developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex mt-5 justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'GuideLineSVG'} />
        <div className={'col-12'} id={'scrollToHere'} style={{ marginTop: 70 }}>
          <div className={'row'}>
            <div className={'col-12 col-lg-6'}>
              <div
                className={'row justify-content-center'}
                style={{ marginTop: 80 }}>
                <div className="col-10">
                  <h2
                    style={{
                      color: '#1A1A1A',
                      fontFamily: 'essonnes-headline',
                      fontSize: 48
                    }}>
                    {new Date().getHours() < 12
                      ? 'Good Morning'
                      : new Date().getHours() >= 12 &&
                        new Date().getHours() < 17
                      ? 'Good Afternoon'
                      : 'Good Evening'}{' '}
                    {this.props.user.first_name}
                  </h2>
                </div>
                <div className={'col-10 pt-3 pb-3'}>
                  <p>
                    Cobbletons is the only digital marketplace for private
                    members to discover and preview an elite collection of
                    assets for 14 days before they are advertised publicly. All
                    members are treated with the utmost confidentiality and all
                    data held is secure and discreet.
                  </p>
                </div>
              </div>
              <div
                className={'row justify-content-center'}
                style={{ marginTop: 80 }}>
                <div className={'col-10 pb-3'}>
                  <div className={'row'}>
                    <div
                      className={'col-5 pr-0'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.changeToggle()}>
                      <div
                        className={`ToggleButton text-center ${this.state
                          .toggle === 'agent' && 'active'}`}>
                        <span>I am an Agent</span>
                      </div>
                    </div>
                    <div
                      className={'col-5 pl-0'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.changeToggle()}>
                      <div
                        className={`ToggleButton text-center ${this.state
                          .toggle === 'buyer' && 'active'}`}>
                        <span>I am a buyer/seller</span>
                      </div>
                    </div>
                  </div>
                  <div className={'row d-flex'}>
                    <div
                      className={'col-5 d-flex justify-content-end'}
                      style={{ height: 80 }}>
                      {window.innerWidth > 992 && (
                        <div className={'circle1'}>&nbsp;</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-6 d-none d-lg-inline'}>
              <div className={'row justify-content-end'}>
                <div className="col-10 pr-0">
                  <img
                    className={'float-right img-fluid'}
                    src={'/images/dashboard/homepage/img1.png'}
                    alt={''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={'row'}
            style={{ marginTop: window.innerWidth > 992 ? 150 : 50 }}>
            <div
              className={`col-12 col-md-6 ${window.innerWidth < 992 && 'mb-5'}`}
              id={'img2'}
              data-aos="fade-right"
              data-aos-anchor="#img2">
              <div className={'row'}>
                <div className="col-11 pl-0">
                  <img
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                    alt={''}
                    src={'/images/dashboard/homepage/img2.png'}
                  />
                </div>
              </div>
            </div>
            <div className={'col-1'}>&nbsp;</div>
            <div
              className={'col-12 col-md-5 d-flex align-items-center'}
              id={'text2'}
              data-aos="zoom-in"
              data-aos-anchor="#text2">
              <div className={'row'}>
                <div className={'col-12'}>
                  <h3
                    style={{
                      fontFamily: 'essonnes-headline',
                      fontWeight: 'normal'
                    }}>
                    {this.state.toggle === 'agent'
                      ? 'Property to list?'
                      : 'Looking for property to buy or sell?'}
                  </h3>
                </div>
                <div className={'col-12 pt-2'}>
                  <p
                    className={'w-100 w-md-75 w-lg-60'}
                    style={{ marginBottom: 50, marginTop: 25 }}>
                    {this.state.toggle === 'agent'
                      ? 'Click here to list your property for other agents to see and the Cobbletons community! '
                      : 'Start your search by using our property search to find the ideal property for you.'}
                  </p>
                </div>
                <div className={'col-12'}>
                  <div className={'row'}>
                    <div className={'col-6 mr-auto'}>
                      <ValuationModal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'row'} style={{ marginTop: 150 }}>
            <div
              className={`col-12 col-md-6 d-flex align-items-center ${window.innerWidth <
                992 && 'mb-5'}`}
              id={'text3'}
              data-aos="zoom-in"
              data-aos-anchor="#text3">
              <div className={'row'}>
                <div className={'col-11'}>
                  <div className={'row justify-content-end'}>
                    <div className={'col-10 text-right'}>
                      <h3
                        style={{
                          fontFamily: 'essonnes-headline',
                          fontWeight: 'normal'
                        }}>
                        {this.state.toggle === 'agent'
                          ? 'Sit and wait for viewings'
                          : 'Set up property alerts'}
                      </h3>
                    </div>
                    <div className={'col-10 text-right pt-2'}>
                      <p
                        className={'w-100 w-md-75 w-lg-60 ml-auto'}
                        style={{ marginBottom: 50, marginTop: 25 }}>
                        {this.state.toggle === 'agent'
                          ? 'You will be alerted by email and through your own dedicated dashboard when a member or agent wants to view your property.'
                          : "If you can't see the property you are looking for, setup a property and wait for properties to come to you."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={'col-12 col-md-6'}
              id={'img3'}
              data-aos="fade-left"
              data-aos-anchor="#img3">
              <div className={'row justify-content-end'}>
                <div className="col-11 pr-0">
                  <img
                    className={'float-right'}
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                    alt={''}
                    src={'/images/dashboard/homepage/img3.png'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'row'} style={{ marginTop: 150 }}>
            <div
              className={`col-12 col-md-6 ${window.innerWidth < 992 && 'mb-5'}`}
              id={'img4'}
              data-aos="fade-right"
              data-aos-anchor="#img4">
              <div className={'row'}>
                <div className="col-11 pl-0">
                  <img
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                    alt={''}
                    src={'/images/dashboard/homepage/img4.png'}
                  />
                </div>
              </div>
            </div>
            <div className={'col-1'}>&nbsp;</div>
            <div
              className={'col-12 col-md-5 d-flex align-items-center'}
              id={'text4'}
              data-aos="zoom-in"
              data-aos-anchor="#text4">
              <div className={'row'}>
                <div className={'col-12'}>
                  <h3
                    style={{
                      fontFamily: 'essonnes-headline',
                      fontWeight: 'normal'
                    }}>
                    {this.state.toggle === 'agent'
                      ? 'Accept offers'
                      : 'Arrange viewing'}
                  </h3>
                </div>
                <div className={'col-12 pt-2'}>
                  <p
                    className={'w-100 w-md-75 w-lg-60'}
                    style={{ marginBottom: 50, marginTop: 25 }}>
                    {this.state.toggle === 'agent'
                      ? 'After the viewings, come the offers. All through your dashboard. You will see offers come in. Review and accept them all through your Cobbletons Dashboard.'
                      : 'When you are positive you’ve found the right property, make and manage your offer through your dashboard and wait for it to be accepted.'}
                  </p>
                </div>
                <div className={'col-12'}>
                  <div className={'row'}>
                    <div className={'col-6 mr-auto'}>
                      <Link to={'/dashboard/my-cobbletons'}>
                        <button
                          className={'cbls-dark-grey-button btn-block '}
                          style={{
                            height: 50,
                            border: '1px solid #1A1A1A',
                            cursor: 'pointer'
                          }}>
                          View your Dashboard
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className={'row'} style={{ marginTop: 150 }}>*/}
            {/*<div*/}
              {/*className={`col-12 col-md-6 d-flex align-items-center ${window.innerWidth <*/}
                {/*992 && 'mb-5'}`}*/}
              {/*id={'text5'}*/}
              {/*data-aos="zoom-in"*/}
              {/*data-aos-anchor="#text5">*/}
              {/*<div className={'row'}>*/}
                {/*<div className={'col-11'}>*/}
                  {/*<div className={'row justify-content-end'}>*/}
                    {/*<div className={'col-10 text-right'}>*/}
                      {/*<h3*/}
                        {/*style={{*/}
                          {/*fontFamily: 'essonnes-headline',*/}
                          {/*fontWeight: 'normal'*/}
                        {/*}}>*/}
                        {/*{this.state.toggle === 'agent'*/}
                          {/*? 'Accept offers'*/}
                          {/*: 'Make an offer'}*/}
                      {/*</h3>*/}
                    {/*</div>*/}
                    {/*<div className={'col-10 text-right pt-2'}>*/}
                      {/*<p*/}
                        {/*className={'w-100 w-md-75 w-lg-60 ml-auto'}*/}
                        {/*style={{ marginBottom: 50, marginTop: 25 }}>*/}
                        {/*{this.state.toggle === 'agent'*/}
                          {/*? 'After the viewings come the offers. All through your dashboard, you will see offers come in, review and accept them.'*/}
                          {/*: "When you are positive you’ve found the right property, make and manage your offer through your dashboard and wait for it to be accepted."}*/}
                      {/*</p>*/}
                    {/*</div>*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
            {/*<div*/}
              {/*className={'col-12 col-md-6'}*/}
              {/*id={'img5'}*/}
              {/*data-aos="fade-left"*/}
              {/*data-aos-anchor="#img5">*/}
              {/*<div className={'row justify-content-end'}>*/}
                {/*<div className="col-11 pr-0">*/}
                  {/*<img*/}
                    {/*className={'float-right'}*/}
                    {/*style={{*/}
                      {/*width: '100%',*/}
                      {/*height: 'auto'*/}
                    {/*}}*/}
                    {/*alt={''}*/}
                    {/*src={'/images/dashboard/homepage/img5.png'}*/}
                  {/*/>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                {window.innerWidth > 992 && (
                  <div className={'col-12 text-center'}>
                    <div className={'circleLast'}>&nbsp;</div>
                  </div>
                )}
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 col-md-6 col-lg-2 text-center'}>
                  <Link to={'/dashboard/my-cobbletons'}>
                    <button
                      className={'cbls-dark-grey-button btn-block border-0'}
                      style={{ height: 50, cursor: 'pointer' }}>
                      View your Dashboard
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user
});

export default connect(mapStateToProps)(HowToUse);
