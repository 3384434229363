import axios from 'axios';


if (window.location.hostname === 'localhost') {
  axios.defaults.baseURL = 'https://api.cobbletons.com';
}
if (window.location.hostname === 'cbls.stagelab.co.uk') {
  axios.defaults.baseURL = 'https://cblsapi.stagelab.co.uk/';
}
if (window.location.hostname === 'beta.cobbletons.com') {
  axios.defaults.baseURL = 'https://api.cobbletons.com';
}
if (window.location.hostname === 'www.cobbletons.com') {
  axios.defaults.baseURL = 'https://api.cobbletons.com';
}
axios.defaults.headers.accept = 'application/json';
axios.defaults.headers.contentType = 'application/json';
axios.defaults.headers.common[
  'Authorization'
  ] = `Bearer ${window.localStorage.getItem('token')}`;

export default axios;
