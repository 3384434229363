import React, { Component } from 'react';
import moment from 'moment';

class SmallOfferTable extends Component {
  render() {
    const { offers } = this.props;
    return (
      <div className={"small-table"}>
        <div className="d-flex">
          <div className="col">
            <b>Ref</b>
          </div>
          <div className="col">
            <b>Status</b>
          </div>
          <div className="col">
            <b>Offer Amount</b>
          </div>
          <div className="col">
            <b>Date</b>
          </div>
        </div>
        {offers !== null && offers !== undefined &&
          offers.map((offer, index) => (
            <div className="d-flex border-top" key={index}>
              <div className="col">
                <span>#{offer.id}</span>
              </div>
              <div className="col">
                <span className={`${offer.offer_status.name}`}>{offer.offer_status.name}</span>
              </div>
              <div className="col">
                <span>£{offer.offer_amount}</span>
              </div>
              <div className="col">
                <span>{moment(offer.created_at).format("DD/MM/YYYY")}</span>
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default SmallOfferTable;