import React from 'react';
import Http from '../../constants/Http';
import PropertyComponent from '../PropertyComponent';
import Loading from '../../constants/Loading';

class EmptySection extends React.Component {
  state = {
    data: null,
    tab: true
  };

  componentWillMount() {
    Http.get('/auth/homepage')
      .then(res => {
        this.setState({
          data: res.data.data
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  
  setSaved = (id) => {
    let data = this.state.data;
    let property = data.properties[this.state.tab ? 'sales' : 'rentals'][id];
    
    property[this.state.tab ? 'is_saved_sale' : 'is_saved_rent'] = !property[this.state.tab ? 'is_saved_sale' : 'is_saved_rent'];
    
    data.properties[this.state.tab ? 'sales' : 'rentals'][id] = property;
    
    this.setState({
      data
    })
  };

  changeTab = val => {
    this.setState({
      tab: val
    });
  };

  render() {
    const { props } = this;
    return (
      <div className={"col-12"}>
        <div
          className={
            'border d-flex justify-content-center align-items-center row mt-4'
          }>
          <div
            className={'col-10 d-flex justify-content-center'}
            style={{ marginTop: 70, marginBottom: 15 }}>
            <img
              src={'/images/logo_grey.svg'}
              alt={'grey cbls logo'}
              style={{ height: 40 }}
            />
          </div>
          <div
            className={'col-10 d-flex justify-content-center'}
            style={{ marginBottom: 70 }}>
            <h3
              className={'font-weight-bold'}
              style={{
                fontFamily: '"proxima-nova", sans-serif',
                fontSize: 20,
                color: '#383B41',
                letterSpacing: '0px',
                textAlign: 'center',
                lineHeight: '25px'
              }} dangerouslySetInnerHTML={{ __html: props.title}}>
            </h3>
          </div>
          <p dangerouslySetInnerHTML={{ __html: props.subtitle }} style={{ marginTop: -70, marginBottom: 70, textAlign: 'center' }} className={"col-10"}></p>
          <div className={'col-10 d-flex justify-content-center'}>
            {props.children}
          </div>
        </div>
        {props.latestOffers !== false && (
        <div
          className={
            'd-flex justify-content-center align-items-center row mt-4'
          }>
          {this.state.data !== null ? (
            <div className={'col-12 pl-0 pr-0'}>
              <p style={{ marginTop: 120 }}>Cobbletons latest 14 day opportunities</p>
              {this.state.tab ? (
                  this.state.data.properties.sales.map((p, i) => (
                    <PropertyComponent
                      afterUnsave={() => this.setSaved(i)}
                      data={p}
                      index={i}
                      sale={true}
                    />
                  ))
                )
                : (
                  this.state.data.properties.rental.map((p, i) => (
                    <PropertyComponent
                      afterUnsave={() => this.setSaved(i)}
                      data={p}
                      index={i}
                      sale={false}
                    />
                  ))
                )}
            </div>
          ) : (
            <div className={'col-12'}>
              <Loading />
            </div>
          )}
        </div>
        )}
      </div>
    );
  }
}

export default EmptySection;
