import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FormFeedback } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

moment.utc();

export default class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(date) {
    this.setState({
      date: date
    });
    this.props.input.onChange(date.format());
  }
  
  render() {
    const selected = this.props.input.value
      ? moment(this.props.input.value)
      : null;
    return (
      <>
        <DatePicker
          inline={this.props.inline}
          includeDates={this.props.includeDates}
          selected={selected}
          dateFormat={this.props.dateFormat}
          showYearDropdown
          popperPlacement="top-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 10px'
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport'
            }
          }}
          showTimeSelectOnly={this.props.showTimeSelectOnly}
          showTimeSelect={this.props.showTimeSelect}
          disabled={this.props.disabled}
          className={`${this.props.className} ${this.props.meta.touched && this.props.meta.error ? "is-invalid form-control" : "form-control" }`}
          placeholderText={this.props.placeholder}
          onChange={this.handleChange}
          onChangeRaw={(event) => console.log(event)}
          monthsShown={this.props.monthsShown}
        />
        {this.props.meta.touched && this.props.meta.error && <FormFeedback>{this.props.meta.error}</FormFeedback>}
      </>
    );
  }
}
