import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import './index.scss';

class SmallPropertyCard extends Component {
  state = {
    isOpen: false,
  };
  
  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };
  
  render() {
    const { property, index, collapseComponent } = this.props;
    return (
      <React.Fragment>
        <div className={"property-small-card border-bottom d-flex"} id={`toggler${index}`} onClick={() => this.toggleCollapse()}>
      
          <img height={"50px"} width={"80px"} src={property.all_images !== null ? property.all_images.length > 0 ? property.all_images[0].image.secure_url : '/images/dashboard/property_image_placeholder.png' : '/images/dashboard/property_image_placeholder.png'} />
      
          <h5>{property.property_name}</h5>
        
          <div className={"toggler"}>
            {!this.state.isOpen ?
              <img width={"15px"} src={"/images/icons/chevron-down-solid.svg"} />
              :
              <img width={"15px"} src={"/images/icons/chevron-up-solid.svg"} />
            }
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {collapseComponent}
        </Collapse>
      </React.Fragment>
    );
  }
}

export default SmallPropertyCard;