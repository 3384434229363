import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { FormFeedback } from 'reactstrap';
import 'react-intl-tel-input/dist/libphonenumber.js';
import 'react-intl-tel-input/dist/main.css';

const handler = (status, value, countryData, number, id, props) => {
  if (status) {
    props.input.onChange(number);
  } else {
    props.input.onChange("Incorrect number format");
  }
};

const PhoneNumber = props => (
  <React.Fragment>
    <IntlTelInput
      {...props}
      preferredCountries={['gb', 'us']}
      css={ ['intl-tel-input', 'form-control'] }
      utilsScript={ 'libphonenumber.js' }
      onPhoneNumberChange={(status, value, countryData, number, id) => handler(status, value, countryData, number, id, props)}
      onPhoneNumberBlur={(status, value, countryData, number, id) => handler(status, value, countryData, number, id, props)}
    />
    {props.input.value === "Incorrect number format" && (
      <FormFeedback>{props.input.value}</FormFeedback>
    )}
  </React.Fragment>
);

export default PhoneNumber;
