import React, { Component } from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(selectedOption) {
    if (selectedOption) {
      if (this.props.multi) {
        this.props.input.onChange(selectedOption);
      } else {
        this.props.input.onChange(selectedOption);
      }
    } else {
      this.props.input.onChange("");
    }
  }
  
  render() {
    return (
      <Select
        {...this.props}
        name={this.props.input.name}
        valueKey={this.props.valueKey}
        labelKey={this.props.labelKey}
        value={this.props.input.value}
        clearable={this.props.clearable}
        backspaceRemoves={this.props.backspaceRemoves}
        onChange={this.handleChange}
        options={this.props.options}
        defaultValue={this.props.defaultValue ? this.props.options[0] : null}
        placeholder={this.props.placeholder}
        multi={this.props.multi}
      />
    );
  }
}

export default SelectDropdown;
