import React, { Component } from "react";
import LogoBanner from "../../components/LogoBanner";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Http from "../../constants/Http";

class PrivacyPolicy extends Component {
  state = {
    PageContent: null
  };
  
  getPageContent = () => {
    Http.post('/get-page-content', { url: window.location.pathname })
      .then(res => {
        console.log(res.data.data);
        this.setState({
          PageContent: res.data.data
        })
      })
      .catch(err => {
        console.log(err);
      })
  };
  
  componentWillMount() {
    this.getPageContent();
  }
  
  componentDidMount() {
    window.setTimeout(() => {
      const moveGrey = document.getElementsByClassName("LoadingSplash");
      console.log(moveGrey);
      moveGrey[0].classList.add('slide');
    }, 1000);
  }
  
  render() {
    return (
      <Fade duration={2000} delay={200}>
        <div>
          <div className="LoadingSplash" style={{top:-50, height: "100vh"}}/>
          <LogoBanner />
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <div className={"d-flex justify-content-center "}>
                  <Link to={'/'}><img width={"50"} height={"50"} src={"/images/new/Landing/logo-new.svg"} /></Link>
                </div>
                <h1 className={"register-h1 mt-2"}>Privacy Policy</h1>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-5">
              {this.state.PageContent !== null &&
                <div className="col-10" dangerouslySetInnerHTML={{ __html: this.state.PageContent.content.Privacy_Policy_Type_Wysiwyg }}/>
              }
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default PrivacyPolicy;
