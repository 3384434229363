import React from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import SearchBox from '../../../../components/SearchBox';
import Loading from '../../../../constants/Loading';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Form, Label, ModalHeader } from 'reactstrap';
import SelectDropdown from '../../../../components/Fields/SelectDropdown';
import {
  saveSearchResults,
  saveSearchValues,
  setSaved,
  nextPage,
  UPDATE_SEARCH_RESULTS
} from '../../../../modules/search';
import Http from '../../../../constants/Http';
import { push } from 'connected-react-router';
import TextInput from '../../../../components/Fields/TextInput';
import PropertyComponent from '../../../../components/PropertyComponent';

import './search.scss';
import EmptySection from '../../../../components/EmptySection';
import InfiniteScrollPagination from "../../../../components/InfiniteScrollPagination";

const required = value => (value ? undefined : 'Required');

class Search extends React.Component {
  state = {
    modal: false,
    modalCBLS: false,
    properties: []
  };
  
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(state.properties) !== JSON.stringify(props.properties)) {
      return {
        properties: props.properties
      }
    }
  }
  
  componentDidMount() {
    if (window.innerWidth < 768) {
      window.scrollTo(0, 525);
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  toggleModalCBLS = () => {
    this.setState({
      modalCBLS: !this.state.modalCBLS
    });
  };

  saveSearchAndCBLS = name => {
    const data = {
      search: {
        ...this.props.type,
        name
      }
    };
    Http.post(
      `/auth/search/save-${
        this.props.type.option === 1 || this.props.type.option === 3
          ? 'sale'
          : 'rent'
      }`,
      data
    )
      .then(res => {
        this.toggleModalCBLS();
        swal({
          title: 'Success',
          text: `${res.data.success.message}`,
          icon: 'success',
          button: 'OK'
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
    Http.post(`/auth/search/cbls`, data.search)
      .then(res => {
        swal({
          title: 'Success',
          text: `${res.data.success.message}`,
          icon: 'success',
          button: 'OK'
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };

  saveSearch = name => {
    const data = {
      search: {
        ...this.props.type,
        name
      }
    };
    Http.post(
      `/auth/search/save-${
        this.props.type.option === 1 || this.props.type.option === 3
          ? 'sale'
          : 'rent'
      }`,
      data
    )
      .then(res => {
        this.toggleModal();
        swal({
          title: 'Success',
          text: `${res.data.success.message}`,
          icon: 'success',
          button: 'OK'
        });
      })
      .catch(err => {
        swal({
          title: 'Oops! Something went wrong',
          text: `${err.response.data.error.message}`,
          icon: 'error',
          button: 'OK'
        });
        console.error(err);
      });
  };
  
  loadMore = () => {
    if (this.props.nextPageURL !== null) {
      this.setState({
        loading: true
      });
      this.props.nextPage()
        .then(res => {
          this.setState({
            loading: false
          })
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({
        endOfStream: true
      })
    }
  };

  render() {
    return (
      <div className={'MyCBLSWrapper'}>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggleModal()}
          className={'d-flex justify-content-center'}
          contentClassName={'w-100 w-md-75'}>
          <ModalHeader toggle={() => this.toggleModal()} />
          <Form className={'d-flex justify-content-center'}>
            <div className={'col-10'}>
              <div
                className={
                  'row align-items-center justify-content-center mt-5 mb-5'
                }>
                <div className={'col-12 text-center mb-5'}>
                  <h3 className={'font-weight-bold'}>Save Property Search</h3>
                </div>
                <div className={'col-12 col-md-8 mb-5'}>
                  <Label>Name of search</Label>
                  <Field
                    name={'name'}
                    component={TextInput}
                    validate={[required]}
                  />
                </div>
                <div className={'col-12 col-md-8'}>
                  <button
                    style={{
                      backgroundColor: '#EBA94F',
                      width: '100%',
                      height: 36,
                      border: 'none',
                      color: 'white',
                      marginRight: 15
                    }}
                    type={'button'}
                    onClick={() => this.saveSearch(this.props.name)}>
                    <i
                      className={'far fa-bell'}
                      style={{
                        color: 'black'
                      }}
                    />{' '}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          isOpen={this.state.modalCBLS}
          toggle={() => this.toggleModalCBLS()}
          className={'d-flex justify-content-center'}
          contentClassName={'w-100 w-md-50'}>
          <ModalHeader toggle={() => this.toggleModalCBLS()} />
          <Form className={'d-flex justify-content-center'}>
            <div className={'col-10'}>
              <div
                className={
                  'row align-items-center justify-content-center mt-5 mb-5'
                }>
                <div className={'col-12 text-center mb-5'}>
                  <h3 className={'font-weight-bold'}>Save Property Search</h3>
                </div>
                <div className={'col-12 col-md-8 mb-5'}>
                  <Label>Name of Search</Label>
                  <Field
                    name={'name'}
                    component={TextInput}
                    validate={[required]}
                  />
                </div>
                <div className={'col-12 col-md-8'}>
                  <button
                    style={{
                      backgroundColor: '#EBA94F',
                      width: '100%',
                      height: 36,
                      border: 'none',
                      color: 'white',
                      marginRight: 15
                    }}
                    type={'button'}
                    onClick={() => this.saveSearchAndCBLS(this.props.name)}>
                    <i
                      className={'far fa-bell'}
                      style={{
                        color: 'black'
                      }}
                    />{' '}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
        <div className={'london-bg-day h-400'}>
          <div className={'container pl-2 pr-2 pb-5 pt-5'}>
            <div className={'d-flex mt-5 justify-content-center'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container mb-4'}>
          {this.props.properties !== null &&
            this.props.properties.length !== 0 && (
              <div className={'row mb-5'}>
                <div className={'col-12 mb-4'}>
                  <h1
                    style={{
                      height: 44,
                      color: '#1A1A1A',
                      fontFamily: 'proxima-nova, sans-serif',
                      fontSize: 36,
                      fontWeight: 'bold',
                      lineHeight: '44px',
                      marginBottom: 20,
                      marginTop: 75
                    }}>
                    Search Results
                  </h1>
                  <h3
                    style={{
                      height: 22,
                      color: '#1A1A1A',
                      fontFamily: 'proxima-nova, sans-serif',
                      fontSize: 18,
                      fontWeight: 300,
                      lineHeight: '22px',
                      marginBottom: 70
                    }}>
                    {this.props.total} Propert
                    {this.props.total === 1 ? 'y' : 'ies'} Found
                  </h3>
                </div>
              </div>
            )}
          {this.props.properties !== null ? (
            this.props.properties.length !== 0 ? (
              <div className={'row mb-5'}>
                <div
                  className={'col-12 col-xl-6 ml-auto'}
                  style={{ marginTop: -81 }}>
                  <div className={'row'}>
                    <div className={'col-12 col-sm-6 mb-2'}>
                      <button
                        style={{
                          backgroundColor: '#EBA94F',
                          width: '100%',
                          height: 36,
                          border: 'none',
                          color: 'white',
                          marginRight: 15
                        }}
                        type={'button'}
                        onClick={() => this.toggleModal()}>
                        <i
                          className={'far fa-bell'}
                          style={{
                            color: 'black'
                          }}
                        />{' '}
                        Save Search
                      </button>
                    </div>
                    <div
                      className={'col-12 col-sm-6 mb-2 SearchFormOrderField'}>
                      <Field
                        name={'order'}
                        clearable={false}
                        backspaceRemoves={false}
                        component={SelectDropdown}
                        options={[
                          {
                            id: 1,
                            label: 'Price (Low - High)'
                          },
                          {
                            id: 2,
                            label: 'Price (High - Low)'
                          },
                          {
                            id: 3,
                            label: 'Newest - Oldest'
                          },
                          {
                            id: 4,
                            label: 'Oldest - Newest'
                          }
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <InfiniteScrollPagination
                  loadMore={() => this.loadMore()}
                  loading={this.state.loading}
                  loadingMoreComponent={<span className={"col-12"}>Loading more propertites</span>}
                  endOfStream={this.state.endOfStream}
                  endOfStreamComponent={<span className={"col-12"}>We could not find any more properties matching your search.</span>}
                >
                  {this.state.properties.map((p, i) => (
                    <div className={'col-12'}>
                      <PropertyComponent
                        data={p}
                        index={i}
                        sale={
                          this.props.type.option === 1 ||
                          this.props.type.option === 3
                        }
                        afterUnsave={() =>
                          this.props.setSaved(i, this.props.type.option)
                        }
                      />
                    </div>
                  ))}
                </InfiniteScrollPagination>
              </div>
            ) : (
              <div className={'mb-5'}>
                <EmptySection
                  title={`
                      <p class="col-12">Leave it with us.</p>`}
                  subtitle={`<p class="mb-0">
                        We have nothing on our current 14 days exclusive
                        listing.
                      </p>
                      <p class="mb-0">
                        Please click below if you want to get back to you with
                        properties from our partnership network that we think
                        will match your criteria.
                        We will also save your search so that you will be
                        notified by email when a property that meets your search criteria.
                      </p>`}
                >
                  <button
                    style={{ marginTop: -50, marginBottom: 50 }}
                    className={'cbls-dark-grey-button w-100 w-md-50'}
                    type="button"
                    onClick={() => this.toggleModalCBLS()}>
                    Save this search and find me properties in this area
                  </button>
                </EmptySection>
              </div>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('searchForm');

const mapStateToProps = state => ({
  properties: state.search.results,
  type: state.search.values,
  nextPageURL: state.search.nextPageURL,
  total: state.search.total,
  name: selector(state, 'name')
});

export default connect(
  mapStateToProps,
  {
    saveSearchValues,
    saveSearchResults,
    push,
    setSaved,
    nextPage
  }
)(
  reduxForm({
    form: 'searchForm',
    destroyOnUnmount: false,
    onChange: (values, dispatch, props, previousValues) => {
      if (values.order !== previousValues.order) {
        dispatch({
          type: UPDATE_SEARCH_RESULTS,
          payload: null
        });
        Http.post('/auth/search', values)
          .then(res => {
            props.saveSearchValues(values).then(() => {
              props.saveSearchResults(res.data);
            });
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  })(Search)
);
