import React from 'react';
import SearchBox from '../../../../components/SearchBox';
import DashFooter from '../../../../components/DashFooter';
import ContactAgentModal from "../../../../components/Modals/ContactAgentModal";

class AgentsPartnership extends React.Component {
  render() {
    return (
      <div className={'developmentsStaticWrapper'}>
        <div className={'homePageHero h-350'}>
          <div className={'container heroContainer'}>
            <div className={'d-flex justify-content-center w-100'}>
              <SearchBox />
            </div>
          </div>
        </div>
        <div className={'container'} style={{ marginTop: 120 }}>
          <div className={'row justify-content-center'}>
            <div className={'col-12 col-md-10'}>
              <h2>Agents Partnership</h2>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 30 }}>
              <h4>Welcome to the Cobbletons partnership opportunities. This is where we display the most exclusive properties which the leading agents have to offer.</h4>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 60 }}>
              <p>When Cobbletons was established we never wanted to be against your high street traditional agents. We always believed in working with them.</p>
            </div>
            <div className={"col-12 col-md-10"} style={{ marginTop: 25 }}>
              <p>We offer </p>
            </div>
            <div className={"col-12 col-md-10"}>
              <ul>
                <li>Displaying agents most exclusive opportunities to our members</li>
                <li>Offering our agents an insight to offer their best clients the chance to see some of our own exclusive opportunities.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={'col-12'}>
          <div
            className={'row davinci-flying-machine align-items-center'}
            style={{ marginTop: 250, height: 900 }}>
            <div className={'col-12'}>
              <div className={'row justify-content-center'}>
                <div className={'col-12 text-white text-center'}>
                  <h1>Your opportunity is here</h1>
                </div>
                <div className={'col-12 text-center'}>
                  <ContactAgentModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DashFooter/>
      </div>
    )
  }
}

export default AgentsPartnership;