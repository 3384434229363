import React from 'react';
import { Fade } from 'react-reveal';

import LogoBanner from '../../components/LogoBanner';
import cblsLogo from "../../images/CBLS_SPLASH_LOGO.svg";

class ThankYou extends React.Component {
  componentDidMount() {
    window.setTimeout(() => {
      const moveGrey = document.getElementsByClassName("LoadingSplash");
      console.log(moveGrey);
      moveGrey[0].classList.add('slide');
    }, 1000);
    document.getElementsByTagName('body')[0].style.background = 'black';
  }
  render(){
    return (
      <Fade duration={1400} delay={400}>
      <div className={"login-page"}>
        <div className="LoadingSplash" style={{top:-50, height: "100vh"}}/>
        <LogoBanner />
        <div className="cbls-container" style={{backgroundColor: "black", color: 'white', height: "calc(100vh - 150px)"}}>
          <div className="row text-center justify-content-center align-items-center h-100">
            <div className="col-12 col-md-4">
              <img
                src={cblsLogo}
                alt=""
                className="cblsLogoSlide mb-3"
                style={{ width: 90 }}
              />
              <h1>Thank You</h1>
              <p className="mx-auto" style={{width: "80%"}}>Thank you for registering with Cobbletons. We are now processing your application and we will come back to you shortly with any further information we may require. For further information or if you have any questions, please email <a href="mailto:members@cobbletons.com">members@cobbletons.com</a></p>
            </div>
          </div>
        </div>
        </div>
      </Fade>
    )
  }
}

export default ThankYou;
