import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, reset } from "redux-form";
import { Modal, Form, Label, ModalHeader } from 'reactstrap';

import Http from '../../constants/Http';
import TextInput from '../Fields/TextInput';
import * as Validations from '../Validations';
import HorizontalDatePicker from "../Fields/HorizontalDatePicker";
import swal from "sweetalert";
import SelectDropdown from '../Fields/SelectDropdown';
import Loading from "../../constants/Loading";


class TimeOfDay extends React.Component {
  state = {
    value: null
  };
  
  updateTimeOfDay = value => {
    this.setState({
      value
    });
    this.props.meta.dispatch(change('valuationForm', 'time_of_day', value));
  };
  
  render() {
    const { value } = this.props.input;
    return (
      <div className={'d-flex'} style={{ justifyContent: 'space-evenly' }}>
        <button
          style={{
            width: 200,
            height: 55,
            backgroundColor: value === 'Morning' ? '#E0DDDD' : '#F2F2F2',
            border: 'none',
            cursor: 'pointer'
          }}
          className={` ${value === 'Morning' && 'active'}`}
          type={'button'}
          onClick={() => this.updateTimeOfDay('Morning')}>
          <p className="mb-0">Morning</p>
          <p>9am to 12pm</p>
        </button>
        <button
          style={{
            width: 200,
            height: 55,
            backgroundColor: value === 'Lunch' ? '#E0DDDD' : '#F2F2F2',
            border: 'none',
            cursor: 'pointer'
          }}
          className={`${value === 'Lunch' && 'active'}`}
          type={'button'}
          onClick={() => this.updateTimeOfDay('Lunch')}>
          <p className="mb-0">Lunch</p>
          <p>12pm to 2pm</p>
        </button>
        <button
          style={{
            width: 200,
            height: 55,
            backgroundColor: value === 'Afternoon' ? '#E0DDDD' : '#F2F2F2',
            border: 'none',
            cursor: 'pointer'
          }}
          className={`${value === 'Afternoon' && 'active'}`}
          type={'button'}
          onClick={() => this.updateTimeOfDay('Afternoon')}>
          <p className="mb-0">Afternoon</p>
          <p>2pm to 5pm</p>
        </button>
        <button
          style={{
            width: 200,
            height: 55,
            backgroundColor: value === 'Evening' ? '#E0DDDD' : '#F2F2F2',
            border: 'none',
            cursor: 'pointer'
          }}
          className={`${value === 'Evening' && 'active'}`}
          type={'button'}
          onClick={() => this.updateTimeOfDay('Evening')}>
          <p className="mb-0">Evening</p>
          <p>5pm to 8pm</p>
        </button>
      </div>
    );
  }
}

class ValuationModal extends React.Component {
  state = {
    isOpen: false,
    tab: 0,
    loading: false
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  next = () => {
    this.setState({
      tab: this.state.tab + 1
    });
  };

  prev = () => {
    this.setState({
      tab: this.state.tab - 1
    });
  };

  onSubmit = values => {
    this.setState({
      loading: true
    });
    Http.post('/auth/contact/arrange-valuation', { ...values, valuation_type: { label: 'Sale' } })
      .then(res => {
        this.toggle();
        this.props.dispatch(reset('valuationForm'));
        this.setState({
          loading: false,
          tab: 0
        });
        swal({
          title: "Success",
          text: `${res.data.success.message}`,
          icon: "success",
          button: "OK",
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        swal({
          title: "Oops! Something went wrong",
          text: `${err.response.data.error.message}`,
          icon: "error",
          button: "OK",
        });
        console.error(err);
      });
  };

  render() {
    const { props } = this;
    const { handleSubmit } = props;
    return (
      <React.Fragment>
        <button
          className={'cbls-dark-grey-button btn-block '}
          onClick={() => this.toggle()}
          type={'button'}
          style={{
            height: 50,
            border: '1px solid #1A1A1A',
            cursor: 'pointer'
          }}>
          I HAVE A PROPERTY TO LIST
        </button>
        <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()}>
          <ModalHeader toggle={() => this.toggle()} />
          <div className={'row justify-content-center d-flex'}>
            <div className={'col-12'}>
              <h3
                className={'text-center'}
                style={{
                  marginTop: 35,
                  height: 24,
                  color: '#1A1A1A',
                  fontFamily: 'proxima-nova, sans-serif',
                  fontSize: 20,
                  fontWeight: 'bold',
                  letterSpacing: '0.33px'
                }}>
                I have a property to list
              </h3>
            </div>
            <div className={'col-6'} style={{ marginTop: 55, zIndex: 1 }}>
              <div className={'row d-flex'}>
                <div className={'col-6 pl-0'}>
                  <div
                    className={`valuationCircle ${this.state.tab === 0 &&
                    'active'}`}>
                    1
                  </div>
                </div>
                <div className={'col-6 justify-content-end d-flex pr-0'}>
                  <div
                    className={`valuationCircle ${this.state.tab === 1 &&
                    'active'}`}>
                    2
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'row justify-content-center d-flex mb-4'}>
            <div
              className={'col-6 border-bottom'}
              style={{ height: 0, marginTop: -20, zIndex: 0 }}>
              &nbsp;
            </div>
          </div>
          <div className={'row justify-content-center d-flex mb-4'}>
            <div className={'col-10'}>
              
                {this.state.tab === 0 && (
                  <Form
                    className={'w-100'}
                    onSubmit={handleSubmit(() => this.next())}>
                    <div className={'row justify-content-center d-flex mb-2'}>
                      <div className="col-12">
                        <Label>Postcode</Label>
                        <Field
                          name={'postcode'}
                          component={TextInput}
                          type={'text'}
                          validate={Validations.required}
                        />
                      </div>
                    </div>
                    <div className={'row'} style={{ marginBottom: 45, marginTop: 65 }}>
                      <div className={'col justify-content-center d-flex'}>
                        <button
                          className={'cbls-dark-grey-button w-25'}>
                          Next
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
                {this.state.tab === 1 && (
                  <Form
                    className={'w-100'}
                    onSubmit={handleSubmit((values) => this.onSubmit(values))}>
                    <div className={"row"} style={{ marginBottom: 45, marginTop: 65 }}>
                      <div className={"col"}>
                        <p className={'text-center bold'}>
                          <b>Additional Message (Optional)</b>
                        </p>
                        <Field
                          name={'message'}
                          component={"textarea"}
                          className={"w-100"}
                          style={{
                            minHeight: 300
                          }}
                        />
                      </div>
                    </div>
                    <div className={'row'} style={{ marginBottom: 100 }}>
                      <div className={'col justify-content-center d-flex'}>
                        <button
                          type={'button'}
                          className={'cbls-dark-grey-button w-50'}
                          onClick={() => this.prev()}>
                          Back
                        </button>
                      </div>
                      <div className={'col justify-content-center d-flex'}>
                        <button
                          disabled={this.state.loading}
                          className={'cbls-dark-grey-button w-50'}
                        >
                          {this.state.loading ? <img src={'/images/ui/Rolling.svg'} alt={'loading deals'} style={{ width: 25, height: 25 }} /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    type: window.location.pathname === '/dashboard/letting-opportunities' ? 'Rental Property' : window.location.pathname === '/dashboard/sale-opportunities' ? 'Sale Property' : null
  }
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'valuationForm'
  })(ValuationModal)
);
